import { Col, Collapse, Row, Spin, Tabs, notification } from "antd";
import React, { useRef } from "react";
import { styled } from "styled-components";
import ThemeCard from "../../components/ThemeCard";
import ThemeDropDown from "../../components/ThemeDropDown";
import { SEGMENTS, optionDays, options } from "../../Helper/constant";
import { useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useEffect } from "react";
import ActionButton from "../../components/ActionButton";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  resetExpirySetting,
  setExprirySetting,
  setTabChange,
  setUserIdInExpirySetting,
} from "../../store/slice/expirySettingSlice";
import { Container, SpinnerOverlay } from "../MarketSetting/MarketSetting";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const StyledDiv = styled.div`
  /* width: 100% !important;
  margin: auto; */
  /* height: 500px !important; */
  /* .ant-modal-close-x {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  } */
`;
const StyledTabs = styled(Tabs)`
  /* background-color: red; */
  font-size: 16px;
  padding: 16px;
  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
  }

  .ant-tabs-tab-btn {
    color: #727880;
    font-weight: 500;
    font-size: 1.3rem !important;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  /* Media query for small screens */
  @media only screen and (max-width: 675px) {
    .ant-tabs-nav-wrap {
      justify-content: left;
    }
  }
`;
const Label = styled.div`
  color: #727880;
  font-weight: 600;
  margin-right: 1rem;
`;
const tabView = (tab) => {
  if (
    tab === "NSE FUT" ||
    tab === "NSE EQU" ||
    tab === "FOREX" ||
    tab === "GLOBEX"
  ) {
    return true;
  } else {
    return false;
  }
};
const NSE_OPTIONS = [
  { label: "INDEX", value: "index" },
  { label: "All", value: "all" },
];

export const NseOptionRender = ({ tab }) => {
  // state
  const [getLoading, setGetLoading] = useState(false);
  const [script, setScript] = useState([]);
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);
  const userId = expiryValue?.userId;
  const ScriptName = expiryValue?.scriptName;

  // console.log(tab, ScriptName, userId);

  // useEffect(() => {
  //   if (tab === "COMEX" || tab === "MCX" || tab === "MCX OPT") {
  //     getScript(SEGMENTS[tab]);
  //   }
  // }, [tab]);

  useEffect(() => {
    const view = tabView(tab);
    // console.log("scriptName", ScriptName, userId, !view, tab);
    if (ScriptName && userId && !view) {
      // getExpirySetting(ScriptName);
      // console.log("script", ScriptName, userId, tab);
    }
  }, [ScriptName, userId, tab]);

  // api call
  // const getScript = async (name) => {
  //   try {
  //     let response = await APICall("get", `${EndPoints.getScriptList}${name}`);
  //     console.log("response", response.data.data);
  //     if (response.status === 200 && response.data) {
  //       const scriptNameOptions = response.data?.data?.map((item, index) => {
  //         return {
  //           value: item.symbol,
  //           label: item.symbol,
  //           key: index,
  //         };
  //       });
  //       setScript(scriptNameOptions);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const getExpirySetting = async (script) => {
    setGetLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${SEGMENTS[tab]}&&scriptName=${script}`
      );
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      }
      setGetLoading(false);
    } catch (error) {
      setGetLoading(false);
      console.log("error", error);
    }
  };

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else if (name === "scriptName") {
      // console.log("name", name, selectedValue[0]);
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
        })
      );
    }
  };

  return (
    <>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            title="Script Name"
            placeholder="Select"
            name="scriptName"
            width={"98%"}
            options={tab !== "NSE OPT" ? script : NSE_OPTIONS}
            $onChange={handleChange}
            $value={expiryValue?.scriptName}
          />
        </Col>
      </Row>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.squareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.position?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue.position?.newPositionStartBeforeDay}
            />
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "center", marginTop: "2rem" }}>
          <Button type="primary" style={style.submitBtn} onClick={handleSubmit}>
            SAVE
          </Button>
        </Row> */}
      </ThemeCard>
    </>
  );
};

export const NseFutureRender = ({ tab }) => {
  const dispatch = useDispatch();
  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);
  // console.log(tab, expiryValue);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else {
      setExprirySetting({
        key: [name],
        value: selectedValue[0],
        tab: tab,
      });
    }
  };

  return (
    <>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue?.intraday?.squareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue?.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue?.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue?.position?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue?.position?.newPositionStartBeforeDay}
            />
          </Col>
        </Row>
      </ThemeCard>
    </>
  );
};

export const GlobexRender = ({ tab }) => {
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice?.[tab]);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate" || name === "valanEndingSquareUpRate") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    }
  };

  return (
    <>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday.squareUpRate}
            />
          </Col>
        </Row>
        <Row align={"center"} style={{ marginTop: "1rem" }}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
    </>
  );
};

export const NseEquRender = ({ tab }) => {
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice?.[tab]);
  // console.log(tab, expiryValue);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate" || name === "valanEndingSquareUpRate") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    }
  };

  return (
    <>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday.squareUpRate}
            />
          </Col>
        </Row>
        <Row align={"center"} style={{ marginTop: "1rem" }}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
    </>
  );
};

export const McxRender = ({ tab }) => {
  // state
  const [getLoading, setGetLoading] = useState(false);
  const [script, setScript] = useState([]);
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);
  const userId = expiryValue?.userId;
  const ScriptName = expiryValue?.scriptName;

  // console.log(tab, ScriptName, userId);

  useEffect(() => {
    if (tab === "COMEX" || tab === "MCX" || tab === "MCX OPT") {
      getScript(SEGMENTS[tab]);
    }
  }, [tab]);

  useEffect(() => {
    const view = tabView(tab);
    // console.log("scriptName", ScriptName, userId, !view, tab);
    if (ScriptName && userId && !view) {
      // getExpirySetting(ScriptName);
      // console.log("script", ScriptName, userId, tab);
    }
  }, [ScriptName, userId, tab]);

  // api call
  const getScript = async (name) => {
    try {
      let response = await APICall("get", `${EndPoints.getScriptList}${name}`);
      // console.log("response", response.data.data);
      if (response.status === 200 && response.data) {
        const scriptNameOptions = response.data?.data?.map((item, index) => {
          return {
            value: item.symbol,
            label: item.symbol,
            key: index,
          };
        });
        setScript(scriptNameOptions);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getExpirySetting = async (script) => {
    setGetLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${SEGMENTS[tab]}&&scriptName=${script}`
      );
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      }
      setGetLoading(false);
    } catch (error) {
      setGetLoading(false);
      console.log("error", error);
    }
  };

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else if (name === "scriptName") {
      // console.log("name", name, selectedValue[0]);
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
        })
      );
    }
  };

  return (
    <>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            title="Script Name"
            placeholder="Select"
            name="scriptName"
            width={"98%"}
            options={tab !== "NSE OPT" ? script : NSE_OPTIONS}
            $onChange={handleChange}
            $value={expiryValue?.scriptName}
          />
        </Col>
      </Row>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.squareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.position?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue.position?.newPositionStartBeforeDay}
            />
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "center", marginTop: "2rem" }}>
          <Button type="primary" style={style.submitBtn} onClick={handleSubmit}>
            SAVE
          </Button>
        </Row> */}
      </ThemeCard>
    </>
  );
};

export const McxOPTRender = ({ tab }) => {
  // state
  const [getLoading, setGetLoading] = useState(false);
  const [script, setScript] = useState([]);
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);
  const userId = expiryValue?.userId;
  const ScriptName = expiryValue?.scriptName;

  // console.log(tab, ScriptName, userId);

  useEffect(() => {
    if (tab === "COMEX" || tab === "MCX" || tab === "MCX OPT") {
      getScript(SEGMENTS[tab]);
    }
  }, [tab]);

  useEffect(() => {
    const view = tabView(tab);
    // console.log("scriptName", ScriptName, userId, !view, tab);
    if (ScriptName && userId && !view) {
      // getExpirySetting(ScriptName);
      // console.log("script", ScriptName, userId, tab);
    }
  }, [ScriptName, userId, tab]);

  // api call
  const getScript = async (name) => {
    try {
      let response = await APICall("get", `${EndPoints.getScriptList}${name}`);
      // console.log("response", response.data.data);
      if (response.status === 200 && response.data) {
        const scriptNameOptions = response.data?.data?.map((item, index) => {
          return {
            value: item.symbol,
            label: item.symbol,
            key: index,
          };
        });
        setScript(scriptNameOptions);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getExpirySetting = async (script) => {
    setGetLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${SEGMENTS[tab]}&&scriptName=${script}`
      );
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      }
      setGetLoading(false);
    } catch (error) {
      setGetLoading(false);
      console.log("error", error);
    }
  };

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else if (name === "scriptName") {
      // console.log("name", name, selectedValue[0]);
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
        })
      );
    }
  };

  return (
    <>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            title="Script Name"
            placeholder="Select"
            name="scriptName"
            width={"98%"}
            options={tab !== "NSE OPT" ? script : NSE_OPTIONS}
            $onChange={handleChange}
            $value={expiryValue?.scriptName}
          />
        </Col>
      </Row>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.squareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.position?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue.position?.newPositionStartBeforeDay}
            />
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "center", marginTop: "2rem" }}>
          <Button type="primary" style={style.submitBtn} onClick={handleSubmit}>
            SAVE
          </Button>
        </Row> */}
      </ThemeCard>
    </>
  );
};

export const ComexRender = ({ tab }) => {
  // state
  const [getLoading, setGetLoading] = useState(false);
  const [script, setScript] = useState([]);
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);
  const userId = expiryValue?.userId;
  const ScriptName = expiryValue?.scriptName;

  // console.log(tab, ScriptName, userId);

  useEffect(() => {
    if (tab === "COMEX" || tab === "MCX" || tab === "MCX OPT") {
      getScript(SEGMENTS[tab]);
    }
  }, [tab]);

  useEffect(() => {
    const view = tabView(tab);
    // console.log("scriptName", ScriptName, userId, !view, tab);
    if (ScriptName && userId && !view) {
      // getExpirySetting(ScriptName);
      // console.log("script", ScriptName, userId, tab);
    }
  }, [ScriptName, userId, tab]);

  // api call
  const getScript = async (name) => {
    try {
      let response = await APICall("get", `${EndPoints.getScriptList}${name}`);
      // console.log("response", response.data.data);
      if (response.status === 200 && response.data) {
        const scriptNameOptions = response.data?.data?.map((item, index) => {
          return {
            value: item.symbol,
            label: item.symbol,
            key: index,
          };
        });
        setScript(scriptNameOptions);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getExpirySetting = async (script) => {
    setGetLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getExpirySetting}?userId=${userId}&&marketName=${SEGMENTS[tab]}&&scriptName=${script}`
      );
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      }
      setGetLoading(false);
    } catch (error) {
      setGetLoading(false);
      console.log("error", error);
    }
  };

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else if (name === "scriptName") {
      // console.log("name", name, selectedValue[0]);
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
        })
      );
    }
  };

  return (
    <>
      <Row style={{ marginTop: "2rem" }}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            title="Script Name"
            placeholder="Select"
            name="scriptName"
            width={"98%"}
            options={tab !== "NSE OPT" ? script : NSE_OPTIONS}
            $onChange={handleChange}
            $value={expiryValue?.scriptName}
          />
        </Col>
      </Row>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.squareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.position?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue.position?.newPositionStartBeforeDay}
            />
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "center", marginTop: "2rem" }}>
          <Button type="primary" style={style.submitBtn} onClick={handleSubmit}>
            SAVE
          </Button>
        </Row> */}
      </ThemeCard>
    </>
  );
};

export const ForexRender = ({ tab }) => {
  const dispatch = useDispatch();

  // redux
  // const tab = useSelector((state) => state.expirySettingSlice?.tab);
  const expiryValue = useSelector((state) => state.expirySettingSlice?.[tab]);

  // console.log(tab, expiryValue);

  // life cycle
  useEffect(() => {
    dispatch(setTabChange(tab));
  }, []);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate" || name === "valanEndingSquareUpRate") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    }
  };

  return (
    <>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday.squareUpRate}
            />
          </Col>
        </Row>
        <Row align={"center"} style={{ marginTop: "1rem" }}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue.intraday?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
    </>
  );
};

// items
const items = [
  {
    key: "NSE FUT",
    label: "NSE FUTURE",
    children: <NseFutureRender tab="NSE FUT" />,
  },
  {
    key: "NSE OPT",
    label: "NSE-OPTION",
    children: <NseOptionRender tab="NSE OPT" />,
  },
  {
    key: "NSE EQU",
    label: "NSE-EQU",
    children: <NseEquRender tab="NSE EQU" />,
  },
  {
    key: "MCX",
    label: "MCX",
    children: <McxRender tab="MCX" />,
  },
  {
    key: "MCX OPT",
    label: "MCX-OPTION",
    children: <McxOPTRender tab="MCX OPT" />,
  },

  {
    key: "COMEX",
    label: "COMEX",
    children: <ComexRender tab="COMEX" />,
  },
  {
    key: "FOREX",
    label: "FOREX",
    children: <ForexRender tab="FOREX" />,
  },
  {
    key: "GLOBEX",
    label: "GLOBEX",
    children: <GlobexRender tab="GLOBEX" />,
  },
];

const ExpirySetting = () => {
  // state
  const [masterOption, setMasterOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [value, setValue] = useState("");
  const canCallRef = useRef(true);
  const dispatch = useDispatch();

  // redux
  const { tab, expiryValue } = useSelector((state) => ({
    tab: state.expirySettingSlice?.tab,
    expiryValue: state.expirySettingSlice?.[state.expirySettingSlice?.tab],
  }));
  const userId = expiryValue?.userId;
  const view = tabView(tab);

  // api call
  const getExpirySetting = async (script) => {
    if (!canCallRef.current) {
      return null;
    }
    canCallRef.current = false;
    setGetLoading(true);
    let URL = view
      ? `${EndPoints.getExpirySetting}?userId=${value}&&marketName=${SEGMENTS[tab]}`
      : `${EndPoints.getExpirySetting}?userId=${value}&&marketName=${
          SEGMENTS[tab]
        }&&scriptName=${encodeURIComponent(expiryValue?.scriptName)}`;
    try {
      let response = await APICall("get", URL);
      if (response.status === 200) {
        // console.log("respnose", response);
        const info = response?.data?.data?.[0];
        dispatch(
          setExprirySetting({
            obj: { ...info, userId: userId },
            tab: info?.marketName,
          })
        );
      }
      // setGetLoading(false);
    } catch (error) {
      console.log("error", error);
    } finally {
      setGetLoading(false);
      setTimeout(() => {
        canCallRef.current = true;
      }, 1000);
    }
  };

  const getUsers = async () => {
    let res = await APICall("get", EndPoints.getAllUser);
    return res.data.data.userData;
  };

  const {
    isLoading: userLoading,
    data: userData,
    error: userError,
  } = useQuery({
    queryKey: ["usersDetails"],
    queryFn: getUsers,
    config: {
      background: true,
    },
  });

  const handleSubmit = async () => {
    let obj = { ...expiryValue, userId: value };

    if (view && !value) {
      return notification.error({
        message: "Please select master admin before submit",
      });
    }
    if (!view && (!expiryValue.scriptName || !value)) {
      return notification.error({
        message: `Please fill details of ${
          !value ? "MasterAdmin" : "ScriptName"
        } before submit`,
      });
    }

    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.addExpirySetting, obj);
      if (response.status === 200) {
        // console.log("expiryResponse", response);
        notification.success({ message: response?.data?.message });
        // dispatch(resetExpirySetting());
        // setValue("");
      } else {
        notification.error({ message: response?.data?.message });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ expirySetting ~ error:", error);
    }
  };

  // life cycle
  useEffect(() => {
    let view = tabView(tab);
    if ((view && value) || (!view && expiryValue.scriptName && value)) {
      // console.log("view", view, value, tab);
      getExpirySetting(null);
    }
  }, [tab, value, expiryValue.scriptName]);

  useEffect(() => {
    let obj = {
      masterAdmin: [],
    };

    if (userData) {
      userData?.map((el) => {
        if (obj[el?.accountType]) {
          obj[el?.accountType].push({
            label: `${el?.accountName} (${el?.userId})`,
            value: el?.userId,
            id: el?._id,
          });
        }
      });
    }
    setMasterOption(obj.masterAdmin);
  }, [userData]);

  useEffect(() => {
    // dispatch(setTabChange("NSE FUT"));
    dispatch(resetExpirySetting());
  }, []);

  // function
  const onCancel = () => {
    console.log("cancle");
  };

  const onChange = (value) => {
    // console.log("change", value);
    dispatch(setTabChange(value));
  };

  const onChangeDropDown = (name, value, option) => {
    setValue(value[0]);
    // console.log("values", value[0]);
    dispatch(setUserIdInExpirySetting(value[0]));
  };

  const renderTabsInCollapse = () => {
    return (
      <Collapse>
        <Panel header="Tabs" key="1">
          <Tabs defaultActiveKey="1" onChange={onChange}>
            {items.map((item) => (
              <TabPane tab={item.label} key={item.key}>
                {item.children}
              </TabPane>
            ))}
          </Tabs>
        </Panel>
      </Collapse>
    );
  };

  return (
    <Container isloading={getLoading.toString()}>
      {getLoading && (
        <SpinnerOverlay>
          <Spin size="large" />
        </SpinnerOverlay>
      )}
      <StyledDiv>
        <Col
          xs={24}
          sm={12}
          md={8}
          lg={6}
          xl={4}
          style={{ marginLeft: "1rem" }}
        >
          <ThemeDropDown
            title="MasterAdmin"
            placeholder="Select MasterAdmin"
            name="masterAdmin"
            options={masterOption}
            width={"98%"}
            $onChange={onChangeDropDown}
            $value={value}
          />
        </Col>

        <StyledTabs
          defaultActiveKey="NSE FUT"
          items={items}
          onChange={onChange}
        />
        {/* <StyledTabs defaultActiveKey="1" onChange={onChange}>
        {items.map((item) => (
          <TabPane tab={item.label} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </StyledTabs> */}
        {/* {window.innerWidth <= 768 && renderTabsInCollapse()} */}
        <Row justify={"center"}>
          <ActionButton
            title={"Submit"}
            loading={loading}
            onClick={handleSubmit}
          />
        </Row>
      </StyledDiv>
    </Container>
  );
};

export default ExpirySetting;
