import { Col } from "antd";
import ThemeDropDown from "../ThemeDropDown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { SEGMENTS } from "../../Helper/constant";
import useGetQueryParams from "../../hooks/useGetQueryParams";

const MarketScriptList = ({ valueRef }) => {
  // state
  const [value, setValue] = useState({ marketName: null, symbolName: null });
  const [scriptdata, setScriptData] = useState(null);
  const [featching, setFeatching] = useState(false);
  const dispatch = useDispatch();
  const { params } = useGetQueryParams();

  //redux
  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const clearMarketList = useSelector(
    (state) => state.globleSlice?.userClearFilter
  );

  useEffect(() => {
    if (params?.marketName || params?.symbolName) {
      setValue({
        marketName: params.marketName || null,
        symbolName: params.symbolName || null,
      });
    }
  }, []);

  // life cycle
  useEffect(() => {
    if (clearMarketList) {
      setValue({ marketName: null, symbolName: null });
      dispatch(setUserClearFilter(false));
    }
  }, [clearMarketList]);

  useEffect(() => {
    if (valueRef.current) {
      valueRef.current = { ...valueRef.current, value };
    }
  }, [value]);

  //api
  const getScriptList = async (marketName) => {
    setFeatching(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getScriptList}${marketName}`
      );
      if (response?.status === 200 && response?.data) {
        const scriptNameOptions = response.data?.data?.map((item, index) => {
          return {
            value: item.symbol,
            label: item.symbol,
            key: index,
          };
        });

        setFeatching(false);
        setScriptData(scriptNameOptions);
      } else {
        setFeatching(false);
      }
    } catch (error) {
      console.log("error", error);
      setFeatching(false);
    }
  };

  //function
  const onChangeDropdown = (name, selectedValue, selectedOptions) => {
    if (name === "marketName" && selectedValue) {
      setValue({ [name]: selectedValue?.[0] });
      getScriptList(SEGMENTS[selectedValue?.[0]]);
    } else {
      setValue((pre) => ({ ...pre, [name]: selectedValue?.[0] }));
    }
  };

  return (
    <>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <ThemeDropDown
          title={"Market"}
          placeholder="Select"
          name="marketName"
          options={marketList}
          width={"98%"}
          showSearch={true}
          $onChange={onChangeDropdown}
          $value={value?.marketName}
          disabled={featching}
          // suffixIcon={<Spin size="small" spinning={featching} />}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <ThemeDropDown
          title={"Script Name"}
          placeholder="Script Name"
          name="symbolName"
          options={scriptdata}
          width={"98%"}
          showSearch={true}
          $onChange={onChangeDropdown}
          $value={value?.symbolName}
          // suffixIcon={<Spin size="small" spinning={featching} />}
          loadings={featching ? "true" : "false"}
          disabled={featching}
        />
      </Col>
    </>
  );
};

export default MarketScriptList;
