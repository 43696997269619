export function formatToINR(amount, market) {
  const number = parseFloat(amount);

  if (isNaN(number)) {
    return 0;
  }

  return new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
    // minimumFractionDigits: 2,
    maximumFractionDigits: findMarket(market) ? 5 : 3,
  }).format(number);
}

export function formateToINRforPosition(amount) {
  const number = parseFloat(amount);

  if (isNaN(number)) {
    return 0;
  }

  return new Intl.NumberFormat("en-IN", {
    currency: "INR",
  }).format(number);
}

export function formatForValanBill(amount) {
  const number = parseFloat(amount);

  if (isNaN(number)) {
    return 0;
  }

  return new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
    // minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }).format(number);
}

export function formatNumber(num) {
  // Check if the number is a valid number
  if (isNaN(num)) {
    return 0;
  }
  // if (num == 0) {
  //   return 0;
  // }

  // Convert the number to a string
  const numStr = num?.toString();
    if(!numStr){
      return 0
    }
  // Split the string into integer and decimal parts
  const [integerPart, decimalPart] = numStr?.split(".");

  // Handle cases with no decimal part
  if (!decimalPart) {
    return integerPart;
  }

  // Find the index of the first non-zero digit in the decimal part
  const firstNonZeroIndex = decimalPart?.search(/[1-9]/);

  // If there are no non-zero digits, return 0 followed by the decimal part
  if (firstNonZeroIndex === -1) {
    return `0.${decimalPart}`;
  }

  // Extract the first two non-zero digits and any leading zeros
  const leadingZeros = decimalPart?.slice(0, firstNonZeroIndex);
  const nonZeroDigits = decimalPart?.slice(
    firstNonZeroIndex,
    firstNonZeroIndex + 2
  );
  const formattedDecimalPart = `${leadingZeros}${nonZeroDigits}`;

  // Combine the integer and formatted decimal parts
  return `${integerPart}.${formattedDecimalPart}`;
}
export const findMarket = (market) => {
  if (market === "FOREX" || market === "GLOBEX" || market === "COMEX") {
    return true;
  } else {
    return false;
  }
};
