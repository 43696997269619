import React, { useEffect, useState } from "react";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useSelector } from "react-redux";
import { valanBillDate } from "../SummaryReport/ReportCart";
import { FilePdfOutlined } from "@ant-design/icons";
const dataSource = [
  {
    key: 1,
    date: "30/6/2023",
    itemDetails: "Valan bill 26 june to 30 june",
    debit: "15480",
    credit: "15480",
    balance: "15480",
    remark: "entry by 2345",
  },
  {
    key: 1,
    date: "30/6/2023",
    itemDetails: "Valan bill 26 june to 30 june",
    debit: "15480",
    credit: "15480",
    balance: "15480",
    remark: "entry by 2345",
  },
  {
    key: 1,
    date: "30/6/2023",
    itemDetails: "Valan bill 26 june to 30 june",
    debit: "15480",
    credit: "15480",
    balance: "15480",
    remark: "entry by 2345",
  },
  {
    key: 1,
    date: "30/6/2023",
    itemDetails: "Valan bill 26 june to 30 june",
    debit: "15480",
    credit: "15480",
    balance: "15480",
    remark: "entry by 2345",
  },
  {
    key: 1,
    date: "30/6/2023",
    itemDetails: "Valan bill 26 june to 30 june",
    debit: "15480",
    credit: "15480",
    balance: "15480",
    remark: "entry by 2345",
  },
];

function AccountPointLedger() {
  // const [ledgerData, setLedgerData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.refetchQueries(["getLedger"]);
  }, []);

  // api call
  const getLegder = async () => {
    // setLoading(true);
    // try {
    let response = await APICall("get", `${EndPoints.getLedger}`);
    console.log("getLedger", response?.data?.data);
    return response?.data?.data?.entries;
    //   if (response.status === 200) {
    //     setLedgerData(response?.data?.data);
    //   }
    //   setLoading(false);
    //   console.log("response", response);
    // } catch (error) {
    //   console.log("error", error);
    //   setLoading(false);
    // }
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["getLedger"],
    queryFn: getLegder,
  });

  //column
  const columns = [
    isLoading
      ? {
          title: "Sr No.",
          dataIndex: "date",
          key: "date",
          width: "5rem",
          align: "center",
        }
      : {
          title: "Sr No.",
          dataIndex: "date",
          key: "date",
          width: "5rem",
          align: "center",
          style: { marginLeft: "1rem" },
          render: (text, record, index) => {
            return <span style={{ marginLeft: "1rem" }}>{index + 1}</span>;
          },
        },
    {
      title: "NAME",
      dataIndex: "remark",
      key: "remark",
      align: "center",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{`${record?.userId} (${record?.accountName})`}</div>
          )
        );
      },
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.date, true);
        return !isLoading && <span>{finalDate}</span>;
      },
    },
    {
      title: "DEBIT",
      dataIndex: "debit",
      key: "debit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "debit") {
          return !isLoading && <span>{record?.amount}</span>;
        } else {
          return !isLoading && "-";
        }
      },
    },
    {
      title: "CREDIT",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "credit") {
          return !isLoading && <span>{record?.amount}</span>;
        } else {
          return !isLoading && "-";
        }
      },
    },
    {
      title: "BALANCE",
      dataIndex: "ledgerBalance",
      key: "ledgerBalance",
      align: "center",
    },
    {
      title: "BILL",
      dataIndex: "bill",
      key: "bill",
      align: "center",
      render: (text, record) => {
        return (
          !isLoading && (
            <FilePdfOutlined
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          )
        );
      },
    },
  ];

  return (
    <>
      <ThemeCardTable
        buttonType={false}
        data={data ? data : []}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading}
      />
    </>
  );
}

export default AccountPointLedger;
