import { Button, Form } from "antd";
import styled from "styled-components";

export const ThemeForm = styled(Form)`
  .ant-form-item-explain-error {
    /* font-size: 1rem; // Adjust this size as needed */
  }
`;
export const RemoveButton = styled(Button)`
  border-radius: ${(props) => props?.theme?.token?.boxShadowNone};
  font-size: 1rem;
  width: 100%;
  height: 3rem;
  background-color: #bf2114;
  color: #fff;
  box-shadow: ${(props) => props?.theme?.token?.boxShadowNone};
`;
export const SubmitButton = styled(Button)`
  border-radius: ${(props) => props?.theme?.token?.boxShadowNone};
  font-size: 1rem;
  width: 100%;
  height: 3rem;
  background-color: ${(props) => props?.theme?.token?.colorPrimary};
  color: #fff;
  box-shadow: ${(props) => props?.theme?.token?.boxShadowNone};
  margin-right: 2rem;
`;
