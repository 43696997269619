import React, { useEffect, useRef } from "react";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { Col, Row, Tag, notification } from "antd";
import ThemeCard from "../../components/ThemeCard";
import {
  PdfDiv,
  StyledSpan,
  SubmitButton,
  accountTypeObj,
} from "../SummaryReport/style";
import ThemeCardTable from "../../components/ThemeCardTable";
import { valanBillDate } from "../SummaryReport/ReportCart";
import { StyledDiv } from "../../components/ThemeCardTable/style";
import {
  findMarket,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { limitNumberLength } from "../../components/SocketLiveData";
import { useQuery } from "@tanstack/react-query";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import APICall from "../../APICall";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { useDispatch, useSelector } from "react-redux";
import { showValue } from "../../components/CommanFuction/CommanFunction";
import { FilePdfOutlined } from "@ant-design/icons";

const BrokerSharingReport = () => {
  const valueRef = useRef({});
  const { params, setSearchParams } = useGetQueryParams();
  const dispatch = useDispatch();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  //life cycle
  useEffect(() => {
    // setSearchParams({
    //   ...params,
    //   userId: params?.userId ? params?.userId : loginId,
    // });
  }, []);
  useEffect(() => {
    // if (Object.keys(params)?.length === 0) {
    //   setSearchParams({ userId: loginId });
    // }
  }, [params]);

  const handleSubmit = () => {
    let refValue = valueRef.current;
    for (let key in refValue) {
      if (!refValue[key]) delete refValue[key];
    }
    console.log({ refValue });
  };

  const handleReset = () => {
    valueRef.current = {};
    dispatch(setUserClearFilter(true));
    // setSearchParams({ userId: loginId });
  };

  const getBrokerageSharingReport = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    // let response=await APICall()
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["brokerSharingReport", params],
    queryFn: getBrokerageSharingReport,
    enabled: Object.keys(params)?.length > 0,
  });

  // column
  const columns = [
    {
      title: "SR NO.",
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
      width: "4rem",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "USER INFO",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      width: "16rem",
      render: (text, record) =>
        !isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <span
              style={{ marginRight: "auto", textAlign: "left" }}
            >{`${record?.userId} (${record?.AccountName})`}</span>
            <Tag color="magenta" bordered={false} style={{ fontSize: "1rem" }}>
              {accountTypeObj[record?.AccountType]}
            </Tag>
          </div>
        ),
    },
    {
      title: "VALAN",
      dataIndex: "valan",
      key: "valan",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return !isLoading && <span>{record?.valan?.valanName}</span>;
      },
    },
    {
      title: "TOTAL VALAN BILL",
      dataIndex: "totalValanBill",
      key: "totalValanBill",
      align: "center",
      width: "16rem",
      render: (text, record) => {
        const valanId = valueRef.current?.valan;
        return (
          !isLoading && (
            <StyledSpan>
              <div style={{ width: "80%" }}>
                {showValue(record?.totalNetAmount)}
              </div>
              <PdfDiv>
                <FilePdfOutlined
                //   onClick={() => {
                //     navigate(`/valanBill/${record?.userId}`);
                //     dispatch(
                //       setSummaryDataFilter({
                //         // ...valueRef.current,
                //         withoutBrokerage: false,
                //         parentId: valueRef.current?.userId
                //           ? valueRef.current?.userId
                //           : loginId,
                //         marketName: valueRef.current?.value?.marketName || null,
                //         symbolName: valueRef.current?.value?.symbolName || null,
                //         valan: valueRef.current?.valan || null,
                //         startDate: valueRef.current?.startDate || null,
                //         endDate: valueRef.current?.endDate || null,
                //       })
                //     );
                //   }}
                />
              </PdfDiv>
            </StyledSpan>
          )
        );
      },
    },
    {
      title: "BROKERAGE",
      dataIndex: "brokerage",
      key: "brokerage",
      align: "center",
      width: "12rem",
      render: (text, record) => {
        return !isLoading && <div>{formatToINR(record?.brokerage || 0)}</div>;
      },
    },
    {
      title: "Broker 1",
      align: "center",
      width: "12rem",
      children: [
        {
          title: "Name",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            const finalDate = valanBillDate(record?.createdAt, true);
            return !isLoading && <StyledDiv>{finalDate}</StyledDiv>;
          },
        },
        {
          title: "PR",
          dataIndex: "",
          align: "center",
          key: "time",
          width: "5rem",
          render: (text, record) => {
            return !isLoading && <span>{"-"}</span>;
          },
        },
        {
          title: "ID",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            const modifiedId =
              `${record?.modifiedBy?.userId}(${record?.modifiedBy?.accountName})` ||
              "-";
            return !isLoading && <StyledDiv>{modifiedId}</StyledDiv>;
          },
        },
      ],
    },
    {
      title: "Broker 2",
      align: "center",
      width: "12rem",
      children: [
        {
          title: "Name",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            const finalDate = valanBillDate(record?.createdAt, true);
            return !isLoading && <StyledDiv>{finalDate}</StyledDiv>;
          },
        },
        {
          title: "PR",
          dataIndex: "",
          align: "center",
          key: "time",
          width: "5rem",
          render: (text, record) => {
            return !isLoading && <span>{"-"}</span>;
          },
        },
        {
          title: "ID",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            const modifiedId =
              `${record?.modifiedBy?.userId}(${record?.modifiedBy?.accountName})` ||
              "-";
            return !isLoading && <StyledDiv>{modifiedId}</StyledDiv>;
          },
        },
      ],
    },
    {
      title: "Broker 3",
      align: "center",
      width: "12rem",
      children: [
        {
          title: "Name",
          dataIndex: "",
          align: "center",
          key: "date",
          width: "6rem",
          render: (text, record) => {
            const finalDate = valanBillDate(record?.createdAt, true);
            return !isLoading && <StyledDiv>{finalDate}</StyledDiv>;
          },
        },
        {
          title: "PR",
          dataIndex: "",
          align: "center",
          key: "time",
          width: "5rem",
          render: (text, record) => {
            return !isLoading && <span>{"-"}</span>;
          },
        },
        {
          title: "ID",
          dataIndex: "",
          align: "center",
          key: "id",
          width: "6rem",
          render: (text, record) => {
            const modifiedId =
              `${record?.modifiedBy?.userId}(${record?.modifiedBy?.accountName})` ||
              "-";
            return !isLoading && <StyledDiv>{modifiedId}</StyledDiv>;
          },
        },
      ],
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row align={"bottom"} gutter={[20, 15]}>
          <UserFilterComponant
            valueRef={valueRef}
            valan={true}
            userType="Customer"
          />
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SubmitButton type="primary" onClick={handleSubmit}>
              Search
            </SubmitButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={3}>
            <SubmitButton type="primary" onClick={handleReset}>
              Clear Search
            </SubmitButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={[]}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={false}
        // filterType="brokerage"
        // footer={true}
      />
    </>
  );
};

export default BrokerSharingReport;
