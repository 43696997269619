import {
  Button,
  Col,
  Modal,
  Row,
  Switch,
  Typography,
  notification,
} from "antd";
import React, { useEffect } from "react";
import ThemeInput from "../../components/ThemeInput";
import { styled } from "styled-components";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeTimePicker from "../../components/ThemeTimePicker";
import { useState } from "react";
import dayjs from "dayjs";
import "./marketSetting.css";

const StyleButton = styled(Button)`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: white;
  width: 100%;

  @media (max-width: 576px) {
    margin-bottom: 2rem;
  }
`;
const StyledTheme = {
  // Space: styled(Space)`
  //   .ant-radio-wrapper {
  //     font-size: 1.1rem;
  //     min-width: 5rem;
  //   }
  // `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      width: ${(props) => props.$width};
      color: ${(props) => props.theme.token.colorTextLabel};
      font-family: ${(props) => props.theme.token.fontFamily};
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0rem;
      line-height: 1.125rem;
      text-align: left;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
  `,
};

const MarketCart = ({ market, index, getMarketStatus, values }) => {
  // state
  const [disable, setDisable] = useState({
    "NSE FUT": true,
    "NSE OPT": true,
    "NSE EQU": true,
    MCX: true,
    "MCX OPT": true,
    FOREX: true,
    COMEX: true,
    GLOBEX: true,
  });

  const [value, setValue] = useState({});
  const [show, setShow] = useState({
    open: false,
    market: null,
  });

  useEffect(() => {
    if (values) {
      setValue({ ...values });
    }
  }, [values]);
  // console.log("value", values);
  // function
  const onChange = (status, market) => {
    const status1 = status ? "online" : "offline";
    setValue({
      ...value,
      status: status1,
    });
  };

  const handleEdit = (value) => {
    setDisable({ ...disable, [value]: !disable[value] });
    setShow({ open: false, market: null });
  };

  const handleChangeStartTime = (e) => {
    const { name, value } = e.target;
    // console.log("op", name, "value", value);

    setValue((pre) => {
      return { ...pre, sessionStartTime: dayjs(value) };
    });
  };

  const handleChangeEndTime = (e) => {
    const { name, value } = e.target;
    // console.log("op", name, value);

    setValue((pre) => {
      return { ...pre, sessionEndTime: dayjs(value) };
    });
  };

  const handleChangeStartAfter = (e) => {
    const { name, value } = e.target;
    // console.log("op", name, value);

    setValue((pre) => {
      return { ...pre, startAfter: dayjs(value) };
    });
  };

  const handleChangeEndBefore = (e) => {
    const { name, value } = e.target;
    // console.log("op", name, value);

    setValue((pre) => {
      return { ...pre, endBefore: dayjs(value) };
    });
  };

  const handleSave = (market) => {
    setShow({ open: true, market: market });
  };

  const handleCancel = () => {
    setShow({ open: false, market: null });
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        key={index}
        style={{
          margin: "auto",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Col xs={12} sm={4} md={4} lg={3}>
          <StyledTheme.Title>{market}</StyledTheme.Title>
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <Switch
            checkedChildren="online"
            unCheckedChildren="offline"
            onChange={(value) => onChange(value, market)}
            value={value?.status === "online" ? true : false}
            disabled={disable[market]}
            style={{
              width: "100%",
              backgroundColor: value?.status === "online" ? "green" : "red",
            }}
          />
        </Col>
        <Col className="columnTitle" xs={24}>
          MarketTrade
        </Col>
        <Col xs={12} sm={5} md={5} lg={3}>
          <ThemeTimePicker
            title="Start At"
            width={"100%"}
            inputProps={{
              value: value?.sessionStartTime,
              name: market,
              onChange: handleChangeStartTime,
              disabled: disable[market],
            }}
          />
        </Col>
        <Col xs={12} sm={5} md={5} lg={3}>
          <ThemeTimePicker
            title="End At"
            width={"100%"}
            inputProps={{
              value: value?.sessionEndTime,
              name: market,
              onChange: handleChangeEndTime,
              disabled: disable[market],
            }}
          />
        </Col>
        <Col className="columnTitle" xs={24}>
          Limit Trade
        </Col>
        <Col xs={12} sm={5} md={5} lg={3}>
          <ThemeTimePicker
            title="StartAfter"
            width={"100%"}
            inputProps={{
              value: value?.startAfter,
              name: market,
              onChange: handleChangeStartAfter,
              disabled: disable[market],
              format: "mm",
            }}
          />
        </Col>
        <Col xs={12} sm={5} md={5} lg={3}>
          <ThemeTimePicker
            title="EndBefore"
            width={"100%"}
            inputProps={{
              value: value?.endBefore,
              name: market,
              onChange: handleChangeEndBefore,
              disabled: disable[market],
              format: "mm",
            }}
          />
        </Col>
        <Col xs={24} sm={4} md={4} lg={3}>
          <StyleButton
            onClick={() =>
              disable[market] ? handleEdit(market) : handleSave(market)
            }
          >
            {disable[market] ? "Edit" : "Save"}
          </StyleButton>
        </Col>
      </Row>
      {show.open && (
        <Modal
          title={"Passward Authentication"}
          open={show.open}
          onCancel={handleCancel}
          footer={false}
        >
          <UpdateMarketSetting
            value={value}
            market={show?.market}
            setShow={setShow}
            getMarketStatus={getMarketStatus}
            setDisable={setDisable}
          />
        </Modal>
      )}
    </>
  );
};

export default MarketCart;

const UpdateMarketSetting = ({
  value,
  market,
  setShow,
  getMarketStatus,
  setDisable,
}) => {
  // state
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  let obj = { password: pass };

  // function
  const converter = (datetimeStr, minute) => {
    let date = new Date(datetimeStr);
    let timeStr = date.toTimeString().split(" ")[0];
    if (minute) {
      return timeStr.split(":")?.[1];
    } else {
      return timeStr;
    }
  };
  const updateMarketStatus = async () => {
    let newObj = {};
    let payload = value;
    for (let key in payload) {
      if (payload[key] !== "status") {
        if (!newObj[key]) {
          const minute =
            key == "endBefore" || key == "startAfter" ? true : false;
          if (minute) {
            newObj["limitTradeAllowance"] = {
              ...newObj["limitTradeAllowance"],
              [key]: converter(payload[key], minute),
            };
          } else {
            newObj[key] = {};
            newObj[key] = converter(payload[key], minute);
          }
        }
      }
    }
    newObj.status = payload.status;
    newObj.marketName = market;
    try {
      let response = await APICall(
        "patch",
        `${EndPoints.getMarketStatus}update-market-timing`,
        newObj
      );
      // console.log("updateMarket response", response);
      if (response.status === 200) {
        setShow({ open: false, market: null });
        notification.success({ message: response.data.message });
        getMarketStatus();
        setDisable((pre) => ({ ...pre, [market]: true }));
      } else {
        notification.error({ message: response.data.message });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const checkPassward = async () => {
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.passwordVerification, obj);
      // console.log("🚀 ~ checkPassward ~ response:", response);
      if (response.status === 200) {
        // console.log("res", response.data);
        updateMarketStatus();
      } else {
        setLoading(false);
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const onChange = (e) => {
    setPass(e.target.value);
  };

  return (
    <>
      <Row justify={"center"} gutter={20} style={{ marginTop: "2rem" }}>
        <Col span={10}>
          <ThemeInput
            title="Password"
            inputProps={{
              name: "accountPassword",
              value: pass,
              onChange: onChange,
              // disabled: is_edits,
            }}
          />
        </Col>
        <Col span={4}>
          <Button
            style={{ marginTop: "1.5rem" }}
            loading={loading}
            onClick={checkPassward}
          >
            Submit
          </Button>
        </Col>
      </Row>
      {/* <Row justify={"center"}></Row> */}
    </>
  );
};
