import { Col, Row, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { setMarketAccess } from "../../store/slice/authSlice";
import styled from "styled-components";
import { formatToINR } from "../../components/FormateToINR/formatToINR";

const StyledTable = styled(Table)`
  transform: none !important;
  width: 100% !important;

  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.5rem;
  }

  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
    /* font-size: 3.2rem !important;
    padding: 0.5rem !important; */
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }

  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }

  .ant-table-thead .ant-table-cell:hover {
    background: ${(props) => props.theme.token.colorPrimaryBgHover} !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;

const Profile = () => {
  // state
  const [margin, setMargin] = useState({});
  const [loading, setLoading] = useState(false);
  let show = false;
  const dispatch = useDispatch();
  // redux
  const parentDetails = useSelector(
    (state) => state.authSlice?.MarketAccessDetails
  );
  const LoginDetails = useSelector((state) => state.authSlice?.userDetail);
  const LoginAccountType = LoginDetails?.accountType;
  const loginId = LoginDetails?.userId;
  const isRent = LoginDetails?.partnershipWith === "rent";
  const accountDetails = parentDetails?.accountDetails;
  const marginDetails = parentDetails?.marginDetails;
  const brokerageDetails = parentDetails?.brokarageDetails;

  // life cycle
  useEffect(() => {
    // getMarginDetails();
    getUserDetails();
  }, []);

  // api call
  const getUserDetails = async () => {
    setLoading(true);
    try {
      let response = await APICall("get", `${EndPoints.getUser}${loginId}`);
      if (response.status === 200) {
        // console.log("login", response.data?.data);
        const userInfo = response?.data?.data;
        console.log("🚀 ~ getUserDetails ~ userInfo:", userInfo);
        let brokarageDetails = {
          "NSE FUT": [],
          "NSE OPT": [],
          "NSE EQU": [],
          MCX: [],
          "MCX OPT": [],
          FOREX: [],
          COMEX: [],
          GLOBEX: [],
        };
        let marginDetails = {
          "NSE FUT": [],
          "NSE OPT": [],
          "NSE EQU": [],
          MCX: [],
          "MCX OPT": [],
          FOREX: [],
          COMEX: [],
          GLOBEX: [],
        };
        for (let i = 0; i < userInfo.sharingBrokerageSchema?.length; i++) {
          if (userInfo.sharingBrokerageSchema[i]?.marketAccessId?.scriptName) {
            brokarageDetails[
              userInfo.sharingBrokerageSchema[i].marketAccessId.scriptName
            ] = userInfo.sharingBrokerageSchema[i];
          }
        }
        for (let j = 0; j < userInfo.margin?.length; j++) {
          if (userInfo.margin[j]?.marketAccessId?.scriptName) {
            marginDetails[userInfo.margin[j].marketAccessId.scriptName] =
              userInfo.margin[j];
          }
        }
        const accountDetails = userInfo?.accountDetails;
        dispatch(
          setMarketAccess({
            accountDetails,
            brokarageDetails,
            marginDetails,
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  // data
  let brokerageObj = {
    "NSE FUT": {
      delivery: brokerageDetails?.["NSE FUT"]?.deliveryTotal,
      intraday: brokerageDetails?.["NSE FUT"]?.intradayTotal,
    },
    "NSE OPT": {
      delivery: brokerageDetails?.["NSE OPT"]?.deliveryTotal,
      intraday: brokerageDetails?.["NSE OPT"]?.intradayTotal,
    },
    "NSE EQU": {
      delivery: brokerageDetails?.["NSE EQU"]?.deliveryTotal,
      intraday: brokerageDetails?.["NSE EQU"]?.intradayTotal,
    },
    "MCX OPT": {
      delivery: brokerageDetails?.["MCX OPT"]?.deliveryTotal,
      intraday: brokerageDetails?.["MCX OPT"]?.intradayTotal,
    },
    MCX: {
      delivery: brokerageDetails?.MCX?.deliveryTotal,
      intraday: brokerageDetails?.MCX?.intradayTotal,
    },
    FOREX: {
      delivery: brokerageDetails?.FOREX?.deliveryTotal,
      intraday: brokerageDetails?.FOREX?.intradayTotal,
    },
    COMEX: {
      delivery: brokerageDetails?.COMEX?.deliveryTotal,
      intraday: brokerageDetails?.COMEX?.intradayTotal,
    },
  };

  let marginObj = {
    "NSE FUT": {
      current: marginDetails?.["NSE FUT"]?.delivery,
      history: marginDetails?.["NSE FUT"]?.history?.[0]?.delivery,
    },
    "NSE OPT": {
      current: marginDetails?.["NSE OPT"]?.delivery,
      history: marginDetails?.["NSE OPT"]?.history?.[0]?.delivery,
    },
    "NSE EQU": {
      current: marginDetails?.["NSE EQU"]?.delivery,
      history: marginDetails?.["NSE EQU"]?.history?.[0]?.delivery,
    },
    "MCX OPT": {
      current: marginDetails?.["MCX OPT"]?.delivery,
      history: marginDetails?.["MCX OPT"]?.history?.[0]?.delivery,
    },
    MCX: {
      current: marginDetails?.MCX?.delivery,
      history: marginDetails?.MCX?.history?.[0]?.delivery,
    },
    FOREX: {
      current: marginDetails?.FOREX?.delivery,
      history: marginDetails?.FOREX?.history?.[0]?.delivery,
    },
    COMEX: {
      current: marginDetails?.COMEX?.delivery,
      history: marginDetails?.COMEX?.history?.[0]?.delivery,
    },
  };

  let obj = {
    masterAdmin: {
      current: accountDetails?.allowNoOfAccountMasterAdmin,
      history: accountDetails?.totalAccounts?.[0]?.MasterAdmin,
    },
    superAdmin: {
      current: accountDetails?.allowNoOfAccountSuperAdmin,
      history: accountDetails?.totalAccounts?.[0]?.SuperAdmin,
    },
    admin: {
      current: accountDetails?.allowNoOfAccountAdmin,
      history: accountDetails?.totalAccounts?.[0]?.Admin,
    },
    master: {
      current: accountDetails?.allowNoOfAccountMaster,
      history: accountDetails?.totalAccounts?.[0]?.Master,
    },
    dealer: {
      current: accountDetails?.allowNoOfAccountDealer,
      history: accountDetails?.totalAccounts?.[0]?.Dealer,
    },
    customer: {
      current: accountDetails?.allowNoOfAccountCustomer,
      history: accountDetails?.totalAccounts?.[0]?.Customer,
    },
    broker: {
      current: accountDetails?.allowNoOfAccountBroker,
      history: accountDetails?.totalAccounts?.[0]?.Broker,
    },
  };

  const accountArray = Object.keys(obj)
    ?.filter((el) => {
      if (el === LoginAccountType) {
        show = true;
      }
      return (LoginAccountType === "owner" || show) && el !== LoginAccountType;
    })
    ?.map((el) => {
      return { type: el, ...obj[el] };
    });

  const marginArray = Object.keys(marginObj)
    ?.filter((el) => {
      return marginObj[el]?.current || marginObj[el]?.current == 0;
    })
    ?.map((el) => {
      return { market: el, ...marginObj[el] };
    });

  const brokerageArray = Object.keys(brokerageObj)
    .filter((el) => {
      return brokerageObj[el]?.delivery || brokerageObj[el]?.delivery === 0;
    })
    .map((el) => {
      return { market: el, ...brokerageObj[el] };
    });

  // column
  const accountColumns = [
    {
      title: "Account",
      key: "accountType",
      width: 100,
      render: (text, record) => <div>{record?.type}</div>,
    },
    {
      title: "Remaining",
      key: "remaining",
      width: 100,
      render: (text, record) => <div>{record?.current}</div>,
    },
    {
      title: "Total",
      key: "history",
      width: 100,
      render: (text, record) => <div>{record?.history}</div>,
    },
  ];
  const marginColumns = [
    {
      title: "Market",
      key: "market",
      width: 100,
      render: (text, record) => <div>{record?.market}</div>,
    },
    {
      title: "Remaining",
      key: "remaining",
      width: 100,
      render: (text, record) => <div>{formatToINR(record?.current)}</div>,
    },
    {
      title: "Total",
      key: "history",
      width: 100,
      render: (text, record) => <div>{formatToINR(record?.history)}</div>,
    },
  ];
  const brokerageColumns = [
    {
      title: "Market",
      key: "market",
      width: 100,
      render: (text, record) => <div>{record?.market}</div>,
    },
    {
      title: "Delivery",
      key: "remaining",
      width: 100,
      render: (text, record) => <div>{record?.delivery}</div>,
    },
    {
      title: "Intraday",
      key: "history",
      width: 100,
      render: (text, record) => <div>{record?.intraday}</div>,
    },
  ];

  return (
    <>
      <ThemeCard
        title="User Details"
        bordered={false}
        style={{
          marginBlock: "3rem",
          width: "100%",
          fontSize: "2.3rem",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <Spin size="medium" />
          </div>
        ) : (
          <Row
            gutter={[20, 40]}
            align={"center"}
            style={{ textAlign: "center" }}
          >
            {LoginAccountType !== "customer" && (
              <Col
                sm={24}
                md={12}
                lg={12}
                xl={isRent ? 12 : 8}
                style={{ overflowX: "hidden" }}
              >
                <Typography.Title level={5}>
                  Allow No. of accounts
                </Typography.Title>
                <StyledTable
                  columns={accountColumns}
                  dataSource={accountArray}
                  pagination={false}
                  size="small"
                  scroll={{ x: false }}
                />
              </Col>
            )}
            {!isRent && (
              <Col
                sm={24}
                md={12}
                lg={12}
                xl={8}
                style={{ overflowX: "hidden" }}
              >
                <Typography.Title level={5}>Margin Details</Typography.Title>
                <StyledTable
                  columns={marginColumns}
                  dataSource={marginArray}
                  pagination={false}
                  size="small"
                  scroll={{ x: false }}
                />
              </Col>
            )}
            {!isRent && (
              <Col
                sm={24}
                md={12}
                lg={12}
                xl={8}
                style={{ overflowX: "hidden" }}
              >
                <Typography.Title level={5}>Brokerage Details</Typography.Title>
                <StyledTable
                  columns={brokerageColumns}
                  dataSource={brokerageArray}
                  pagination={false}
                  size="small"
                  scroll={{ x: false }}
                />
              </Col>
            )}
          </Row>
        )}
      </ThemeCard>
    </>
  );
};

export default Profile;
