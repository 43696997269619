import { useState } from "react";
import EndPoints from "../../../../APICall/EndPoints";
import { Col, Popover, Row, Skeleton, Tag } from "antd";
import { labelObj } from "../../../../Helper/constant";
import APICall from "../../../../APICall";

const UPlineComponant = ({ record, index }) => {
  // state
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [uplineLoading, setUplineLoading] = useState(false);
  const [uplineData, setUplineData] = useState([]);

  const isOpen = openPopoverIndex === index;

  // function
  const content = (index) => {
    return (
      <div>
        {uplineLoading ? (
          <Skeleton active />
        ) : (
          uplineData?.map((el) => {
            return (
              <Row
                gutter={[10, 10]}
                style={{ marginBottom: "0.2rem" }}
                key={el?._id}
              >
                <Col span={6}>
                  <Tag
                    color="magenta"
                    bordered={false}
                    style={{
                      fontSize: "1rem",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {labelObj[el?.accountType]}
                  </Tag>
                </Col>

                <Col>{`: ${el?.accountName} (${el?.userId})`}</Col>
              </Row>
            );
          })
        )}
        {/* <Row justify={"center"}>
            <Button
              className="regularUserTableBtn"
              onClick={() => handleOpenChange(index)}
              style={{ marginTop: "1rem" }}
            >
              Close
            </Button>
          </Row> */}
      </div>
    );
  };

  const handleOpenChange = async (index, record) => {
    setOpenPopoverIndex(index === openPopoverIndex ? null : index);
    if (index !== openPopoverIndex) {
      const id = record?.userId;

      setUplineLoading(true);
      try {
        let response = await APICall("get", `${EndPoints.getUpline}${id}`);
        // console.log("upline", response);
        if (response.status === 200) {
          setUplineData(response?.data?.data);
        }
        setUplineLoading(false);
      } catch (error) {
        setUplineLoading(false);
        console.log("error", error);
      }
    }
  };

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <Popover
          content={() => content(index)}
          title="Upline List"
          trigger="click"
          placement="top"
          open={isOpen}
          onOpenChange={() => handleOpenChange(index, record)}
        >
          {`${record?.parent?.userId} (${record?.parent?.accountName})`}
        </Popover>
      </div>
    </>
  );
};

export default UPlineComponant;
