import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ThemeCard from "../../../components/ThemeCard";
import ThemeDropDown from "../../../components/ThemeDropDown";
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Spin,
  Tabs,
  notification,
  theme,
} from "antd";
import ThemeInput from "../../../components/ThemeInput";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  initialState,
  resetUserAccountSlice,
  setNewAccountInfo,
} from "../../../store/slice/userAccountSlice";
import ThemeTextLabel from "../../../components/ThemeTextLabel";
import {
  InitialAdditionalInfo,
  RentOption,
  accountObj,
  options,
} from "../../../Helper/constant";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import store from "../../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { saveDetails } from "../../../store/slice/globleSlice";
import { setMarketAccess } from "../../../store/slice/authSlice";
import { Container, SpinnerOverlay } from "../../MarketSetting/MarketSetting";
import { useForm } from "antd/es/form/Form";
import { payloadObject } from "../NewAccount/payloadObject";
import CreateUserModal from "../NewAccount/components/CreateUserModal";
import { removeFieldsWithXXYY } from "../NewAccount/components/UtilityFuction/removeFieldsWithXXYY";
import AdditionalInfoView from "../NewAccount/components/AdditionalInfoView";
import AccountOpeningView from "../NewAccount/components/AccountOpeningView";
import ThemeDatePicker from "../../../components/ThemeDatePicker";
import AccountDetailsRadioView from "../NewAccount/AccountDetailsRadioView";
import ThemeInputNumber from "../../../components/ThemeInputNumber/ThemeInputNumber";
import { Details, MarketAccess, MarketTab, StyleRow } from "./style.js";

const CreateRentAccount = ({ is_edits }) => {
  const loginDetail = useSelector((state) => state.authSlice?.userDetail);
  const loginId = loginDetail?.userId;
  const logintype = loginDetail?.accountType;

  // state
  const [loading, setLoading] = useState(false);
  const [newUserDetails, setNewUserDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parentLoading, setParentLoading] = useState(
    logintype === "owner" && !is_edits ? false : true
  );
  const [initialValues, setInitialValues] = useState({
    ...InitialAdditionalInfo,
    accountPassword: 1234,
  });
  const userId = useParams()?.id;
  const formRef = useRef(null);
  const [rentForm] = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux
  const childMarketAccess = useSelector(
    (state) => state?.userAccountSlice?.childMarketAccess
  );
  const initalMarket = useSelector(
    (state) => state?.globleSlice?.constantValue?.marketAccess
  );
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const marketList = useSelector(
    (state) => state?.userAccountSlice?.market_access
  );

  //life cycle
  useLayoutEffect(() => {
    if (is_edits && userId) {
      getuserDetails();
      setParentLoading(true);
      // getParentDetails(userId);
    } else {
      dispatch(resetUserAccountSlice());
      logintype !== "owner" && getParentDetails(loginId);
    }
  }, [is_edits]);

  // api call
  const handleSubmit = async (payloadObj) => {
    let autoLock = rentForm.getFieldValue("autoLock");
    let remark = rentForm.getFieldValue("remark");
    let rent = rentForm.getFieldValue("rent");
    let expiryDate = rentForm.getFieldValue("expiryDate");
    let isExpiryMessageBeforeDays = rentForm.getFieldValue(
      "isExpiryMessageBeforeDays"
    );
    let expiryMessageBeforeDays = rentForm.getFieldValue(
      "expiryMessageBeforeDays"
    );
    const market = [...new Set(marketList)];
    setLoading(true);
    let payload = {};
    if (!is_edits) {
      payload = {
        ...payloadObj,
        basicInfo: {
          ...payloadObj.basicInfo,
          autoLock: autoLock,
          isExpiryMessageBeforeDays: isExpiryMessageBeforeDays,
          expiryMessageBeforeDays: expiryMessageBeforeDays || null,
          expiryDate: expiryDate || null,
          rent: rent || null,
        },
        accountDetails: {
          ...payloadObj.accountDetails,
          partnershipWith: "rent",
          allowNoOfAccountMasterAdmin: 0,
          remark: remark || null,
        },
      };
      // return console.log({ payload });
    } else {
      payload = removeFieldsWithXXYY(payloadObj);
      const deletedMarket = initalMarket?.reduce((acc, curr) => {
        let avalible = market?.includes(curr);
        if (!avalible) {
          acc.push(curr);
        }
        return acc;
      }, []);
      const addedMarket = market?.reduce((acc, curr) => {
        let avalible = initalMarket?.includes(curr);
        if (!avalible) {
          acc.push(curr);
        }
        return acc;
      }, []);
      payload.removeMarket = deletedMarket;
      payload.marketAccess = addedMarket;
    }

    const state = store.getState()?.userAccountSlice;
    const accounts = state?.accountDetails;

    if (market.length < 1) {
      setLoading(false);
      return notification.error({
        message: "Plase select market before submit",
      });
    }

    try {
      let response = await APICall(
        "post",
        is_edits ? `${EndPoints.editUsers}${userId}` : EndPoints.register,
        payload
      );
      // console.log("response", response);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        dispatch(resetUserAccountSlice());
        if (is_edits) {
          navigate(`/users/accounts/rent`);
        }
        let obj = {
          accountType: accountType,
          authorityLevel: state?.authorityLevel,
        };
        dispatch(setNewAccountInfo(obj));
        setIsModalOpen(true);
        setNewUserDetails({
          ...response?.data?.data,
          passward:
            store.getState().userAccountSlice.basicInfo?.accountPassword,
        });
        {
          accountType !== "masterAdmin" && getParentDetails(loginId);
        }
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    } finally {
      setLoading(false);
    }
  };
  const getuserDetails = async () => {
    try {
      let response = await APICall("get", `${EndPoints.getUser}${userId}`);
      if (response?.status === 200 && response?.data) {
        let userInfo = response?.data?.data;
        // setParentId(userInfo?.parent);
        let constantValue = {
          "NSE FUT": {},
          "NSE OPT": {},
          "NSE EQU": {},
          MCX: {},
          "MCX OPT": {},
          FOREX: {},
          COMEX: {},
          GLOBEX: {},
        };
        let marketAccess = userInfo?.marketAccess?.map(
          (item) => item?.scriptName
        );
        if (is_edits) {
          constantValue.balance = userInfo?.user?.balance;
          constantValue.allowNoOfAccountSuperAdmin =
            userInfo.accountDetails?.allowNoOfAccountSuperAdmin || 0;
          constantValue.allowNoOfAccountAdmin =
            userInfo.accountDetails?.allowNoOfAccountAdmin || 0;
          constantValue.allowNoOfAccountMaster =
            userInfo.accountDetails?.allowNoOfAccountMaster || 0;
          constantValue.allowNoOfAccountBroker =
            userInfo.accountDetails?.allowNoOfAccountBroker || 0;
          constantValue.allowNoOfAccountDealer =
            userInfo.accountDetails?.allowNoOfAccountDealer || 0;
          constantValue.allowNoOfAccountCustomer =
            userInfo.accountDetails?.allowNoOfAccountCustomer || 0;
          constantValue.balance = userInfo?.user?.balance || 0;
          constantValue.marketAccess = marketAccess || [];
          setInitialValues({
            ...InitialAdditionalInfo,
            ...userInfo?.accountDetails,
            ...userInfo?.additionalInfoSchema?.[0],
            ...userInfo?.user,
          });
        }
        // const childMarketAccess = userInfo?.childMarketAccess;
        let obj = {
          accountType: userInfo?.user?.accountType,
          authorityLevel: userInfo?.user?.authorityLevel,
          is_sample_account: false,
          is_edit: true,
          basicInfo: {
            accountName: userInfo?.user?.accountName,
            accountId: userInfo?.user?.userId,
            balance: userInfo?.user?.balance,
            dopositeHistory: userInfo?.user?.history?.[0]?.balance,
            linkLedger: userInfo?.user?.linkLedger,
          },
          childMarketAccess: userInfo?.childMarketAccess,
          accountDetails: {
            allowNoOfAccountSuperAdmin:
              userInfo.accountDetails?.allowNoOfAccountSuperAdmin || 0,
            allowNoOfAccountAdmin:
              userInfo.accountDetails?.allowNoOfAccountAdmin || 0,
            allowNoOfAccountMaster:
              userInfo.accountDetails?.allowNoOfAccountMaster || 0,
            allowNoOfAccountBroker:
              userInfo.accountDetails?.allowNoOfAccountBroker || 0,
            allowNoOfAccountDealer:
              userInfo.accountDetails?.allowNoOfAccountDealer || 0,
            allowNoOfAccountCustomer:
              userInfo.accountDetails?.allowNoOfAccountCustomer || 0,
            partnershipWith: userInfo.accountDetails?.partnershipWith,
            additionalInfo: userInfo?.additionalInfoSchema?.[0] || {},
            historicalData: userInfo?.accountDetails?.totalAccounts?.[0],
          },
          market_access: marketAccess,
        };
        const marketArray = store
          .getState()
          ?.globleSlice?.marketListData?.map((el) => el?.scriptName);

        // for (let i = 0; i < marketArray.length; i++) {
        //   obj[marketArray[i]] = { ...initialState[marketArray[i]] };
        // }
        dispatch(setNewAccountInfo(obj));
        if (userId) {
          dispatch(saveDetails(constantValue));
        }
        getParentDetails(userInfo?.parent);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getParentDetails = async (id) => {
    setParentLoading(true);
    try {
      let response = await APICall("get", `${EndPoints.getUser}${id}`);
      if (response?.status === 200) {
        let userInfo = response?.data?.data;
        // console.log("🚀 ~ getParentDetails ~ userInfo:", userInfo);
        const childMarketAccess = userInfo?.childMarketAccess;
        let accountDetails = userInfo?.accountDetails;
        const parentMarketList = userInfo?.marketAccess?.map((el) => {
          return el?.scriptName;
        });
        const basicInfo = userInfo?.user;
        let additionalInfo = userInfo?.additionalInfoSchema?.[0];
        if (!is_edits) {
          setInitialValues({ ...initialValues, additionalInfo });
        }
        dispatch(
          setMarketAccess({
            accountDetails,
            basicInfo,
            parentMarketList,
            additional: additionalInfo,
            childMarketAccess,
          })
        );
        dispatch(
          setNewAccountInfo({
            parent_key: "accountDetails",
            key: "additionalInfo",
            value: { ...additionalInfo },
          })
        );
      }
    } catch (error) {
      console.log("error while getting data of loginUser", error);
    } finally {
      setParentLoading(false);
    }
  };
  // function
  const onFinish = () => {
    console.log("finish");
    handleSubmit();
  };

  const onFinishFailed = () => {
    console.log("not finish");
  };

  return (
    <Container>
      {parentLoading && (
        <SpinnerOverlay>
          <Spin />
        </SpinnerOverlay>
      )}
      {!parentLoading && initialValues && (
        <Form
          name="rent"
          onFinish={onFinish}
          // ref={formRef}
          initialValues={{ ...initialValues }}
          onFinishFailed={onFinishFailed}
          // autoComplete="off"
          form={rentForm}
        >
          <RentBasicInfo is_edits={is_edits} form={rentForm} />
          <RentAccountDetailsInfo is_edits={is_edits} />
          <Row align={"center"}>
            <Col align={"center"} xs={24} sm={12} md={6} lg={4} xl={3}>
              <Button
                type="primary"
                loading={loading}
                style={{ width: "100%" }}
                onClick={() => {
                  console.log("rent", rentForm.getFieldsValue());
                  rentForm.validateFields().then(() => {
                    let payloadObj = payloadObject(
                      rentForm.getFieldsValue(),
                      marketList,
                      null,
                      is_edits ? true : false,
                      true
                    );
                    handleSubmit(payloadObj);
                  });
                }}
              >
                {is_edits ? "Update Account" : "Create Account"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
      {!is_edits && (
        <CreateUserModal
          newUserDetails={newUserDetails}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          type="rent"
        />
      )}
    </Container>
  );
};

export default CreateRentAccount;

const RentBasicInfo = ({ is_edits, form }) => {
  const dispatch = useDispatch();
  const [finalOption, setFinalOption] = useState([]);

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const userDetail = useSelector((state) => state.authSlice?.userDetail);
  const parentDetails = useSelector(
    (state) => state.authSlice?.MarketAccessDetails
  );
  const basicInfo = useSelector((state) => state.userAccountSlice?.basicInfo);
  const isExpiryMessageBeforeDays = Form.useWatch(
    "isExpiryMessageBeforeDays",
    form
  );

  // life cycle
  useEffect(() => {
    if (userDetail?.accountType === "owner") {
      setFinalOption([...finalOption, RentOption[1]]);
    } else {
      let temp = [];
      for (let i = 0; i < RentOption?.length; i++) {
        if (RentOption?.[i].authorityLevel > userDetail?.authorityLevel) {
          if (
            userDetail.accountType === "customer" ||
            userDetail.accountType === "broker"
          ) {
            break;
          } else {
            if (
              parentDetails?.accountDetails?.[
                accountObj[options?.user?.accountType?.[i].value]
              ] === 0
            ) {
              // finalOption.push({
              //   ...options.user.accountType[i],
              //   disabled: true,
              // });
              // console.log("");
            } else {
              // finalOption.push(options?.user?.accountType?.[i]);
              temp.push(RentOption?.[i]);
            }
          }
        }
      }
      setFinalOption(temp);
    }
  }, [userDetail, parentDetails]);

  useEffect(() => {
    let obj = {
      accountType: "masterAdmin",
      authorityLevel: 1,
    };
    if (userDetail?.accountType === "owner" && !is_edits) {
      dispatch(setNewAccountInfo(obj));
    } else if (finalOption.length !== 0 && !is_edits) {
      let showAccount = false;
      for (let i = 0; i < finalOption.length; i++) {
        if (
          parentDetails?.accountDetails?.[accountObj?.[finalOption[i].value]] >
          0
        ) {
          let obj = {
            accountType: finalOption?.[i].value,
            authorityLevel: finalOption?.[i].authorityLevel,
          };
          // console.log(
          //   "check",
          //   parentDetails?.accountDetails[accountObj[finalOption[i]]],
          //   obj
          // );

          dispatch(setNewAccountInfo(obj));
          showAccount = true;
          break;
        }
        // console.log("final", finalOption[i]);
      }
      if (!showAccount) {
        dispatch(setNewAccountInfo({ accountType: null }));
      }
    }
  }, [is_edits, parentDetails, finalOption]);

  // function
  const onChangeAccountType = (name, value, options) => {
    // if (obj[value[0]] === value[0]) {
    //   return;
    // }
    if (!value) {
      return;
    }

    batch(() => {
      dispatch(setNewAccountInfo({ [name]: value ? value[0] : null }));
      dispatch(
        setNewAccountInfo({
          authorityLevel: value ? options?.[0]?.authorityLevel : null,
        })
      );
    });
  };

  const onChangeDeforeDays = (e) => {
    if (!e.target.value) {
      form.setFieldValue("expiryMessageBeforeDays", null);
    }
  };
  return (
    <ThemeCard
      title="Basic Info"
      bordered={false}
      style={{
        width: "100%",
      }}
    >
      <StyleRow justify={"space-between"} gutter={[20, 20]}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDropDown
            name={"accountType"}
            title={"Account Type"}
            placeholder="Select Account"
            options={finalOption}
            width={"100%"}
            required
            $value={accountType}
            $onChange={onChangeAccountType}
            disabled={is_edits}
            // form_props={{
            //   name: "accountType",
            //   rules: [{ required: true }],
            // }}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeInput
            title="Account Name"
            inputProps={{
              name: "accountName",
            }}
            formProps={{
              name: "accountName",
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && value?.length <= 20) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        `${
                          value?.length > 20
                            ? "You can not add more then 20 character"
                            : "Please add account name"
                        } `
                      );
                    }
                  },
                }),
              ],
            }}
          />
        </Col>
        {!is_edits ? (
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeInput
              title="Account Password"
              required
              inputProps={{
                name: "accountPassword",
                // value: basicInfo?.accountPassword,
                // onChange: onChange,
                // disabled: is_edits,
              }}
              formProps={{
                name: "accountPassword",
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      value = value && value?.toString();
                      if (value?.length >= 4 || is_edits) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Password must be at least 4 characters long."
                        );
                      }
                    },
                  }),
                ],
              }}
            />
          </Col>
        ) : (
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeInput
              title="Account Id"
              required
              formProps={{
                name: "userId",
              }}
              inputProps={{
                // onChange: onChange,
                readOnly: "readOnly",
                name: "userId",
                // disabled: true,
              }}
              readOnly
            />
          </Col>
        )}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeInputNumber
            title={"Opening Balance"}
            extraProps={{
              input_type: "number",
              showConversion: true,
            }}
            inputProps={{
              name: "openingBalance",
            }}
            formProps={{
              name: "openingBalance",
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value >= 0 || !value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(`Only allow positive numbers`);
                    }
                  },
                }),
              ],
            }}
          />
        </Col>
      </StyleRow>
      <StyleRow justify={"space-between"} align={"middle"} gutter={[20, 20]}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeDatePicker
            title="Expiry Date"
            name="expiryDate"
            style={{ width: "100%" }}
            formProps={{
              name: "expiryDate",
              rules: [
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const selectedDate = new Date(value);
                    const currentDate = new Date();
                    if (selectedDate > currentDate || !value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Please select expiry date in future")
                      );
                    }
                  },
                }),
              ],
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
          <AccountDetailsRadioView
            title={"Auto Lock"}
            $width="7rem"
            name="autoLock"
            formProps={{
              name: "autoLock",
              initialValue: false,
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={9} style={{ display: "flex" }}>
          <AccountDetailsRadioView
            title={"Expiry message before days"}
            $width="15rem"
            name="isExpiryMessageBeforeDays"
            options={[
              { label: "On", value: true },
              { label: "Off", value: false },
            ]}
            onChange={onChangeDeforeDays}
            formProps={{
              name: "isExpiryMessageBeforeDays",
              // onChange: handleChange,
              initialValue: false,
            }}
          />
          <Form.Item name="expiryMessageBeforeDays" width={"4rem"}>
            <InputNumber
              name="expiryMessageBeforeDays"
              width={"4rem"}
              placeholder="0"
              disabled={!isExpiryMessageBeforeDays}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <ThemeInputNumber
            title={"Rent"}
            extraProps={{
              input_type: "number",
              showConversion: true,
            }}
            inputProps={{
              name: "rent",
            }}
            formProps={{
              name: "rent",
              // rules: [
              //   ({ getFieldValue }) => ({
              //     validator(_, value) {
              //       if (value >= 0 || !value) {
              //         return Promise.resolve();
              //       } else {
              //         return Promise.reject(`Only allow positive numbers`);
              //       }
              //     },
              //   }),
              // ],
            }}
          />
        </Col>
      </StyleRow>
    </ThemeCard>
  );
};

const RentAccountDetailsInfo = ({ is_edits }) => {
  return (
    <ThemeCard
      title="Account details"
      bordered={false}
      style={{
        marginBlock: "2rem",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Details>
        <AccountOpeningView rent={true} is_edits={is_edits} />
        <RentAccountMarketList is_edits={is_edits} />
      </Details>
      <Row style={{ marginBottom: "2rem" }}></Row>
      <AdditionalInfoView rent={true} />
      <Col span={24} align="center" style={{ marginTop: "1rem" }}>
        <ThemeInput
          title={"Remark"}
          inputProps={{
            placeholder: "",
            name: "remark",
          }}
          formProps={{
            name: "remark",
          }}
        />
      </Col>
    </ThemeCard>
  );
};

const RentAccountMarketList = ({ is_edits }) => {
  const dispatch = useDispatch();
  const token = theme.useToken().token;

  //state
  const [showMarket, setShowMarket] = useState([]);
  const [tab, setTab] = useState("");

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );
  const market_access = useSelector(
    (state) => state.userAccountSlice.market_access
  );
  const parentDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails
  );
  const parentMarket = parentDetails?.parentMarketList;
  const childMarketAccess = useSelector(
    (state) => state?.userAccountSlice?.childMarketAccess
  );

  const marketList = useSelector((state) => state.globleSlice.marketListData);

  // life cycle

  useEffect(() => {
    if (is_edits && accountType !== "masterAdmin") {
      const market = parentMarket?.map((el) => {
        return { label: el, value: el, scriptName: el };
      });
      setShowMarket(market);
    } else {
      setShowMarket(marketList);
    }
  }, [is_edits, parentMarket]);

  //functions
  const handleTabChange = (activeKey) => {
    if (tab === activeKey) {
      setTab("");
    } else {
      setTab(activeKey);
      let dummy = [];
      let includes = market_access?.includes(activeKey);
      if (!includes) {
        dummy = [...market_access, activeKey];
        dispatch(setNewAccountInfo({ market_access: dummy }));
      }
    }
  };

  const handleTabCheck = (e, activeKey) => {
    e.stopPropagation();
    const checked = e.target.checked;
    if (!checked && is_edits) {
      const include = childMarketAccess?.includes(activeKey);

      if (!include) {
        return;
      }
    }
    let dummy = [];
    if (market_access?.includes(activeKey)) {
      dummy = market_access.filter((o1) => o1 !== activeKey);
    } else {
      dummy = [...market_access, activeKey];
    }
    if (e.target.checked) {
      setTab(activeKey);
    }
    dispatch(setNewAccountInfo({ market_access: dummy }));
  };

  ///render functions
  const renderTabBar = () => {
    return (
      <MarketTab>
        {showMarket?.map((item, index) => {
          let checked = market_access?.includes(item.value);
          if (item.value !== "") {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(item?.value)}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: checked ? token.colorPrimary : "white",
                  borderRadius: token.borderRadiusLG,
                  padding: "0.7rem",
                  border: `2px solid ${token.colorBorder}`,
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={(e) => handleTabCheck(e, item?.value)}
                />
                <span
                  style={{
                    marginInline: "0.5rem",
                    color: checked ? "white" : "black",
                    fontSize: "1rem",
                    fontWeight: 600,
                  }}
                >
                  {item?.label}
                </span>
              </div>
            );
          }
        })}
      </MarketTab>
    );
  };

  return (
    <MarketAccess>
      <ThemeTextLabel
        fontSize={"1.4rem"}
        title="Market Access"
        color={token.colorTextBase}
      />
      <Tabs items={marketList} renderTabBar={renderTabBar} />
    </MarketAccess>
  );
};
