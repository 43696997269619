import { useEffect, useState } from "react";
import store from "../../../store/store";
import {
  formatNumber,
  formatToINR,
} from "../../../components/FormateToINR/formatToINR";

export const ScriptName = (record) => {
  const opt = record?.marketName?.endsWith("OPT");
  const name = opt
    ? `${record?.symbolName}  ${
        record?.strikePrice ? record?.strikePrice : ""
      } ${record?.series ? record?.series : ""}`
    : record?.symbolName;

  return name;
};

export const TotalPNL = ({ data }) => {
  const [total, setTotal] = useState(0);
  // const webSocketData = useSelector((state) => state.webSocketSlice.webSocket.data);
  useEffect(() => {
    const interval = setInterval(() => {
      let currentTotal = 0;
      const current = data?.reduce((acc, curr) => {
        let price =
          store?.getState()?.positionSocketSlice?.data?.[curr?._id]
            ?.currentPNL || 0;
        acc = acc + Number(price);
        return acc;
      }, 0);

      // data?.forEach((item) => {
      //   let key = CurrentForPosition(item);
      //   let price =
      //     store.getState().webSocketSlice.webSocket.data?.[item?.symbol]?.[
      //       key
      //     ] || 0;

      //   // Check if price is not equal to 0 and item data is valid
      //   if (
      //     price !== 0 &&
      //     item?.totalBrokerage !== undefined &&
      //     item?.totalBuyPrice !== undefined &&
      //     item?.totalSellPrice !== undefined &&
      //     item?.totalQuantity !== undefined
      //   ) {
      //     let currentPNL = parseFloat(
      //       -(item.totalBrokerage || 0) -
      //         (item.totalBuyPrice || 0) +
      //         (item.totalSellPrice || 0) +
      //         (item.totalQuantity || 0) * price
      //     )?.toFixed(2);

      //     // Check if currentPNL is a valid number
      //     if (!isNaN(currentPNL)) {
      //       currentTotal += Number(currentPNL);
      //     }
      //   }
      // });
      // setTotal(currentTotal);
      setTotal(current);
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <span style={{ color: total > 0 ? "green" : "red" }}>
      {total > 0 ? `+${formatToINR(total)}` : `${formatToINR(total)}`}
    </span>
  );
};

export const TotalOutstanding = ({ data, value, type }) => {
  let total = 0;
  let lot = 0;
  const noLot = value?.radioClient === 1 && data?.[0]?.marketName === "NSE EQU";
  data?.forEach((el) => {
    if (el?.positionType === type) {
      total += el?.totalQuantity;
    }
  });

  data?.forEach((el) => {
    if (el?.positionType === type && el?.marketName !== "NSE EQU") {
      lot += el?.lot;
    }
  });

  const totalLot = data?.reduce((acc, el) => {
    if (el?.lot && el?.positionType === type) {
      return acc + el?.lot;
    }
  }, 0);

  // console.log("lots", totalLot);
  return (
    <span style={{ color: total > 0 ? "green" : "red" }}>
      {total > 0 ? `+${formatToINR(total)}` : formatToINR(total)}

      {!noLot && `(${formatNumber(lot)})`}
    </span>
  );
};

export const TotalMTM = ({ data }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const calculateTotalMTM = () => {
      const totalMTM =
        typeof data !== "number" &&
        data?.reduce((acc, curr) => {
          let price =
            store?.getState()?.positionSocketSlice?.data?.[curr?._id]
              ?.currentPNL || 0;
          acc = acc + Number(price);
          return acc;
        }, 0);
      // data?.reduce((sum, item) => {
      //   const currentPrice =
      //     store.getState().webSocketSlice.webSocket.data?.[item.symbol]?.[
      //       CurrentForPosition(item)
      //     ];

      //   // Check if required data is available and valid
      //   if (
      //     currentPrice !== undefined &&
      //     item.totalBuyPrice !== undefined &&
      //     item.totalSellPrice !== undefined &&
      //     item.totalQuantity !== undefined &&
      //     item.totalBrokerage !== undefined &&
      //     !isNaN(currentPrice) &&
      //     !isNaN(item.totalBuyPrice) &&
      //     !isNaN(item.totalSellPrice) &&
      //     !isNaN(item.totalQuantity) &&
      //     !isNaN(item.totalBrokerage)
      //   ) {
      //     const currentPNL =
      //       -(item.totalBuyPrice || 0) +
      //       (item.totalSellPrice || 0) +
      //       (item.totalQuantity || 0) * currentPrice -
      //       (item.totalBrokerage || 0);

      //     return sum + currentPNL;
      //   }

      //   // If any data is missing or invalid, skip the item
      //   return sum;
      // }, 0);

      setValue(totalMTM);
    };

    const interval = setInterval(calculateTotalMTM, 1000);
    return () => clearInterval(interval);
  }, [data]);

  // return value;
  return formatToINR(value);
};
