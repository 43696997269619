import { formatToINR } from "../FormateToINR/formatToINR";

export const showValue = (data) => {
  if (data !== undefined && data !== null) {
    const intValue = parseInt(data);
    return (
      <span style={{ color: intValue >= 0 ? "green" : "red" }}>
        {intValue > 0
          ? `+${formatToINR(intValue)}`
          : `${formatToINR(intValue)}`}
      </span>
    );
  } else {
    return 0;
  }
};

export const CurrentPrice = (record) => {
  let key = record?.totalQuantity > 0 ? "Bid" : "Ask";
  return key;
};
