import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import { Modal, notification } from "antd";
import {
  setSeletedMarket,
  setWatchListArray,
} from "../../../store/slice/marketWatchSlice";
import ThemeInput from "../../../components/ThemeInput";
import EndPoints from "../../../APICall/EndPoints";

const WatchListModal = ({ watchListModal, setWatchListModal }) => {
  const [loading, setLoading] = useState(false);
  const watchListData = useSelector(
    (state) => state?.marketWatchSlice?.watchListArray
  );

  const [name, setName] = useState(`Watchlist-${watchListData.length + 1}`);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    let obj = {
      name: name,
      index:
        watchListData?.length > 0
          ? watchListData[watchListData.length - 1]?.index
          : 1,
    };
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.addWatchList, obj);
      if (response.status === 201) {
        console.log("response", response?.data);
        setWatchListModal(false);
        notification.success({ message: "WatchList created successfully" });
        dispatch(
          setWatchListArray({ type: "add", data: response?.data?.data })
        );
        dispatch(setSeletedMarket(watchListData?.length));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={watchListModal}
      onCancel={() => setWatchListModal(false)}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <ThemeInput
        title="Watch-List Name"
        inputProps={{
          name: "watchlistName",
          value: name,
          onChange: (e) => setName(e.target.value),
          style: { marginTop: "1rem" },
        }}
      />
    </Modal>
  );
};

export default WatchListModal;
