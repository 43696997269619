import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { SEGMENTS } from "../../../Helper/constant";
import { notification } from "antd";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { MemoizedSocketScript } from "../../../components/SocketLiveData";
import { setMarketWatchData } from "../../../store/slice/marketWatchSlice";

const AllScriptLogic = forwardRef(({ value, setLoading, flage }, ref) => {
  const dispatch = useDispatch();
  // state
  const [soketScript, setSoketScript] = useState(null);
  let marketName = SEGMENTS[value?.marketName];

  //function
  const getAllScript = async () => {
    let obj = {
      "NSE FUT": "nsefut",
      MCX: "mcxfut",
      // FOREX: "forex",
    };

    if (marketName == undefined) {
      return notification.info({ message: "Please Select Market" });
    }
    try {
      setLoading(true);
      const response = await APICall(
        "get",
        flage
          ? `${EndPoints.addNewExpiryScript}${marketName}&devicetype=WEB`
          : `${EndPoints.addAllScript}${obj[value.marketName]}`
      );
      if (response.status === 200) {
        // console.log("response,,", response.data.data);
        const Script = response.data?.data?.map((el) => el?.symbol);
        dispatch(
          setMarketWatchData({
            previous: true,
            type: "multiple_add",
            nested_key: "data",
            key: value?.marketName,
            payload: response.data.data,
          })
        );
        setSoketScript(Script);
        notification.success({ message: response.data?.message });
        // queryClient.resetQueries(["getWatchList"]);
      } else {
        notification.error({ message: response.data?.message });
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      notification.error({ message: error?.message });
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getAllScript: () => getAllScript(),
    }),
    [getAllScript]
  );

  return soketScript ? <MemoizedSocketScript script={soketScript} /> : null;
});

export default AllScriptLogic;
