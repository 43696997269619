import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import userAccountReducer from "./slice/userAccountSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { errorHandler } from "./errorHandler";
import marketWatchReducer from "./slice/marketWatchSlice";
import webSocketReducer from "./slice/webSocketSlice";
import globleReducer from "./slice/globleSlice";
import apiDataReducer from "./slice/apiDataSlice";
import dataFilterReducer from "./slice/dataFilterSlice";
import expirySettingReducer from "./slice/expirySettingSlice";
import brokaerForUsersFormReducer from "./slice/brokaerForUsersFormSlice";
import scriptSettingReducer from "./slice/scriptSettingSlice";
import positionSocketReducer from "./slice/positionSocketSlice";
import scriptSettingsRequestReducer from "./slice/scriptSettingsRequestSlice";

const persistConfig = {
  key: "root",
  version: 1.1,
  storage,
  whitelist: [
    "authSlice",
    "marketWatchSlice",
    "userAccountSlice",
    "webSocketSlice",
    "globleSlice",
    "apiDataSlice",
    "dataFilterSlice",
    "expirySettingSlice",
    "scriptSettingSlice",
    "positionSocketSlice",
    "brokaerForUsersFormSlice",
  ],
};

const rootReducer = combineReducers({
  authSlice: authReducer,
  userAccountSlice: userAccountReducer,
  marketWatchSlice: marketWatchReducer,
  webSocketSlice: webSocketReducer,
  globleSlice: globleReducer,
  apiDataSlice: apiDataReducer,
  dataFilterSlice: dataFilterReducer,
  expirySettingSlice: expirySettingReducer,
  scriptSettingSlice: scriptSettingReducer,
  positionSocketSlice: positionSocketReducer,
  brokaerForUsersFormSlice: brokaerForUsersFormReducer,
  scriptSettingsRequestSlice: scriptSettingsRequestReducer,
  // [magentoEmptyApi.reducerPath]: magentoEmptyApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_MODE === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Redux persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(errorHandler),
});

export const persistor = persistStore(store);

export default store;

export const clearLocalStorage = async () => {
  await persistor.purge();
};
