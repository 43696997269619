import { Document, Page, Text, View } from "@react-pdf/renderer";
import {
  formatForValanBill,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";

import { formatDate, styles, valanBillDate } from "./ReportCart";

const columnEQU = [
  {
    title: "Sr No.",
    dataIndex: "number",
    width: "2rem",
    render: (_text, record, index) => {
      let number = index + 1;
      if (record?.customerId) {
        return <Text style={styles.tableCell}>{number}</Text>;
      }
    },
  },
  {
    title: "Client",
    dataIndex: "client",
    width: "5rem",
    render: (_text, record) => {
      if (record?.customerId) {
        return (
          <Text
            style={styles.tableCell}
          >{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</Text>
        );
      }
    },
  },
  {
    title: "Date",
    dataIndex: "tradeTime",
    width: "8rem",
    render: (text, record) => {
      if (record?.tradeTime) {
        let time = valanBillDate(record?.tradeTime, true);
        return <Text style={styles.tableCell}>{time}</Text>;
      }
    },
  },
  {
    title: "Type",
    dataIndex: "title",
    width: "3rem",
    render: (text, record) => {
      return (
        <Text
          style={[
            styles.tableCell,
            {
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            },
          ]}
        >
          {record?.title}
        </Text>
      );
    },
  },
  // {
  //   title: "Lot",
  //   dataIndex: "lot",
  //   width: "3rem",
  //   render: (text, record) => {
  //     const lot = formatNumber(record?.lot);
  //     return (
  //       <Text style={styles.tableCell}>
  //         {record?.title !== "live" ? lot : ""}
  //       </Text>
  //     );
  //   },
  // },
  {
    title: "Buy Qty",
    dataIndex: "buyQty",
    width: "3rem",
    render: (text, record) => {
      let buy = "-";
      if (record.tradePattern === "buy") {
        buy = record.quantity;
      }
      return (
        <Text
          style={[
            styles.tableCell,
            { color: buy !== "-" ? "green" : "black", fontWeight: "600" },
          ]}
        >
          {formatToINR(buy)}
        </Text>
      );
    },
  },
  {
    title: "Sell Qty",
    dataIndex: "SellQty",
    width: "3rem",
    render: (text, record) => {
      let sell = "-";
      if (record.tradePattern === "sell") {
        sell = record.quantity;
      }
      return (
        <Text
          style={[
            styles.tableCell,
            { color: sell !== "-" ? "red" : "black", fontWeight: "500" },
          ]}
        >
          {formatToINR(sell)}
        </Text>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "tradePrice",
    width: "4rem",
    render: (text, record) => {
      if (record?.tradePrice) {
        const formattedPrice =
          record?.marketName === "FOREX"
            ? record?.tradePrice
            : record?.tradePrice;
        return (
          <Text style={styles.rightAlignedCell}>
            {formatForValanBill(formattedPrice)}
          </Text>
        );
      }
    },
  },
  {
    title: "Net Rate",
    dataIndex: "netRate",
    width: "4rem",
    render: (text, record) => {
      if (record?.netRate) {
        const netRate = record?.netRate;
        return (
          <Text style={styles.rightAlignedCell}>
            {formatForValanBill(netRate)}
          </Text>
        );
      }
    },
  },
  {
    title: "Net Amount",
    dataIndex: "netAmount",
    width: "4rem",
    render: (text, record) => {
      if (record?.netAmount) {
        const netAmount = record?.netAmount;
        return (
          <Text style={styles.rightAlignedCell}>
            {formatForValanBill(netAmount)}
          </Text>
        );
      }
    },
  },
];

const PDFDocument = ({ valanBill, grossRef, withoutBrokerage, column }) => (
  <Document>
    <Page size="A2" style={styles.page}>
      <View>
        <View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>
                Live Bill:{" "}
                {grossRef?.user?.accountName
                  ? `${grossRef?.user?.accountName}(${grossRef?.user?.userId})`
                  : ""}
              </Text>
            </View>
            <View style={styles.column}>
              <Text>
                Valan:{" "}
                {grossRef?.valan?.valanName ? grossRef?.valan?.valanName : ""}
              </Text>
            </View>
            <View style={styles.column}>
              <Text>
                From:{" "}
                {grossRef?.valan?.startDate
                  ? formatDate(grossRef?.valan?.startDate)
                  : ""}{" "}
                To{" "}
                {grossRef?.valan?.endDate
                  ? formatDate(grossRef?.valan?.endDate)
                  : ""}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.themeBuyColumn}>
              <Text>
                Buy Turnover: {formatToINR(grossRef?.totalBuyTurnover) || 0}
              </Text>
            </View>
            <View style={styles.themeSellColumn}>
              <Text
                style={{
                  textAlign: "right",
                  width: "100%",
                }}
              >
                Sell Turnover: {formatToINR(grossRef?.totalSellTurnover) || 0}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.column}>
              <Text>
                Without Brokerage Bill: {formatToINR(grossRef?.totalGroce) || 0}
              </Text>
            </View>
            {!withoutBrokerage && (
              <View style={styles.column}>
                <Text>
                  Total Brokerage: {formatToINR(grossRef?.totalBrokerage) || 0}
                </Text>
              </View>
            )}
            {!withoutBrokerage &&
              grossRef?.user?.accountType !== "customer" && (
                <View style={styles.column}>
                  <Text>
                    Self Brokerage: {formatToINR(grossRef?.selfBrokerage) || 0}
                  </Text>
                </View>
              )}
            {grossRef?.user?.accountType !== "customer" && (
              <View style={styles.themeColumn}>
                <Text>Self Amount: </Text>
                <Text
                  style={{
                    color: grossRef?.selfAmount > 0 ? "green" : "red",
                  }}
                >
                  {formatToINR(grossRef?.selfAmount) || 0}
                </Text>
              </View>
            )}
            <View style={styles.themeColumn}>
              <Text>Final Bill With Brokerage: </Text>
              <Text
                style={{
                  color: grossRef?.totalNetAmount > 0 ? "green" : "red",
                }}
              >
                {formatToINR(grossRef?.totalNetAmount) || 0}
              </Text>
            </View>
          </View>

          {Object.keys(valanBill).length === 0 && (
            <View style={{ alignItems: "center", marginTop: 50 }}>
              <Text style={{ fontSize: 32 }}>No data available</Text>
            </View>
          )}
          <View style={{ marginTop: "20px" }}></View>
          {Object.keys(valanBill).length > 0 &&
            Object.keys(valanBill).map((script, index) => (
              <View style={styles.themeCard} key={index}>
                <View style={styles.titleRow}>
                  <Text style={styles.scriptTitle}>{script}</Text>
                  <View style={styles.segmentBill}>
                    <Text>
                      Segment Bill:
                      <Text
                        style={{
                          color:
                            valanBill[script].totalMarketNetAmount >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {formatToINR(valanBill[script].totalMarketNetAmount) ||
                          0}
                      </Text>
                    </Text>
                  </View>
                </View>
                {valanBill[script].trades.map((item, index) => (
                  <View key={index}>
                    <Text style={styles.scriptHeader}>
                      {`${item._id.symbolName} ${
                        valanBill[script].trades[0].trades[0].expiryDate
                          ? valanBillDate(
                              valanBill[script].trades[0].trades[0].expiryDate,
                              false
                            )
                          : null
                      }`}
                    </Text>
                    <View style={styles.table}>
                      {/* Render table headers */}
                      <View style={styles.tableRow}>
                        {script === "NSE EQU" &&
                          columnEQU?.map((col, index) => (
                            <Text style={styles.tableCell} key={index}>
                              {col.title}
                            </Text>
                          ))}
                        {script !== "NSE EQU" &&
                          column?.map((col, index) => (
                            <Text style={styles.tableCell} key={index}>
                              {col.title}
                            </Text>
                          ))}
                      </View>
                      {/* Render table rows */}
                      {item?.trades?.map((trade, index) => (
                        <View style={styles.tableRow} key={index}>
                          {script === "NSE EQU" &&
                            columnEQU.map((col, indexs) => (
                              <Text style={styles.tableCell} key={indexs}>
                                {col.render
                                  ? col.render(
                                      trade[col.dataIndex],
                                      trade,
                                      index
                                    )
                                  : trade[col.dataIndex]}
                              </Text>
                            ))}
                          {script !== "NSE EQU" &&
                            column.map((col, indexs) => (
                              <Text style={styles.tableCell} key={indexs}>
                                {col.render
                                  ? col.render(
                                      trade[col.dataIndex],
                                      trade,
                                      index
                                    )
                                  : trade[col.dataIndex]}
                              </Text>
                            ))}
                        </View>
                      ))}
                    </View>
                    {/* Render table footer */}
                    <View style={styles.footerContainer}>
                      <View style={styles.tableRow}>
                        <Text style={[styles.tableCell]}>
                          ScriptWise Total :
                        </Text>
                        <Text style={styles.tableCell}></Text>
                        <Text style={styles.tableCell}></Text>
                        <Text style={styles.tableCell}></Text>
                        {script !== "NSE EQU" && (
                          <Text style={styles.tableCell}></Text>
                        )}
                        <Text style={styles.tableCell}>
                          {formatToINR(item?.totalBuyQuantity)}
                        </Text>
                        <Text style={styles.tableCell}>
                          {formatToINR(item?.totalSellQuantity)}
                        </Text>
                        <Text style={styles.tableCell}>
                          {formatForValanBill(item.rate)}
                        </Text>
                        {!withoutBrokerage ? (
                          <Text style={styles.tableCell}>
                            {item?.netRate
                              ? `Br. : ${formatForValanBill(item.netRate)}`
                              : ""}
                          </Text>
                        ) : (
                          <Text style={styles.tableCell}></Text>
                        )}
                        <Text style={styles.tableCell}>
                          {item?.totalNetAmount
                            ? formatForValanBill(item?.totalNetAmount)
                            : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
                <View style={styles.scriptBottom}>
                  <Text style={styles.themeColumn}>
                    Total Brokerage :{" "}
                    {formatToINR(valanBill?.[script]?.totalBrokerage) || 0}
                  </Text>
                  {grossRef?.user?.accountType !== "customer" && (
                    <Text style={styles.themeColumn}>
                      Self Brokerage::
                      {formatToINR(valanBill?.[script]?.selfBrokerage) || 0}
                    </Text>
                  )}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default PDFDocument;
