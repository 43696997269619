import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tooltip,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { StyledButton } from "../style";
import { WhatsappShareButton } from "react-share";
import { WhatsAppOutlined } from "@ant-design/icons";
import APICall from "../../../../APICall";
import EndPoints from "../../../../APICall/EndPoints";

const ResetButton = ({ item }) => {
  // state
  const [resetPasswardModal, setResetPasswardModal] = useState({
    status: false,
    userId: null,
    id: null,
    name: null,
  });

  // function
  const handlepassward = () => {
    setResetPasswardModal({ status: false, id: null });
  };

  const passwardOk = () => {
    setResetPasswardModal({ status: false, id: null });
  };

  const resetPassward = (item) => {
    setResetPasswardModal({
      status: true,
      id: item?._id,
      userId: item?.userId,
      name: item?.accountName,
    });
  };
  return (
    <>
      <Tooltip placement="bottomLeft" title="Reset Password">
        <StyledButton
          className="regularUserTableBtn"
          onClick={() => resetPassward(item)}
        >
          RP
        </StyledButton>
      </Tooltip>
      <Modal
        title={`Reset Passward of ${resetPasswardModal?.name} (${resetPasswardModal?.userId})`}
        open={resetPasswardModal.status}
        onOk={passwardOk}
        onCancel={handlepassward}
        destroyOnClose={true}
        footer={null}
      >
        <ResetUserPassward
          id={resetPasswardModal?.id}
          userId={resetPasswardModal?.userId}
          setResetPasswardModal={setResetPasswardModal}
        />
      </Modal>
    </>
  );
};

export default ResetButton;

const ResetUserPassward = ({ id, userId }) => {
  const [form] = Form.useForm();

  // state
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [copy, setCopy] = useState(false);
  // const dispatch = useDispatch();

  const shareUrl = `userId: ${userId}, Newpassward : ${userData?.newPassword} `;
  const title = "Passward is successfully reset for,";

  // life cycle
  useEffect(() => {
    if (copy) {
      setCopy(false);
    }
  }, []);

  // api call
  const onFinish = async (values) => {
    let obj = { ...values, userId: id };
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.resetPassward, obj);

      if (response?.status === 200 && response?.data) {
        notification.success({ message: response?.data?.message });
        setUserData(obj);
        // console.log("obj", obj);
      } else {
        // console.log("response.....", response);
        navigator.clipboard.writeText(response?.data?.message);
        notification.error({ message: response?.data?.message });
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      notification.error({ message: error?.message });
      setLoading(false);
    }
  };

  // function
  const handleCopy = () => {
    const linkText = "https://www.9bison.in";
    navigator.clipboard.writeText(
      `${title}${shareUrl}, for application download ${linkText}`
    );
    setCopy(true);
  };

  return (
    <Row justify="center" style={{ marginTop: "2rem" }}>
      <Col>
        <Form form={form} onFinish={onFinish} labelCol={{ span: 10 }}>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please input your new password!" },
              {
                min: 4,
                message: "Password must be at least 4 characters long!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ marginTop: "1rem" }}
            >
              Reset Passward
            </Button>
          </Form.Item>
          {userData !== null && (
            <Row justify={"center"} style={{ gap: "1rem", marginLeft: "1rem" }}>
              <Button onClick={handleCopy}>{copy ? "Copied!" : "Copy"}</Button>
              <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsAppOutlined style={{ fontSize: "2rem" }} />
              </WhatsappShareButton>
            </Row>
            // <ShareAltOutlined
            //   style={{ fontSize: "2rem", marginLeft: "15rem" }}
            // />
          )}
        </Form>
      </Col>
    </Row>
  );
};
