// LoginLayout.js

import { Form } from "antd";
import styled from "styled-components";

const LoginStyle = {
  LeftView: styled.div`
    height: 100vh;
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  RightView: styled.div`
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    border-radius: 1rem;
    /* height: 70vh; */
    /* width: 40vw; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Form: styled(Form)`
    /* border: 1px solid red; */
    /* height: 250px; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    & input[type="password"] {
      background-color: transparent;
    }
  `,

  Image: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
};

export default LoginStyle;
