import React from "react";
import ThemeCard from "../../components/ThemeCard";
import { Typography } from "antd";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const PrivacyPolicy = () => {
  const { Title, Paragraph } = Typography;
  return (
    <ThemeCard title={<Title level={3}>Privacy Policy</Title>}>
      <Container>
        {/* <Title level={2}>Privacy Policy</Title> */}

        <Section>
          <Title level={3}>1. Introduction</Title>
          <Paragraph>
            Welcome to 9bison, your go-to paper trading platform for financial
            education and simulated trading experiences. This Privacy Policy is
            designed to help you understand how we collect, use, and safeguard
            your personal information. By using 9bison, you agree to the terms
            outlined in this policy.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>2. Information We Collect</Title>
          <Title level={4}>2.1 Personal Information</Title>
          <Paragraph>
            We may collect personal information such as your name, email
            address, and user credentials during the account registration
            process.
          </Paragraph>
          <Title level={4}>2.2 Usage Information</Title>
          <Paragraph>
            We gather information about your interactions with the app,
            including the features you use, the content you view, and the
            frequency and duration of your activities.
          </Paragraph>
          <Title level={4}>2.3 Device Information</Title>
          <Paragraph>
            We may collect information about the device you use to access
            9bison, such as device type, operating system, and unique device
            identifiers.
          </Paragraph>
          <Title level={4}>2.4 Log Data</Title>
          <Paragraph>
            Our servers automatically record information, including your IP
            address, the pages you visit within the app, and the time and date
            of your interactions.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>3. How We Use Your Information</Title>
          <Paragraph>
            Personal information is used for account management, authentication,
            and communication purposes.
          </Paragraph>
          <Paragraph>
            Usage information helps us improve the app's functionality, tailor
            content to user preferences, and analyze user behavior for
            optimization.
          </Paragraph>
          <Paragraph>
            Device information and log data assist us in troubleshooting
            technical issues, maintaining security, and enhancing overall
            performance.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>4. Information Sharing</Title>
          <Paragraph>
            We do not sell, trade, or rent your personal information to third
            parties.
          </Paragraph>
          <Paragraph>
            Non-personal, aggregated data may be shared for analytics and
            improvement purposes.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>5. Security</Title>
          <Paragraph>
            We implement industry-standard security measures to protect your
            information from unauthorized access, disclosure, alteration, and
            destruction.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>6. Third-Party Services</Title>
          <Paragraph>
            9bison may integrate with third-party services for analytics, or
            other functionalities. Please review the privacy policies of these
            third parties for their data practices.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>7. Changes to the Privacy Policy</Title>
          <Paragraph>
            This Privacy Policy may be updated to reflect changes in our data
            practices. Users will be notified of any significant updates.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>8. Your Choices</Title>
          <Paragraph>
            Users can update or delete their account information within the app.
          </Paragraph>
          <Paragraph>
            You may opt-out of promotional communications by following the
            instructions provided.
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>9. Contact Us</Title>
          <Paragraph>
            If you have questions or concerns about this Privacy Policy, please
            contact us at xtechpot@gmail.com
          </Paragraph>
        </Section>

        <Section>
          <Title level={3}>10. Approval for Testing and Approval</Title>
          <Paragraph>
            By using 9bison, you acknowledge and agree to the terms outlined in
            this Privacy Policy. This policy is effective as of the last updated
            date mentioned above.
          </Paragraph>
        </Section>
      </Container>
    </ThemeCard>
  );
};

export default PrivacyPolicy;
