import React, { useCallback, useEffect, useRef, useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Col, Row, Skeleton, Spin, theme } from "antd";
import { MemoizedSocketScript } from "../../components/SocketLiveData";
import { useParams, useSearchParams } from "react-router-dom";
import ThemeCard from "../../components/ThemeCard";
import { Text, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import {
  formatForValanBill,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { Container, SpinnerOverlay } from "../MarketSetting/MarketSetting";
import { StyledTheme, FooterRow, StyledButton } from "./style";
import { sequence } from "../../Helper/constant";
import PDFDocument from "./ValanBillPDF";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  buySellRow: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
    backgroundColor: "lightgreen",
  },
  column: {
    flexDirection: "column",
    fontWeight: "bold",
  },
  themeColumn: {
    flexDirection: "row",
    alignItems: "center",
  },
  themeBuyColumn: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "lightgreen",
    width: "50%",
    padding: "3px",
  },
  themeSellColumn: {
    flexDirection: "row",
    backgroundColor: "lightpink",
    textAlign: "right",
    width: "50%",
    padding: "3px",
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  themeCard: {
    marginBottom: 20,
  },
  scriptTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  segmentBill: {
    fontSize: 12,
    textAlign: "right",
    display: "flex",
    // marginRight: "1rem",
  },
  segmentBillAmount: {
    color: "green",
  },
  scriptHeader: {
    backgroundColor: "#0072bc",
    color: "white",
    fontSize: 12,
    padding: 5,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    textAlign: "center",
  },
  table: {
    marginBottom: 0,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderLeftWidth: 1, // Add this line
    borderLeftColor: "#000", // Add this line
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  tableCell: {
    flex: 1,
    paddingVertical: 4,
    paddingHorizontal: 5,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderRightStyle: "solid",
    fontSize: "12px",
    textAlign: "center",
  },
  button: {
    backgroundColor: `${(props) => props.theme.token.colorPrimaryBgHover}`,
  },
  rightAlignedCell: {
    flex: 1,
    padding: 5,
    // textAlign: "right",
    textAlign: "center",
  },
  footerContainer: {
    borderTopWidth: 0,
    // borderTopColor: "#000",
    borderTopStyle: "solid",
    textAlign: "center",
    marginBottom: "10px",
    backgroundColor: "#edecfa",
  },
  footerRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderLeftWidth: 1, // Add this line
    borderLeftColor: "#000", // Add this line
    // textAlign: "center",
  },
  emptyCellStyle: {
    flex: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Helvetica",
    color: "transparent",
  },
  scriptBottom: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "10px",
  },
});

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getUTCDate(); // Using getUTCDate() to extract day in UTC timezone
  const month = date.getUTCMonth() + 1; // Using getUTCMonth() to extract month in UTC timezone, adding 1 because January is 0
  const year = date.getUTCFullYear(); // Using getUTCFullYear() to extract year in UTC timezone

  // Format the date as "DD-MM-YYYY"
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  return formattedDate;
}

const ReportCart = () => {
  const token = theme.useToken().token;

  // state
  const [valanBill, setValanBill] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const grossRef = useRef({});
  const { combinedParams } = useParams();

  const parseParams = (params) => {
    const [idPart, valanIdPart] = params.split("&valanId=");
    const userId = idPart;
    const valanId = valanIdPart;
    return { userId };
  };
  const { userId, valanId } = parseParams(combinedParams);

  // redux
  const summaryData = useSelector(
    (state) => state.globleSlice?.summaryDataFilter
  );
  const withoutBrokerage = summaryData?.withoutBrokerage;

  // console.log("🚀 ~ ReportCart ~ withoutBrokerage:", summaryData);
  // console.log("🚀 ~ ReportCart ~ valanBill:", valanBill);

  // life cycle
  useEffect(() => {
    userValanBill();
  }, []);
  // useEffect(() => {
  //   if (withoutBrokerage) {
  //     // setSearchParams({ withoutBrokerage: "true" });
  //   } else {
  //     setSearchParams({});
  //   }
  // }, [withoutBrokerage]);

  // api call
  const userValanBill = async () => {
    let URL = `${EndPoints.userValanBill}${userId}`;
    if (withoutBrokerage) {
      URL = `${EndPoints.userValanBill}${userId}`;
    }
    try {
      setLoading(true);
      let response = await APICall("post", URL, summaryData);
      if (response.status === 200) {
        const valanList = response?.data?.data;
        grossRef.current = valanList;
        const sortedValan = {};
        sequence.forEach((key) => {
          if (valanList.customerTrades?.[key]) {
            sortedValan[key] = valanList.customerTrades[key];
          }
        });
        setValanBill(sortedValan);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  // column
  const reportColumn = [
    {
      title: "Sr No.",
      dataIndex: "number",
      align: "center",
      width: "2rem",
      render: (_text, record, index) => {
        if (record?.customerId) {
          return <span>{index + 1}</span>;
        }
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      align: "center",
      width: "5rem",
      render: (_text, record) => {
        if (record?.customerId) {
          return (
            <span>{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</span>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "tradeTime",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        if (record?.tradeTime) {
          let time = valanBillDate(record?.tradeTime, true);
          return <span>{time}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "title",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        return (
          <span
            style={{
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            }}
          >
            {record?.title}
          </span>
        );
      },
    },
    {
      title: "Lot",
      dataIndex: "lot",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        return <div>{record?.title !== "live" ? lot : ""}</div>;
      },
    },
    {
      title: "Buy Qty",
      dataIndex: "buyQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let buy = "-";
        if (record?.tradePattern === "buy") {
          buy = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <>
            <div
              style={{
                color: buy !== "-" ? "green" : "",
                fontWeight: "600",
              }}
            >
              {buy}
            </div>
          </>
        );
      },
    },
    {
      title: "Sell Qty",
      dataIndex: "SellQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let sell = "-";
        if (record?.tradePattern === "sell") {
          sell = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <span style={{ color: sell !== "-" ? "red" : "", fontWeight: "500" }}>
            {sell}
          </span>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "tradePrice",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.tradePrice || record?.tradePrice == 0) {
          return formatForValanBill(record?.tradePrice);
        } else if (record?.ordertype === "live") {
          return (
            <>
              <MemoizedSocketScript script={[record?.symbol]} />
            </>
          );
        }
      },
    },
    !withoutBrokerage
      ? {
          title: "Net Rate",
          dataIndex: "netRate",
          align: "right",
          width: "4rem",
          render: (_text, record) => {
            if (record?.netRate || record?.netRate == 0) {
              const netRate = formatForValanBill(record?.netRate);
              return netRate;
            }
          },
        }
      : { width: "0rem" },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.netAmount || record?.netAmount == 0) {
          const netAmount = formatForValanBill(record?.netAmount);
          return netAmount;
        }
      },
    },
  ];

  const reportColNseEqu = [
    {
      title: "Sr No.",
      dataIndex: "number",
      align: "center",
      width: "2rem",
      render: (_text, record, index) => {
        if (record?.customerId) {
          return <span>{index + 1}</span>;
        }
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      align: "center",
      width: "5rem",
      render: (_text, record) => {
        if (record?.customerId) {
          return (
            <span>{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</span>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "tradeTime",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        if (record?.tradeTime) {
          let time = valanBillDate(record?.tradeTime, true);
          return <span>{time}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "title",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        return (
          <span
            style={{
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            }}
          >
            {record?.title}
          </span>
        );
      },
    },
    // {
    //   title: "Lot",
    //   width: "0px",
    //   align: "center",
    // },

    {
      title: "Buy Qty",
      dataIndex: "buyQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let buy = "-";
        if (record?.tradePattern === "buy") {
          buy = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <>
            <div
              style={{
                color: buy !== "-" ? "green" : "",
                fontWeight: "600",
              }}
            >
              {buy}
            </div>
          </>
        );
      },
    },
    {
      title: "Sell Qty",
      dataIndex: "SellQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let sell = "-";
        if (record?.tradePattern === "sell") {
          sell = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <span style={{ color: sell !== "-" ? "red" : "", fontWeight: "500" }}>
            {sell}
          </span>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "tradePrice",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.tradePrice || record?.tradePrice == 0) {
          return formatForValanBill(record?.tradePrice);
        } else if (record?.ordertype === "live") {
          return (
            <>
              <MemoizedSocketScript script={[record?.symbol]} />
            </>
          );
        }
      },
    },
    !withoutBrokerage
      ? {
          title: "Net Rate",
          dataIndex: "netRate",
          align: "right",
          width: "4rem",
          render: (_text, record) => {
            if (record?.netRate || record?.netRate == 0) {
              const netRate = formatForValanBill(record?.netRate);
              return netRate;
            }
          },
        }
      : { width: "0rem" },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.netAmount || record?.netAmount == 0) {
          const netAmount = formatForValanBill(record?.netAmount);
          return netAmount;
        }
      },
    },
  ];

  // function
  const TotalRow = ({ data, script, scriptequ }) => (
    <FooterRow>
      <td colSpan="2">ScriptWise Total :</td>
      <td></td>
      <td></td>
      {!scriptequ && <td></td>}
      <td style={{ textAlign: "center" }}>
        {formatToINR(data?.totalBuyQuantity || 0)}
      </td>
      <td style={{ textAlign: "center" }}>
        {formatToINR(data?.totalSellQuantity || 0)}
      </td>
      <td style={{ textAlign: "end" }}>{formatForValanBill(data?.rate)}</td>
      {!withoutBrokerage ? (
        <td style={{ textAlign: "end" }}>
          {`Br. : ${formatForValanBill(data?.netRate)}`}
        </td>
      ) : (
        <td style={{ width: "0rem" }}></td>
      )}
      <td style={{ textAlign: "end" }}>
        {formatForValanBill(data?.totalNetAmount)}
      </td>
    </FooterRow>
  );

  return (
    <Container isloading={loading.toString()}>
      {loading && (
        <SpinnerOverlay>
          <Spin size="large" />
        </SpinnerOverlay>
      )}

      <Row
        align={"bottom"}
        justify={"space-between"}
        style={{ fontWeight: "500", marginBottom: "1rem" }}
      >
        <StyledTheme.ThemeColumn>
          {`Live Bill: ${
            grossRef.current?.user?.accountName
              ? grossRef.current?.user?.accountName
              : ""
          }(${
            grossRef.current?.user?.userId ? grossRef.current?.user?.userId : ""
          }) ` || 0}
        </StyledTheme.ThemeColumn>
        <StyledTheme.ThemeColumn>
          {`Valan: ${
            grossRef.current?.valan?.valanName
              ? grossRef.current?.valan?.valanName
              : ""
          } ` || 0}
        </StyledTheme.ThemeColumn>
        <StyledTheme.ThemeColumn>
          {`From: ${
            grossRef.current?.valan?.startDate
              ? formatDate(grossRef.current?.valan?.startDate)
              : ""
          } To ${
            grossRef.current?.valan?.endDate
              ? formatDate(grossRef.current?.valan?.endDate)
              : ""
          } ` || 0}
        </StyledTheme.ThemeColumn>
        {grossRef.current?.user?.accountName && (
          <StyledTheme.ThemeColumn>
            <PDFDownloadLink
              document={
                <PDFDocument
                  valanBill={valanBill}
                  grossRef={grossRef.current}
                  withoutBrokerage={withoutBrokerage}
                  column={column}
                />
              }
              fileName={`${grossRef.current?.user?.accountName}(${grossRef.current?.user?.userId})valanBill.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <StyledButton disabled={loading}>
                  {loading ? "Loading document..." : "Download PDF"}
                </StyledButton>
              )}
            </PDFDownloadLink>
          </StyledTheme.ThemeColumn>
        )}
      </Row>

      <Row justify={"space-between"} style={{ fontWeight: "500" }}>
        <StyledTheme.ThemeBuyColumn>
          {`Buy Turnover: ${
            formatToINR(grossRef.current?.totalBuyTurnover || 0) || 0
          }`}
        </StyledTheme.ThemeBuyColumn>
        <StyledTheme.ThemeSellColumn>
          {`Sell Turnover: ${
            formatToINR(grossRef.current?.totalSellTurnover || 0) || 0
          }`}
        </StyledTheme.ThemeSellColumn>
      </Row>

      <Row
        justify={"space-between"}
        style={{ fontWeight: "500", marginBottom: "1rem", marginTop: "0.5rem" }}
      >
        <StyledTheme.ThemeColumn>{`Without Brokerage Bill: ${formatToINR(
          grossRef.current?.totalGroce || 0
        )}`}</StyledTheme.ThemeColumn>
        {!withoutBrokerage && (
          <StyledTheme.ThemeColumn>{`Total Brokerage: ${formatToINR(
            grossRef.current?.totalBrokerage || 0
          )}`}</StyledTheme.ThemeColumn>
        )}
        {!withoutBrokerage &&
          grossRef.current?.user?.accountType !== "customer" && (
            <StyledTheme.ThemeColumn>
              {`Self Brokerage: ${formatToINR(
                grossRef.current?.selfBrokerage || 0
              )}`}
            </StyledTheme.ThemeColumn>
          )}
        {grossRef.current?.user?.accountType !== "customer" && (
          <StyledTheme.ThemeColumn>
            {`Self Amount: `}
            <span
              style={{
                color: grossRef.current?.selfAmount
                  ? grossRef.current?.selfAmount > 0
                    ? "green"
                    : "red"
                  : "black",
              }}
            >
              {formatToINR(grossRef.current?.selfAmount || 0)}
            </span>
          </StyledTheme.ThemeColumn>
        )}

        <StyledTheme.ThemeColumn>
          {`Final Bill With Brokerage: `}
          <span
            style={{
              color: grossRef.current?.totalNetAmount
                ? grossRef.current?.totalNetAmount > 0
                  ? "green"
                  : "red"
                : "black",
            }}
          >
            {formatToINR(grossRef.current?.totalNetAmount || 0)}
          </span>
        </StyledTheme.ThemeColumn>
      </Row>

      {Object?.keys?.(valanBill)?.length === 0 && (
        <Row justify={"center"} style={{ fontSize: "4rem", marginTop: "4rem" }}>
          <StyledTheme.Table
            columns={column}
            className="w-100"
            dataSource={[]}
            pagination={false}
            locale={{
              emptyText: loading ? <Skeleton active /> : null,
            }}
            bordered={false}
          />
        </Row>
      )}
      {Object?.keys?.(valanBill)?.length > 0 &&
        Object?.keys?.(valanBill)?.map((script, index) => {
          let scriptequ = script === "NSE EQU" ? true : false;
          return (
            <ThemeCard
              style={{
                // border: `2px solid ${token.colorPrimary}`,
                marginBottom: "2rem",
              }}
              key={index}
            >
              <StyledTheme.TitleRow justify={"space-between"} align="bottom">
                <Col
                  xs={24}
                  sm={12}
                  style={{ fontSize: "2rem", fontWeight: "500" }}
                >
                  {script}
                </Col>

                <Col xs={24} sm={12} className="segement-bill-title">
                  {`Segment Bill :`}
                  <span
                    style={{
                      color: valanBill?.[script]?.totalMarketNetAmount
                        ? valanBill?.[script]?.totalMarketNetAmount > 0
                          ? "green"
                          : "red"
                        : "black",
                    }}
                  >
                    {formatToINR(
                      valanBill?.[script]?.totalMarketNetAmount || 0
                    )}
                  </span>
                </Col>
              </StyledTheme.TitleRow>
              {valanBill?.[script]?.trades?.map((item, index) => {
                const date = item?.trades?.[0]?.expiryDate;
                const finalDate = valanBillDate(date, false);
                const opt = item?._id?.marketName?.endsWith("OPT");
                const name = opt
                  ? `${item?._id?.symbolName} ${
                      item?._id?.strikePrice ? item?._id?.strikePrice : ""
                    } ${item?._id?.series ? item?._id?.series : ""} `
                  : item?._id?.symbolName;
                return (
                  <div key={index}>
                    <Row
                      justify={"center"}
                      style={{
                        backgroundColor: token?.colorPrimary,
                        color: "white",
                        fontSize: "1.5rem",
                        borderTopRightRadius: "0.5rem",
                        borderTopLeftRadius: "0.5rem",
                      }}
                    >
                      {name} {finalDate ? finalDate : null}
                    </Row>

                    <StyledTheme.Root style={{ marginBottom: "1rem" }}>
                      <StyledTheme.Table
                        columns={scriptequ ? reportColNseEqu : reportColumn}
                        dataSource={item?.trades}
                        pagination={false}
                        bordered={true}
                        isLoading={loading ? <Skeleton /> : false}
                        rowKey="_id"
                        scroll={{
                          x: 1250,
                        }}
                        style={{
                          position: "sticky",
                        }}
                        summary={() => (
                          <TotalRow
                            data={item}
                            script={script}
                            scriptequ={scriptequ}
                          />
                        )}
                      />
                    </StyledTheme.Root>
                  </div>
                );
              })}
              {!withoutBrokerage && (
                <Row
                  justify={"space-evenly"}
                  style={{
                    backgroundColor: "white",
                    fontWeight: "400",
                  }}
                >
                  <StyledTheme.ThemeColumn>
                    {`Total Brokerage: ${formatToINR(
                      valanBill?.[script]?.totalBrokerage || 0
                    )}`}
                  </StyledTheme.ThemeColumn>
                  {grossRef.current?.user?.accountType !== "customer" && (
                    <StyledTheme.ThemeColumn>
                      {`Self Brokerage: ${formatToINR(
                        valanBill?.[script]?.selfBrokerage || 0
                      )}`}
                    </StyledTheme.ThemeColumn>
                  )}
                  {/* <StyledTheme.ThemeColumn>
                  Final Bill Amt:
                </StyledTheme.ThemeColumn> */}
                </Row>
              )}
            </ThemeCard>
          );
        })}
    </Container>
  );
};

export default ReportCart;

const column = [
  {
    title: "Sr No.",
    dataIndex: "number",
    width: "2rem",
    render: (_text, record, index) => {
      let number = index + 1;
      if (record?.customerId) {
        return <Text style={styles.tableCell}>{number}</Text>;
      }
    },
  },
  {
    title: "Client",
    dataIndex: "client",
    width: "5rem",
    render: (_text, record) => {
      if (record?.customerId) {
        return (
          <Text
            style={styles.tableCell}
          >{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</Text>
        );
      }
    },
  },
  {
    title: "Date",
    dataIndex: "tradeTime",
    width: "8rem",
    render: (text, record) => {
      if (record?.tradeTime) {
        let time = valanBillDate(record?.tradeTime, true);
        return <Text style={styles.tableCell}>{time}</Text>;
      }
    },
  },
  {
    title: "Type",
    dataIndex: "title",
    width: "3rem",
    render: (text, record) => {
      return (
        <Text
          style={[
            styles.tableCell,
            {
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            },
          ]}
        >
          {record?.title}
        </Text>
      );
    },
  },
  {
    title: "Lot",
    dataIndex: "lot",
    width: "3rem",
    render: (text, record) => {
      const lot = formatNumber(record?.lot);
      return (
        <Text style={styles.tableCell}>
          {record?.title !== "live" ? lot : ""}
        </Text>
      );
    },
  },
  {
    title: "Buy Qty",
    dataIndex: "buyQty",
    width: "3rem",
    render: (text, record) => {
      let buy = "-";
      if (record.tradePattern === "buy") {
        buy = record.quantity;
      }
      return (
        <Text
          style={[
            styles.tableCell,
            { color: buy !== "-" ? "green" : "black", fontWeight: "600" },
          ]}
        >
          {formatToINR(buy)}
        </Text>
      );
    },
  },
  {
    title: "Sell Qty",
    dataIndex: "SellQty",
    width: "3rem",
    render: (text, record) => {
      let sell = "-";
      if (record.tradePattern === "sell") {
        sell = record.quantity;
      }
      return (
        <Text
          style={[
            styles.tableCell,
            { color: sell !== "-" ? "red" : "black", fontWeight: "500" },
          ]}
        >
          {formatToINR(sell)}
        </Text>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "tradePrice",
    width: "4rem",
    render: (text, record) => {
      if (record?.tradePrice) {
        const formattedPrice =
          record?.marketName === "FOREX"
            ? record?.tradePrice
            : record?.tradePrice;
        return (
          <Text style={styles.rightAlignedCell}>
            {formatForValanBill(formattedPrice)}
          </Text>
        );
      }
    },
  },
  {
    title: "Net Rate",
    dataIndex: "netRate",
    width: "4rem",
    render: (text, record) => {
      if (record?.netRate) {
        const netRate = record?.netRate;
        return (
          <Text style={styles.rightAlignedCell}>
            {formatForValanBill(netRate)}
          </Text>
        );
      }
    },
  },
  {
    title: "Net Amount",
    dataIndex: "netAmount",
    width: "4rem",
    render: (text, record) => {
      if (record?.netAmount) {
        const netAmount = record?.netAmount;
        return (
          <Text style={styles.rightAlignedCell}>
            {formatForValanBill(netAmount)}
          </Text>
        );
      }
    },
  },
];

export const valanBillDate = (data, flage) => {
  if (!data) {
    return null;
  }

  // Convert the timestamp
  const date = new Date(data);

  const formattedTime = date.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
    hour12: false,
  });
  const formattedDate = `${formattedTime}`;

  // Format date using UTC methods
  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "long",
    timeZone: "UTC",
  });
  const year = date.getUTCFullYear();

  // Mapping of full month names to abbreviated month names
  const monthAbbreviations = {
    January: "JAN",
    February: "FEB",
    March: "MAR",
    April: "APR",
    May: "MAY",
    June: "JUN",
    July: "JUL",
    August: "AUG",
    September: "SEP",
    October: "OCT",
    November: "NOV",
    December: "DEC",
  };

  const abbreviatedMonth = monthAbbreviations[month];

  if (flage) {
    return `${formattedDate} ${day}${abbreviatedMonth}${year}`;
  } else {
    return `${day}${abbreviatedMonth}${year}`;
  }
};
