import { Checkbox, Col, Modal, Row, Space } from "antd";
import styled from "styled-components";

export const StyledThemeInput = {
  Modal: styled(Modal)`
    & .ant-modal-content {
      background-color: ${(props) => props.theme.token.colorBgLayout};
    }
    .ant-modal-header {
      background-color: ${(props) => props.theme.token.colorBgLayout};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-modal-title {
      width: fit-content;
      color: ${(props) => props.theme.token.colorPrimary};
      font-weight: 500;
      font-size: 1.3rem;
      margin-block: 1rem;
    }
  `,
};
export const StyleDiv = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;
export const RowDiv = styled(Row)`
  width: 100%;
`;
export const RightRow = styled(Row)`
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const LeftRow = styled(Row)`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-top: 2rem;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const Accounts = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: auto;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
export const AccountDetails = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 1rem;
  /* border: 1px solid red; */
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 3rem;
  }
`;
export const Main = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const ProfilCol = styled(Col)`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 769px) {
    display: none;
  }
`;

export const StyledTheme = {
  Wrapper: styled(Space)`
    justify-content: space-between;
    & .ant-tabs-ink-bar {
      height: 0 !important;
    }
    &.ant-space {
      width: 100%;
    }
    .ant-space-item {
      width: 100%;
    }
    .ant-tabs-nav-list {
      width: 100%;
      justify-content: space-between;
    }
  `,
  Checkbox: styled(Checkbox)`
    & .ant-checkbox-inner {
      /* border: none;
      background-color: ${(props) =>
        `${props.theme.token.Checkbox.colorBgLightGrey} !important`};
      border-color: ${(props) =>
        `${props.theme.token.Checkbox.colorBgLightGrey} !important`}; */
    }

    .ant-checkbox-inner::after {
      ${"" /* background-color: #add8e6; */}/* border-color: ${(props) =>
        props?.checked
          ? props.theme.token.colorBgActionButton
          : props.theme.token.colorTextLightSolid}; */
    }
  `,
  TabPaneWrapper: styled(Space)`
    width: 100%;
    height: ${(props) => (props.show ? "fit-content" : "0rem")};
    min-height: ${(props) => (props.show ? "0rem" : "0rem")};
    max-height: ${(props) => (props.show ? "150rem" : "0rem")};
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 400ms ease-in-out;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    & .ant-space-item {
      width: 100%;
    }
  `,
  TabDiv: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.token?.colorPrimaryBgHover};
  `,
};
