import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webSocket: { data: {}, socket: false },
};

export const webSocketSlice = createSlice({
  name: "webSocketSlice",
  initialState,
  reducers: {
    resetWebSocket: () => ({ ...initialState }),
    setWebSocket(state, action) {
      const payload = action?.payload;

      if (!payload) {
        return; // Exit early if payload is undefined or null
      }

      // Ensure that state.webSocket is initialized as an object
      if (typeof state.webSocket !== "object" || state.webSocket === null) {
        console.error(
          "state.webSocket is not an object or is null. Current value:",
          state.webSocket
        );
        state.webSocket = {};
      }

      // Ensure that state.webSocket.data is initialized as an object
      if (!state.webSocket.hasOwnProperty("data")) {
        console.error(
          "state.webSocket.data is not initialized. Current value:",
          state.webSocket.data
        );
        state.webSocket.data = {};
      }
      Object?.keys?.(payload)?.forEach((key) => {
        if (state.webSocket?.data) {
          state.webSocket.data[key] = payload[key];
        }
        // else {
        //   state.webSocket.data[key] = payload[key];
        // }
      });
    },
    resetSocketData: (state, action) => {
      const symbols = action.payload;
      let ObjData = state.webSocket?.data;

      for (let key in ObjData) {
        let valid = symbols?.includes(key);
        if (!valid && key !== "256265" && key !== "265") {
          delete state.webSocket.data[key];
        }
      }
    },
    setSocketFlag: (state, action) => {
      state.webSocket.socket = action.payload;
    },
  },
});

export const { setWebSocket, resetWebSocket, setSocketFlag, resetSocketData } =
  webSocketSlice.actions;
export default webSocketSlice.reducer;
