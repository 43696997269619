import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  theme,
} from "antd";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBrokerList } from "../../../../store/slice/brokaerForUsersFormSlice";
import ThemeCard from "../../../../components/ThemeCard";
import ThemeTextLabel from "../../../../components/ThemeTextLabel";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const sequence = [
  "NSE FUT",
  "NSE OPT",
  "NSE EQU",
  "MCX",
  "MCX OPT",
  "FOREX",
  "COMEX",
  "GLOBEX",
];
const AdditionalSharingView = ({ tab, view, parentForm, is_edits }) => {
  const dispatch = useDispatch();
  const token = theme.useToken().token;

  //state

  let watchAdditionalSharing = Form.useWatch(
    `additionalSharing$${tab}`,
    parentForm
  );
  //redux
  const brokersList = useSelector((state) => state.brokaerForUsersFormSlice);
  const tempBrokerList = brokersList.brokerInitiaData;

  const market_access = useSelector(
    (state) => state.userAccountSlice.market_access
  );

  useEffect(() => {
    if (watchAdditionalSharing?.length === 0) {
      dispatch(
        setBrokerList([
          ...tempBrokerList,
          // ...mainData,
        ])
      );
    }
  }, [watchAdditionalSharing]);

  useEffect(() => {
    if (
      !parentForm.getFieldValue(`additionalSharing$${tab}`) ||
      parentForm.getFieldValue(`additionalSharing$${tab}`).length === 0
    ) {
      let temp = market_access.find((item) =>
        parentForm.getFieldValue(`additionalSharing$${item}`)
      );
      let additinalArray = parentForm
        ?.getFieldValue(`additionalSharing$${temp}`)
        ?.map((item) => {
          return { brokerId: item?.brokerId };
        });

      parentForm.setFieldValue(`additionalSharing$${tab}`, additinalArray);
    }
  }, [market_access]);

  useEffect(() => {
    if (brokersList) {
      let brokers = parentForm.getFieldValue(`additionalSharing$${tab}`);

      let letestOption = brokersList?.brokerList?.filter((el) => {
        return !brokers?.some((item) => item.brokerId === el?.value);
      });

      if (letestOption) {
        dispatch(setBrokerList([...letestOption]));
      }
    }
  }, [brokersList?.brokerInitiaData]);

  // api call
  return (
    <ThemeCard
      title="Additional sharing"
      className="additional-sharing-card"
      headerColor
      style={{ marginBottom: "2rem", overflow: "auto" }}
    >
      <div style={{ overflow: "auto" }}>
        <Row
          style={{
            margin: "unset",
            marginBottom: "0px",
            marginTop: "1rem",
            minWidth: "800px",
          }}
          gutter={[2, 2]}
        >
          <Col span={2} align="center">
            <ThemeTextLabel title="No" titleFontWeight={400} />
          </Col>
          <Col span={4} align="center">
            <ThemeTextLabel title="Broker ID" titleFontWeight={400} />
          </Col>

          <Col span={5} align="center">
            <ThemeTextLabel title="Percentage" titleFontWeight={400} />
          </Col>
          <Col span={5} align="center">
            <ThemeTextLabel title="Delivery(Buy+Sell)" titleFontWeight={400} />
          </Col>

          <Col span={5} align="center">
            <ThemeTextLabel title="Intraday(Buy+Sell)" titleFontWeight={400} />
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ marginBlock: "2rem", minWidth: "800px" }}
        />
        <Form.List name={`additionalSharing$${tab}`} initialValue={[]}>
          {(fields, { add, remove, move }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    key={key}
                    style={{ marginBottom: "6px", minWidth: "800px" }}
                    gutter={[10, 10]}
                  >
                    <Col span={2} align="center" style={{ margin: "6px" }}>
                      {name + 1}
                    </Col>
                    <Col span={4} align="center">
                      <Form.Item
                        {...restField}
                        name={[name, "brokerId"]}
                        className="w-100"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(`Please select brokerId`)
                              );
                            },
                          }),
                        ]}
                      >
                        <Select
                          placeholder="Broker"
                          options={brokersList.brokerList}
                          onChange={(e, v, b) => {
                            let idFromForm = parentForm
                              ?.getFieldValue(`additionalSharing$${tab}`)
                              ?.map((item) => item?.brokerId);
                            let idFromAlllScrip = brokersList.brokerInitialIds;
                            let exIds = idFromAlllScrip.filter(
                              (item) => !idFromForm?.includes(item)
                            );
                            let mainData = brokersList.brokerInitiaData.filter(
                              (item) => exIds.includes(item.value)
                            );

                            //////////////
                            function changeBrokerId(marketName) {
                              let addSheByMarket = parentForm.getFieldValue(
                                `additionalSharing$${marketName}`
                              );
                              if (addSheByMarket) {
                                let mappedData = addSheByMarket.map(
                                  (item, index) => ({
                                    ...item,
                                    brokerId:
                                      index === key ? e : item?.brokerId || e,
                                  })
                                );

                                return mappedData;
                              } else {
                                return [{ brokerId: e }];
                              }
                            }
                            parentForm.setFields(
                              sequence
                                .filter((item) => item !== tab)
                                .map((item) => {
                                  return {
                                    name: `additionalSharing$${item}`,
                                    value: changeBrokerId(item),
                                    touched: true,
                                  };
                                })
                            );
                            ////////////////
                            dispatch(setBrokerList([...mainData]));
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5} align="center">
                      <Form.Item
                        {...restField}
                        name={[name, `percentage`]}
                        initialValue={0}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                value >= 0 &&
                                value <= 100 &&
                                value !== null
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(`Only allow between 0 to 100`)
                              );
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          // disabled={parentForm.getFieldValue(
                          //   `additionalSharing$${tab}`
                          // )}
                          // defaultValue={0}
                          className="w-100"
                          // inputProps={{
                          //   name: `percentage`,
                          // }}
                          name="percentage"
                          addonAfter="%"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5} align="center">
                      <Form.Item
                        {...restField}
                        name={[name, `delivery`]}
                        initialValue={0}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (
                                value >= 0 &&
                                value <= 100 &&
                                value !== null
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(`Only allow between 0 to 100`)
                              );
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          name={`delivery`}
                          className="w-100"
                          addonAfter="%"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5} align="center">
                      <Form.Item
                        {...restField}
                        name={[name, `intraday`]}
                        initialValue={0}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (
                                value >= 0 &&
                                value <= 100 &&
                                value !== null
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(`Only allow between 0 to 100`)
                              );
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className="w-100"
                          name={`intraday`}
                          addonAfter="%"
                        />
                      </Form.Item>
                    </Col>
                    <span>
                      <Button
                        type="primary"
                        title="Remove"
                        onClick={() => {
                          let brokerID =
                            parentForm.getFieldsValue()?.[
                              `additionalSharing$${tab}`
                            ]?.[name]?.brokerId;
                          let removedBroker = tempBrokerList.find(
                            (item) => item.value === brokerID
                          );

                          if (brokerID) {
                            dispatch(
                              setBrokerList([
                                ...brokersList.brokerList,
                                removedBroker,
                              ])
                            );
                            remove(name);
                          } else {
                            remove(name);
                          }
                          parentForm.setFields(
                            sequence
                              .filter((item) => item !== tab)
                              .map((item) => {
                                let temp = parentForm.getFieldValue(
                                  `additionalSharing$${item}`
                                );
                                temp.splice(name, 1);
                                return {
                                  name: `additionalSharing$${item}`,
                                  value: temp,
                                  touched: true,
                                };
                              })
                          );
                        }}
                        icon={<MinusCircleOutlined />}
                      ></Button>
                    </span>
                  </Row>
                ))}
                <Form.Item style={{ position: "sticky", left: 0 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (fields.length < 3) {
                        add();
                        parentForm.setFields(
                          sequence
                            .filter((item) => item !== tab)
                            .map((item) => {
                              const currentValue =
                                parentForm.getFieldValue(
                                  `additionalSharing$${item}`
                                ) || [];
                              return {
                                name: `additionalSharing$${item}`,
                                value:
                                  currentValue.length < 3
                                    ? [...currentValue, {}]
                                    : currentValue,
                                touched: true,
                              };
                            })
                        );
                      }
                    }}
                    block
                    disabled={fields.length >= 3}
                    icon={<PlusOutlined />}
                  >
                    Add Broker
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </div>
    </ThemeCard>
  );
};

export default AdditionalSharingView;
