import { Button, Table } from "antd";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG};
  font-size: 1rem;
  width: 100%;
  background-color: ${(props) => props.theme.token.colorBgActionButton};
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  margin-right: 2rem;
  height: 3rem;
  @media (max-width: 1079px) {
    height: 2.5rem;
    font-size: 0.8rem;
  }
`;

const StyledTable = styled(Table)`
  margin-top: 2rem;
  // Define a styled component for the Table
  .ant-table-thead tr th {
    padding: 20px 0px !important;
    background-color: #f0f2f5; /* Change the background color of table headers */
  }

  .ant-table-tbody > tr > td {
    padding: 15px !important;
    color: #727880 !important;
  }
  .ant-table-thead tr .ant-table-cell {
    background-color: #798478;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    padding: 15px !important;
    font-size: 1rem;
    @media (max-width: 1079px) {
      font-size: 0.8rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }
`;
const StyledSummarySearchInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: unset !important;
  }
`;
const StyledImg = styled.img`
  margin-left: 2rem;
  width: 25px;
  height: 25px;
`;
const StyledThemeCard = styled.div`
  margin-top: 2rem;
  border-radius: ${(props) =>
    `${props.theme.token.borderRadiusLG}px ${props.theme.token.borderRadiusLG}px`};
  background-color: ${(props) => props.theme.token.themeCardBackground};
  padding: 2rem 0rem;
`;
const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.token.textColor};
  margin-right: 1rem;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
  }
`;
const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  
  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;
