import { Row } from "antd";
import styled from "styled-components";

export const MarketTab = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 80%;
  gap: 2rem;
  align-items: center;
  align-content: center;
  margin: auto;
  margin-top: 4rem;
  /* border: 1px solid green; */

  @media screen and (max-width: 1000px) {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const MarketAccess = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-inline: auto;
  /* border: 1px solid red; */

  @media screen and (max-width: 1000px) {
    margin-top: 2rem;
    width: 100%;
  }
`;
export const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: "center";
  }
`;
export const StyleRow = styled(Row)`
  padding-inline: 5rem;
  @media (max-width: 550px) {
    padding-inline: 0rem;
  }
`;
