import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offlineTrade: false,
  selectedMarket: {
    "NSE FUT": true,
    "NSE OPT": true,
    "NSE EQU": true,
    MCX: true,
    "MCX OPT": true,
    FOREX: true,
    COMEX: true,
    GLOBEX: true,
  },
  data: {
    "NSE FUT": [],
    "NSE OPT": [],
    "NSE EQU": [],
    MCX: [],
    "MCX OPT": [],
    FOREX: [],
    COMEX: [],
    GLOBEX: [],
  },
  watchListArray: [],
  seletctedMaketIndex: 0,
  marketWatchLoading: false,
};

export const marketWatchSlice = createSlice({
  name: "marketWatchSlice",
  initialState,
  reducers: {
    resetMarketWatchSlice: (state, action) => {
      const { key } = action?.payload;
      if (key === "all") {
        return { ...initialState };
      } else {
        state.data = { ...initialState.data };
      }
      // ({ ...initialState }),
    },
    setMarketWatchData(state, action) {
      const { nested_key, key, type, payload, previous } = action.payload;

      if (type === "add") {
        let allAddedScripts = state[nested_key][key]?.map((o1) => o1.symbol);
        if (
          state[nested_key][key] &&
          !allAddedScripts.includes(payload.symbol)
        ) {
          state[nested_key][key].push(payload);
        } else {
          const existingItemIndex = state[nested_key][key]?.findIndex(
            (o) => o.symbol === payload.symbol
          );
          if (existingItemIndex !== -1) {
            state[nested_key][key][existingItemIndex] = payload;
          }
        }
      }
      if (type === "multiple_add") {
        // let allAddedScripts = state[nested_key][key]?.map((o1) => o1.symbol);
        // console.log("nested", nested_key, key);
        // payload?.forEach((item) => {
        //   if (
        //     state[nested_key][key] &&
        //     !allAddedScripts?.includes(item.symbol)
        //   ) {
        //     state[nested_key][key].push(item);
        //   } else {
        //     const existingItemIndex = state[nested_key][key]?.findIndex(
        //       (o) => o.symbol === item.symbol
        //     );

        //     if (existingItemIndex !== -1) {
        //       state[nested_key][key][existingItemIndex] = item;
        //     }
        //   }
        // });
        if (!previous) {
          state[nested_key][key] = [];
        }
        payload?.forEach((item) => {
          state[nested_key][key].push(item);
        });
      }
      if (type === "delete") {
        state[nested_key][key] = state[nested_key][key]?.filter(
          (item) => item._id !== payload
        );
      }
      if (type === "delete_all") {
        state[nested_key][key] = [];
      }
      const sortedItems = state[nested_key][key]?.slice()?.sort((a, b) => {
        return a?.symbol?.localeCompare(b?.symbol);
      });
      state[nested_key][key] = sortedItems;
    },
    setOfflineTrade(state, action) {
      state.offlineTrade = action.payload;
    },
    newSelectedMarket: (state, action) => {
      state.selectedMarket = action.payload;
    },
    addPositionInMarketWatch: (state, action) => {
      const { key, payload } = action.payload;

      const scriptIndex = state["data"][key]?.findIndex(
        (el) => el.symbol === payload?.symbol
      );
      if (scriptIndex !== -1) {
        state["data"]?.[key]?.[scriptIndex]?.positions.push(payload);
      }
    },
    setMarketWatchLoading: (state, action) => {
      if (state.marketWatchLoading !== action.payload) {
        state.marketWatchLoading = action.payload;
      }
    },
    setSeletedMarket: (state, action) => {
      state.seletctedMaketIndex = action.payload;
    },
    setWatchListArray: (state, action) => {
      const { type, data, index, id, name, sortedList } = action.payload;
      switch (type) {
        case "add":
          state.watchListArray.push(data);
          break;
        case "new":
          state.watchListArray = data;
          break;
        case "scriptAdd":
          state.watchListArray[index].scriptList.push(data);
          break;
        case "deleteScript":
          state.watchListArray[index].scriptList = state.watchListArray[
            index
          ]?.scriptList?.filter((el) => el?._id !== id);
          break;
        case "rename":
          console.log("slice", name, index);
          state.watchListArray[index].name = name;
          break;
        case "deleteWatchList":
          state.watchListArray = state.watchListArray.filter(
            (el) => el._id !== state.watchListArray[index]?._id
          );
          break;
        case "deleteAllScript":
          state.watchListArray[index].scriptList = [];
          break;
        case "sortedWatchList":
          state.watchListArray[index].scriptList = sortedList;
        default:
          break;
      }
      // if (type === "add") {
      //   state.watchListArray.push(data);
      // } else if (type === "new") {
      //   state.watchListArray = data;
      // } else if (type === "scriptAdd") {
      //   state.watchListArray[index].scriptList.push(data);
      // } else if (type === "deleteScript") {
      //   state.watchListArray[index].scriptList = state.watchListArray[
      //     index
      //   ]?.scriptList?.filter((el) => el?._id !== id);
      // }
    },
  },
});

export const {
  setMarketWatchData,
  setOfflineTrade,
  resetMarketWatchSlice,
  newSelectedMarket,
  addPositionInMarketWatch,
  setMarketWatchLoading,
  setWatchListArray,
  setSeletedMarket,
} = marketWatchSlice.actions;
export default marketWatchSlice.reducer;
