import dayjs from "dayjs";
import store from "../../store/store";

export const initializeObject = () => ({
  "NSE FUT": [],
  "NSE OPT": [],
  "NSE EQU": [],
  MCX: [],
  "MCX OPT": [],
  FOREX: [],
  COMEX: [],
  GLOBEX: [],
});
const SetUserDetails1 = (userInfo, m2m, defalutSetting) => {
  const parentDetails = store.getState()?.authSlice?.MarketAccessDetails;
  const PreviousData = store.getState()?.userAccountSlice;
  const loginUser = store.getState()?.authSlice?.userDetail?.accountType;

  let brokerageObj = {
    "NSE FUT": [],
    "NSE OPT": [],
    "NSE EQU": [],
    MCX: [],
    "MCX OPT": [],
    FOREX: [],
    COMEX: [],
    GLOBEX: [],
  };
  let scriptWiseObj = {
    "NSE FUT": [],
    "NSE OPT": [],
    "NSE EQU": [],
    MCX: [],
    "MCX OPT": [],
    FOREX: [],
    COMEX: [],
    GLOBEX: [],
  };
  let marginObj = {
    "NSE FUT": [],
    "NSE OPT": [],
    "NSE EQU": [],
    MCX: [],
    "MCX OPT": [],
    FOREX: [],
    COMEX: [],
    GLOBEX: [],
  };
  let additionalSharingObj = {
    "NSE FUT": [],
    "NSE OPT": [],
    "NSE EQU": [],
    MCX: [],
    "MCX OPT": [],
    FOREX: [],
    COMEX: [],
    GLOBEX: [],
  };

  if (userInfo.additionalSharing) {
    for (let i = 0; i < userInfo?.additionalSharing.length; i++) {
      additionalSharingObj[
        userInfo.additionalSharing[i]?.marketAccessId?.scriptName
      ].push({
        ...userInfo.additionalSharing[i],
        brokerId: Number(userInfo.additionalSharing[i].brokerId?.userId),
      });
    }

    for (let key in additionalSharingObj) {
      additionalSharingObj[key].sort((a, b) => a.brokerId - b.brokerId);
    }
  }

  for (let i = 0; i < userInfo.sharingBrokerageSchema?.length; i++) {
    brokerageObj[userInfo.sharingBrokerageSchema[i].marketAccessId.scriptName] =
      userInfo.sharingBrokerageSchema[i];
  }
  for (let k = 0; k < userInfo.scriptWiseSettingInfo?.length; k++) {
    scriptWiseObj[userInfo.scriptWiseSettingInfo[k].marketAccessId.scriptName] =
      userInfo.scriptWiseSettingInfo[k];
  }
  for (let j = 0; j < userInfo.margin?.length; j++) {
    marginObj[userInfo.margin[j].marketAccessId.scriptName] =
      userInfo.margin[j];
  }

  let marketAccess = userInfo.marketAccess?.map((item) => item.scriptName);

  let allowAccount = userInfo?.accountDetails?.totalAccounts?.[0];
  const importUser = store.getState()?.userAccountSlice?.basicInfo;

  const marketArray = store
    .getState()
    ?.globleSlice?.marketListData?.map((el) => el?.scriptName);

  let obj = {
    accountType: userInfo?.user?.accountType,
    // parentId: 1234,
    // is_sample_account: false,
    // is_edit: true,
    edit_userData: [],
    basicInfo: {
      accountName: importUser?.accountName || "",
      // accountId: basicInfo?.accountId,
      balance: m2m ? userInfo?.user?.balance : importUser?.balance || "",
      openingBalance: importUser?.openingBalance || 0,
      accountPassword: 1234,
      linkLedger: importUser?.linkLedger || false,
      importUserSettings: defalutSetting ? "" : importUser?.importUserSettings,
      defaultUser: importUser?.defaultUser || "",
    },
    accountDetails: {
      allowNoOfAccountSuperAdmin: allowAccount?.SuperAdmin,
      allowNoOfAccountAdmin: allowAccount?.Admin,
      allowNoOfAccountMaster: allowAccount?.Master,
      allowNoOfAccountBroker: allowAccount?.Broker,
      allowNoOfAccountDealer: allowAccount?.Dealer,
      allowNoOfAccountCustomer: allowAccount?.Customer,
      partnershipWith:
        loginUser === "owner"
          ? userInfo.accountDetails?.partnershipWith
          : parentDetails?.accountDetails?.partnershipWith,
      minimumBrokerageRequire:
        loginUser === "owner"
          ? userInfo.accountDetails?.minimumBrokerageRequire
          : parentDetails?.accountDetails?.minimumBrokerageRequire,
      additionalInfo:
        loginUser === "owner"
          ? userInfo?.additionalInfoSchema?.[0]
          : parentDetails?.additional || {},
      orderBetweenHighLow: userInfo.accountDetails?.orderBetweenHighLow,
      showDetailBill: userInfo.accountDetails?.showDetailBill,
      allowBannedScript: userInfo.accountDetails?.allowBannedScript,
      isOnlyPositionSquareup: userInfo.accountDetails?.isOnlyPositionSquareup,
      isAutoStopNewPosition: userInfo.accountDetails?.isAutoStopNewPosition,
      autoStopNewPosition: {
        profit: userInfo.accountDetails?.applyAutoSquareupProfit,
        loss: userInfo.accountDetails?.autoStopNewPositionLoss,
      },
      isApplyAutoSquareup: userInfo.accountDetails?.applyAutoSquareup,
      applyAutoSquareup: {
        profit: userInfo.accountDetails?.applyAutoSquareupProfit,
        loss: userInfo.accountDetails?.applyAutoSquareupLoss,
      },
      isM2MAlert: userInfo.accountDetails?.isM2MAlert,
      M2MAlert: {
        profit: userInfo.accountDetails?.m2mAlertProfit,
        loss: userInfo.accountDetails?.m2mAlertLoss,
      },
      isNewPositionSquareupTime:
        userInfo.accountDetails?.isNewPositionSqaureupTime,
      newPositionSquareupTime: {
        time: userInfo.accountDetails?.newPositionSqaureupTime,
      },
      isFreshLimitAllow: userInfo.accountDetails?.isFreshLimitAllow,
      freshLimitAllow: userInfo.accountDetails?.freshLimitAllow,
      remark: userInfo.accountDetails?.remark,
    },
    // accountDetails: {
    //   allowNoOfAccountSuperAdmin: allowAccount?.SuperAdmin,
    //   allowNoOfAccountAdmin: allowAccount?.Admin,
    //   allowNoOfAccountMaster: allowAccount?.Master,
    //   allowNoOfAccountBroker: allowAccount?.Broker,
    //   allowNoOfAccountDealer: allowAccount?.Dealer,
    //   allowNoOfAccountCustomer: allowAccount?.Customer,
    //   partnershipWith:
    //     loginUser === "owner"
    //       ? userInfo.accountDetails?.partnershipWith
    //       : parentDetails?.accountDetails?.partnershipWith,
    //   minimumBrokerageRequire:
    //     loginUser === "owner"
    //       ? userInfo.accountDetails?.minimumBrokerageRequire
    //       : parentDetails?.accountDetails?.minimumBrokerageRequire,
    //   additionalInfo:
    //     loginUser === "owner"
    //       ? userInfo?.additionalInfoSchema?.[0]
    //       : parentDetails?.additional || {},
    //   orderBetweenHighLow: userInfo.accountDetails?.orderBetweenHighLow,
    //   showDetailBill: userInfo.accountDetails?.showDetailBill,
    //   allowBannedScript: userInfo.accountDetails?.allowBannedScript,
    //   isOnlyPositionSquareup: userInfo.accountDetails?.isOnlyPositionSquareup,
    //   isAutoStopNewPosition: userInfo.accountDetails?.isAutoStopNewPosition,
    //   autoStopNewPosition: {
    //     profit: userInfo.accountDetails?.applyAutoSquareupProfit,
    //     loss: userInfo.accountDetails?.autoStopNewPositionLoss,
    //   },
    //   isApplyAutoSquareup: userInfo.accountDetails?.applyAutoSquareup,
    //   applyAutoSquareup: {
    //     profit: userInfo.accountDetails?.applyAutoSquareupProfit,
    //     loss: userInfo.accountDetails?.applyAutoSquareupLoss,
    //   },
    //   isM2MAlert: userInfo.accountDetails?.isM2MAlert,
    //   M2MAlert: {
    //     profit: userInfo.accountDetails?.m2mAlertProfit,
    //     loss: userInfo.accountDetails?.m2mAlertLoss,
    //   },
    //   isNewPositionSquareupTime:
    //     userInfo.accountDetails?.isNewPositionSqaureupTime,
    //   newPositionSquareupTime: {
    //     time: userInfo.accountDetails?.newPositionSqaureupTime,
    //   },
    //   isFreshLimitAllow: userInfo.accountDetails?.isFreshLimitAllow,
    //   FreshLimitAllowed: {
    //     gap: userInfo.accountDetails?.freshLimitAllow,
    //   },
    //   remark: userInfo.accountDetails?.remark,
    // },
    market_access: marketAccess,
  };

  for (let i = 0; i < marketArray.length; i++) {
    let name =
      marketArray[i] === "NSE EQU" ? "totalNoOfQuantity" : "totalNoOfLot";
    let brokerageDetails = {
      delivery: brokerageObj[marketArray[i]]?.deliveryTotal || 0,
      deliveryBuy: brokerageObj[marketArray[i]]?.deliveryBuy || 0,
      intradayBuy: brokerageObj[marketArray[i]]?.intradayBuy || 0,
      intraday: brokerageObj[marketArray[i]]?.intradayTotal || 0,
      deliverySell: brokerageObj[marketArray[i]]?.deliverySell || 0,
      intradaySell: brokerageObj[marketArray[i]]?.intradaySell || 0,
      percentage: brokerageObj[marketArray[i]]?.percentage || 0,
      brokerageType:
        loginUser === "owner"
          ? brokerageObj[marketArray[i]]?.brokerageType
          : parentDetails?.brokarageDetails?.[marketArray[i]]?.brokerageType,
      remainingPercentage:
        brokerageObj[marketArray[i]]?.remainingPercentage || 0,
    };
    let additionalSharing = additionalSharingObj?.[marketArray[i]] || [];
    let margin = {
      type: marginObj[marketArray[i]]?.type || "value",
      delivery: marginObj[marketArray[i]]?.history?.[0]?.delivery || 0,
      intraday: marginObj[marketArray[i]]?.history?.[0]?.intraday || 0,
      total: marginObj[marketArray[i]]?.history?.[0]?.total || 0,
      interestRate: marginObj[marketArray[i]]?.interestRate || 0,
    };
    let scriptWiseSettingInfo = {
      minimumScriptRate: scriptWiseObj[marketArray[i]]?.minimumScriptRate || 0,
      totalNoOfScriptAllowed:
        scriptWiseObj[marketArray[i]]?.totalNoOfScripts || null,
      orderLimit: scriptWiseObj[marketArray[i]]?.orderLimits || null,
      strikeRateMaxLot: scriptWiseObj[marketArray[i]]?.strikeRateMaxLot || null,
      allowShortSell: scriptWiseObj[marketArray[i]]?.allowShortSell || false,
      allowLimitOrder: scriptWiseObj[marketArray[i]]?.allowLimitOrder || true,
      [name]: scriptWiseObj[marketArray[i]]?.[name] || null,
    };
    let allScript =
      loginUser === "owner"
        ? userInfo.scriptWiseSetting?.allScript?.[marketArray[i]] || []
        : PreviousData?.[marketArray[i]]?.allScript || [];
    let blockScript =
      loginUser === "owner"
        ? userInfo.scriptWiseSetting?.blockScript?.[marketArray[i]] || []
        : PreviousData?.[marketArray[i]]?.blockScript || [];

    obj[marketArray[i]] = {
      brokerageDetails,
      additionalSharing,
      margin,
      scriptWiseSettingInfo,
      allScript,
      blockScript,
    };
  }

  return obj;
};

const SetUserDetails = (userInfo, m2m, defaultSetting, form) => {
  const {
    MarketAccessDetails: parentDetails,
    userDetail: { accountType },
  } = store.getState().authSlice;
  const PreviousData = store.getState().userAccountSlice;
  const loginUser = accountType;
  const isOwner = accountType === "owner";
  const isRent = parentDetails?.accountDetails?.partnershipWith === "rent";
  const additionalSharingObj = initializeObject();

  if (userInfo?.additionalSharing) {
    userInfo?.additionalSharing?.forEach((sharing) => {
      const { marketAccessId, brokerId } = sharing;
      additionalSharingObj[marketAccessId.scriptName].push({
        ...sharing,
        brokerId: Number(brokerId.userId),
      });
    });

    Object.values(additionalSharingObj)?.forEach((scripts) =>
      scripts.sort((a, b) => a.brokerId - b.brokerId)
    );
  }

  const brokerageObj = userInfo?.sharingBrokerageSchema?.reduce(
    (acc, schema) => {
      const { marketAccessId } = schema;
      acc[marketAccessId.scriptName] = schema;
      return acc;
    },
    {}
  );

  const scriptWiseObj = userInfo?.scriptWiseSettingInfo?.reduce(
    (acc, setting) => {
      const { marketAccessId } = setting;
      acc[marketAccessId.scriptName] = setting;
      return acc;
    },
    {}
  );

  const marginObj = userInfo?.margin?.reduce((acc, marginData) => {
    const { marketAccessId } = marginData;
    acc[marketAccessId.scriptName] = marginData;
    return acc;
  }, {});
  let onlyPositionObj = {};
  userInfo.marketAccess?.forEach((el) => {
    if (!onlyPositionObj[el?.scriptName]) {
      onlyPositionObj[el?.scriptName] = {};
    }
    onlyPositionObj[el?.scriptName] = {
      isOnlyPositionSquareup: el?.isOnlyPositionSquareup,
    };
  });

  const marketAccess = userInfo?.marketAccess?.map(
    ({ scriptName }) => scriptName
  );

  const allowAccount = userInfo?.accountDetails?.totalAccounts?.[0];
  const importUser = PreviousData?.basicInfo;

  const marketArray = store
    .getState()
    ?.globleSlice?.marketListData?.map(({ scriptName }) => scriptName);

  const obj = {
    accountType: userInfo?.user?.accountType,
    basicInfo: {
      accountName: form.getFieldValue("accountName") || "",
      balance: m2m
        ? userInfo?.user?.balance
        : form.getFieldValue("balance") || "",
      accountPassword: 1234,
      linkLedger: importUser?.linkLedger || false,
      importUserSettings: defaultSetting ? "" : importUser?.importUserSettings,
      defaultUser: importUser?.defaultUser || "",
    },
    accountDetails: {
      allowNoOfAccountSuperAdmin: allowAccount?.SuperAdmin,
      allowNoOfAccountAdmin: allowAccount?.Admin,
      allowNoOfAccountMaster: allowAccount?.Master,
      allowNoOfAccountBroker: allowAccount?.Broker,
      allowNoOfAccountDealer: allowAccount?.Dealer,
      allowNoOfAccountCustomer: allowAccount?.Customer,
      partnershipWith: isOwner
        ? userInfo.accountDetails?.partnershipWith
        : isRent
        ? "percentage"
        : parentDetails?.accountDetails?.partnershipWith,
      minimumBrokerageRequire: isOwner
        ? userInfo.accountDetails?.minimumBrokerageRequire
        : parentDetails?.accountDetails?.minimumBrokerageRequire === true
        ? true
        : userInfo.accountDetails?.minimumBrokerageRequire,
      additionalInfo: isOwner
        ? userInfo?.additionalInfoSchema?.[0]
        : parentDetails?.additional || {},
      orderBetweenHighLow: userInfo.accountDetails?.orderBetweenHighLow,
      showDetailBill: userInfo.accountDetails?.showDetailBill,
      allowBannedScript: userInfo.accountDetails?.allowBannedScript,
      isOnlyPositionSquareup: userInfo.accountDetails?.isOnlyPositionSquareup,
      isAutoStopNewPosition: userInfo.accountDetails?.isAutoStopNewPosition,
      autoStopNewPosition: {
        profit: userInfo.accountDetails?.applyAutoSquareupProfit,
        loss: userInfo.accountDetails?.autoStopNewPositionLoss,
      },
      isApplyAutoSquareup: userInfo.accountDetails?.applyAutoSquareup,
      applyAutoSquareup: {
        profit: userInfo.accountDetails?.applyAutoSquareupProfit,
        loss: userInfo.accountDetails?.applyAutoSquareupLoss,
      },
      isM2MAlert: userInfo.accountDetails?.isM2MAlert,
      M2MAlert: {
        profit: userInfo.accountDetails?.m2mAlertProfit,
        loss: userInfo.accountDetails?.m2mAlertLoss,
      },
      isNewPositionSquareupTime:
        userInfo.accountDetails?.isNewPositionSqaureupTime,
      newPositionSquareupTime: userInfo.accountDetails?.newPositionSqaureupTime
        ? dayjs(userInfo.accountDetails?.newPositionSqaureupTime) || null
        : null || null,
      isFreshLimitAllow: userInfo.accountDetails?.isFreshLimitAllow,
      freshLimitAllow: userInfo.accountDetails?.freshLimitAllow,
      remark: userInfo.accountDetails?.remark,
    },
    market_access: marketArray,
  };

  marketArray.forEach((scriptName) => {
    const name =
      scriptName === "NSE EQU" ? "totalNoOfQuantity" : "totalNoOfLot";

    let isOnlyPositionSquareup =
      onlyPositionObj?.[scriptName]?.isOnlyPositionSquareup || false;
    const brokerageDetails = {
      deliveryTotal: brokerageObj[scriptName]?.deliveryTotal || 0,
      deliveryBuy: brokerageObj[scriptName]?.deliveryBuy || 0,
      intradayBuy: brokerageObj[scriptName]?.intradayBuy || 0,
      intradayTotal: brokerageObj[scriptName]?.intradayTotal || 0,
      deliverySell: brokerageObj[scriptName]?.deliverySell || 0,
      intradaySell: brokerageObj[scriptName]?.intradaySell || 0,
      percentage: brokerageObj[scriptName]?.percentage || 0,
      brokerageType:
        isOwner || isRent
          ? brokerageObj?.[scriptName]?.brokerageType
          : parentDetails?.brokarageDetails?.[scriptName]?.brokerageType,
      remainingPercentage: brokerageObj[scriptName]?.remainingPercentage || 0,
    };
    const additionalSharing = additionalSharingObj[scriptName] || [];
    const margin = {
      type: marginObj[scriptName]?.type || "value",
      delivery: marginObj[scriptName]?.history?.[0]?.delivery || 0,
      intraday: marginObj[scriptName]?.history?.[0]?.intraday || 0,
      total: marginObj[scriptName]?.history?.[0]?.total || 0,
      interestRate: marginObj[scriptName]?.interestRate || 0,
    };
    const scriptWiseSettingInfo = {
      minimumScriptRate: scriptWiseObj[scriptName]?.minimumScriptRate || null,
      totalNoOfScriptAllowed:
        scriptWiseObj[scriptName]?.totalNoOfScripts || null,
      orderLimit: scriptWiseObj[scriptName]?.orderLimits || null,
      strikeRateMaxLot: scriptWiseObj[scriptName]?.strikeRateMaxLot || null,
      allowShortSell: scriptWiseObj[scriptName]?.allowShortSell || false,
      allowLimitOrder: scriptWiseObj[scriptName]?.allowLimitOrder || false,
      allowQuantityTrading:
        scriptWiseObj[scriptName]?.allowQuantityTrading || false,
      [name]: scriptWiseObj[scriptName]?.[name] || null,
    };
    const allScript = isOwner
      ? userInfo.scriptWiseSetting?.allScript?.[scriptName] || []
      : PreviousData?.[scriptName]?.allScript || [];
    const blockScript = isOwner
      ? userInfo.scriptWiseSetting?.blockScript?.[scriptName] || []
      : PreviousData?.[scriptName]?.blockScript || [];

    obj[scriptName] = {
      isOnlyPositionSquareup,
      brokerageDetails,
      additionalSharing,
      margin,
      scriptWiseSettingInfo,
      allScript,
      blockScript,
    };
  });

  return obj;
};

export default SetUserDetails;
