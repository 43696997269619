import React, { useEffect, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Form, Radio, Row, notification } from "antd";
import ThemeInput from "../../components/ThemeInput";
import styled from "styled-components";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import ThemeDropDown from "../../components/ThemeDropDown";
import { useSelector } from "react-redux";
import { allOption } from "../../Helper/constant";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import { ThemeForm, RemoveButton, SubmitButton } from "./style";
import { useWatch } from "antd/es/form/Form";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";

const initalState = {
  accountType: "",
  accountId: "",
  entryType: "deposit",
  type: "cash",
  date: "",
  point: "",
  remark: "",
};
function AccountPointEntry() {
  // state
  const [value, setValue] = useState(initalState);
  const [childOption, setChildOption] = useState({
    all: [],
    masterAdmin: [],
    superAdmin: [],
    admin: [],
    master: [],
    dealer: [],
    customer: [],
    broker: [],
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const formAccountType = useWatch("accountType", form);

  //api call
  const handleSubmit = async (payload) => {
    // for (let key in value) {
    //   if (value[key] === "" && key !== "remark") {
    //     return notification.error({ message: "Please fill all details" });
    //   }
    // }
    let obj = {
      ...payload,
      accountType: payload?.accountType?.[0],
      accountId: payload?.accountId?.[0],
      remark: payload?.remark || null,
      type: "cash",
    };
    setSubmitLoading(true);
    try {
      let response = await APICall("post", EndPoints.createLedger, obj);
      if (response.status === 200) {
        notification.success({ message: response.data?.message });
        form.resetFields();
        setValue(initalState);
      } else {
        notification.error({ message: response.data?.message });
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      console.log("error", error);
    }
  };
  const getChildUser = async () => {
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getChildUsers}${userDetail?.userId}&type=onlychild`
      );
      return response?.data?.data?.childUsers;
    } catch (error) {
      console.log("error", error);
    }
  };
  const {
    isLoading,
    error,
    data: childData,
  } = useQuery({ queryKey: ["getChildUser"], queryFn: getChildUser });

  // life cycle
  useEffect(() => {
    let obj = {
      all: [],
      masterAdmin: [],
      superAdmin: [],
      admin: [],
      master: [],
      dealer: [],
      customer: [],
      broker: [],
    };
    // console.log("child", childData);
    if (childData) {
      childData.map((el) => {
        obj[el?.accountType].push({
          label: `${el?.accountName} (${el?.userId})`,
          value: el?.userId,
        });
        obj.all.push({
          label: `${el?.accountName} (${el?.userId})`,
          value: el?.userId,
        });
      });
      setChildOption(obj);
      // console.log("obj", obj);
    }
  }, [childData]);

  // function
  const handleChange = (name, value, option) => {
    setValue((pre) => ({ ...pre, [name]: value?.[0] ? value[0] : null }));
  };

  const onChangeRadio = (e) => {
    const { value, name } = e.target;
    setValue((pre) => ({ ...pre, entryType: value }));
  };

  const handleDateChange = (date) => {
    setValue((pre) => ({ ...pre, date: date }));
  };

  const onChangeInput = ({ target: { name, value } }) => {
    setValue((pre) => ({ ...pre, [name]: value }));
  };

  const handleCancel = () => {
    // setValue(initalState);
    form.resetFields();
  };

  const handleFaild = (error) => {
    console.log("faild", error);
    notification.error({ message: error?.errorFields?.[0]?.errors?.[0] });
  };

  const handleFinish = (payload) => {
    console.log("finish", payload);
    // let payload = form.getFieldsValue();
    // console.log({ payload });
    handleSubmit(payload);
  };

  return (
    <>
      <ThemeCard title={"Account Point Entry"}>
        <ThemeForm
          name="basic"
          form={form}
          onFinishFailed={handleFaild}
          onFinish={handleFinish}
          autoComplete="off"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 6,
          }}
          style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {/* <Row justify={"center"} style={{ marginBottom: "2rem" }}>
            <Col span={24} md={7}> */}
          <ThemeDropDown
            name={"accountType"}
            placeholder="Select"
            // $value={value.accountType}
            // $onChange={handleChange}
            onChange={form.setFieldValue("accountId", null)}
            showSearch={true}
            options={allOption?.filter(
              (item) => item.authorityLevel > userDetail.authorityLevel
            )}
            disabled={isLoading}
            form_props={{
              name: "accountType",
              label: "Account Type",
              rules: [
                { required: true },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     if (value) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(new Error("Please add account type"));
                //   },
                // }),
              ],
            }}
          />
          {/* </Col>
          </Row> */}

          {/* <Row justify={"center"} style={{ marginBottom: "2rem" }}>
            <Col span={24} md={7}> */}
          <ThemeDropDown
            name={"accountId"}
            placeholder="Select"
            // $value={value.accountId}
            // value={value.accountId}
            // $onChange={handleChange}
            showSearch={true}
            options={childOption[formAccountType?.[0]]}
            required
            form_props={{
              name: "accountId",
              label: "Account Id",
              rules: [
                { required: true },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     if (value) {
                //       return Promise.resolve();
                //     } else {
                //       return Promise.reject("Please add account Id");
                //     }
                //   },
                // }),
              ],
            }}
          />
          {/* </Col>
          </Row> */}

          {/* <Row justify={"center"} gutter={10}>
            <Col span={24} md={7}> */}
          <Form.Item
            label="Type"
            name="entryType"
            initialValue={"deposit"}
            rules={[
              { required: true },
              // ({ getFieldValue }) => ({
              //   validator(_, value) {
              //     // value = getFieldValue("entryType");
              //     if (value) {
              //       return Promise.resolve();
              //     } else {
              //       return Promise.reject("Please add entry type");
              //     }
              //   },
              // }),
            ]}
          >
            <Radio.Group
              // value={value?.entryType}
              // onChange={onChangeRadio}
              name="entryType"
              style={{ marginLeft: "1rem" }}
            >
              <Radio value={"deposit"}>deposit</Radio>
              <Radio value={"withdrow"}>Withdraw</Radio>
            </Radio.Group>
          </Form.Item>
          {/* </Col>
          </Row> */}

          {/* <Row justify={"center"} gutter={[10, 10]}>
            <Col span={24} md={7}> */}
          <ThemeDatePicker
            name="date"
            width={"100%"}
            // value={value.date}
            // onChange={handleDateChange}
            formProps={{
              name: "date",
              label: "Date",
              rules: [
                { required: true },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     // value = getFieldValue("date");
                //     if (value) {
                //       return Promise.resolve();
                //     } else {
                //       return Promise.reject("Please add Date");
                //     }
                //   },
                // }),
              ],
            }}
          />
          {/* </Col>
          </Row> */}

          {/* <Row
            justify={"center"}
            gutter={[10, 10]}
            style={{ marginTop: "1.5rem" }}
          >
            <Col span={24} md={7}> */}
          <ThemeInputNumber
            extraProps={{
              inputType: "number",
            }}
            marginBottom={"0rem"}
            inputProps={{
              name: "point",
              step: 1,
              // value: value.point,
              // onChange: onChangeInput,
            }}
            // errorheight="1rem"
            formProps={{
              name: "point",
              label: "Point",
              rules: [
                { required: true },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     // value = getFieldValue("date");
                //     if (value) {
                //       return Promise.resolve();
                //     } else {
                //       return Promise.reject("Please add Point");
                //     }
                //   },
                // }),
              ],
            }}
          />
          {/* </Col>
          </Row> */}

          {/* <Row justify={"center"} gutter={10}>
            <Col span={24} md={7}> */}
          <ThemeInput
            // marginBottom={"0rem"}
            inputProps={{
              name: "remark",
              // value: value.remark,
              // onChange: onChangeInput,
            }}
            formProps={{
              name: "remark",
              label: "Remark",
              rules: [
                // { required: true },
                // {
                //   validator: (_, value) => {
                //     if (value && value.length > 5) {
                //       return Promise.resolve();
                //     } else {
                //       return Promise.reject(new Error("add number"));
                //     }
                //   },
                // },
              ],
            }}
          />
          {/* </Col>
          </Row> */}

          <Row
            justify={"center"}
            style={{ marginTop: "2rem" }}
            gutter={[10, 20]}
          >
            <Col span={24} md={3}>
              <SubmitButton
                // onClick={handleSubmit}
                // style={style.submitBtn}
                loading={submitLoading}
                type="primary"
                htmlType="submit"
              >
                Submit
              </SubmitButton>
            </Col>
            <Col span={24} md={3}>
              <RemoveButton type="primary" onClick={handleCancel}>
                Cancel
              </RemoveButton>
            </Col>
          </Row>
        </ThemeForm>
      </ThemeCard>
    </>
  );
}

export default AccountPointEntry;
