import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Typography,
  notification,
  theme,
} from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import APICall from "../../APICall";
import { useState } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import {
  DeleteOutlined,
  EditOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import EndPoints from "../../APICall/EndPoints";
import {
  MemoizedCardCurrentPrice,
  limitNumberLength,
} from "../../components/SocketLiveData";
import ActionButton from "../../components/ActionButton";
import useColorChange from "../../hooks/useColorChange";
import ThemeCard from "../../components/ThemeCard";
import ThemeInput from "../../components/ThemeInput";
import { valanBillDate } from "../SummaryReport/ReportCart";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  findMarket,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { Link } from "react-router-dom";
import store from "../../store/store";
import { useSocket } from "../../components/Socket";
import dayjs from "dayjs";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import { options } from "../../Helper/constant";
import "./style.css";

const styleTheme = {
  AllowButton: styled(Button)`
    border-radius: ${({ theme }) => theme.token.borderRadiusLG};
    font-size: 1rem;
    height: 3rem;
    color: white;
    /* width: unset !important; */
    background-color: ${({ theme }) => theme.token.colorPrimary};
    /* margin-right: 20px; */
    @media (max-width: 1079px) {
      height: 2rem;
      font-size: 0.8rem;
    }
  `,
};
const StyledDiv = styled.div`
  text-align: left;
`;
const StyledOverFlowDiv = styled.div`
  text-align: left;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ModalText = styled(Typography.Text)`
  color: red;
`;
const istrue = ["COMEX", "FOREX", "GLOBEX"];
const currentPrice = (record) => {
  let key = record?.tradePattern === "sell" ? "Ask" : "Bid";
  return key;
};
function DashboardScreen() {
  const dispatch = useDispatch();
  // redux
  const accountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isCustomer = accountType === "customer";
  const isBroker = accountType === "broker";

  return (
    <div>
      <Row gutter={[10, 10]}>
        <LetestTradeTable />
        <PendingTradeTable />
      </Row>
      <Row>
        <RejectionLogsTable />
      </Row>

      {!isBroker && !isCustomer && (
        <>
          <Row gutter={[10, 10]}>
            <M2MAlertTable />
            <AutoSqureTable />
          </Row>
          <Row>
            <AlertTable />
          </Row>
        </>
      )}
    </div>
  );
}

export default DashboardScreen;

export const ModalCellView = React.memo(
  ({
    name = "",
    title,
    currentValue,
    checkUpdates = false,
    hideValue = false,
  }) => {
    const token = theme.useToken().token;
    const { color, backgroundColor } = useColorChange({
      currentValue: checkUpdates ? currentValue : null,
      defaultBgColor: token.colorBgBase,
      defaultColor: token.colorTextLabel,
    });

    return (
      <Row
        style={{
          backgroundColor,
        }}
      >
        <div className="cell-title" style={{ color }}>
          {title}
        </div>
        {hideValue ? null : (
          <div className="cell-number" style={{ color }}>
            {currentValue || "~"}
          </div>
        )}
      </Row>
    );
  }
);

const LetestTradeTable = () => {
  const queryClient = useQueryClient();

  //state
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState(null);
  const tradeRef = useRef(null);

  //redux
  const LoginDetails = useSelector((state) => state?.authSlice?.userDetail);
  const addition = useSelector((state) => state?.authSlice?.additionalSharing);

  const loginUserId = LoginDetails?.userId;
  const loginAccountType = LoginDetails?.accountType;
  const isOwner = loginAccountType === "owner";

  useEffect(() => {
    queryClient.refetchQueries(["allTrade"]);
  }, []);

  // api call
  const fetchInitialData = async ({ pageParam = 1 }) => {
    let obj = { userId: loginUserId, tradeStatus: "executed", title: filter };

    const response = await APICall(
      "post",
      `${EndPoints.getTrade}?page=${pageParam}&limit=10&isLatest=true`,
      obj
    );

    return response?.data?.data;
  };

  const tradeQuery = useInfiniteQuery({
    queryKey: ["allTrade", filter],
    queryFn: fetchInitialData,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  const newPageData =
    tradeQuery?.data?.pages?.flatMap((page) => page && page?.trades) || [];

  const deleteTrade = async (record, index, key) => {
    const id = record?._id;
    const customerId = record?.customerId?._id;
    let obj = {
      deviceType: "web",
      tradeIpAddress: record?.tradeIpAddress,
    };

    try {
      let response = await APICall(
        "delete",
        `${EndPoints.deleteTrade}${id}`,
        obj
      );
      // console.log("🚀 ~ deleteTrade ~ response:", response);
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        queryClient.resetQueries([key]);
        queryClient.resetQueries(["getWatchList"]);
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.log("error");
    }
  };

  // function
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleEdit = (record, key) => {
    setShowModal(true);
    tradeRef.current = { ...record, key };
  };
  const filterOption = options.trading.orderType?.map((el) => ({
    ...el,
    text: el?.label,
  }));

  // column
  const latestTradeColumns = useMemo(
    () => [
      tradeQuery?.isLoading
        ? {
            title: "TIME",
            dataIndex: "tradeTime",
            key: "tradeTime",
            width: "5rem",
            align: "left",
          }
        : {
            title: "TIME",
            dataIndex: "tradeTime",
            key: "tradeTime",
            width: "5rem",
            align: "left",
            render: (text, record) => {
              const time = onlyTime(record?.tradeTime);
              let executeTime = "";
              if (record?.title === "limit") {
                const excute = onlyTime(record?.executeTime);
                executeTime = excute;
              }
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {executeTime && (
                    <div>{executeTime ? `(${executeTime})` : ""}</div>
                  )}
                  <div> {time}</div>
                </div>
              );
            },
          },
      {
        title: "CLIENT",
        dataIndex: "customerName",
        key: "customerName",
        width: "2rem",
        align: "left",
        ellipsis: true,
        render: (text, record) => {
          let id = record?.customer[0]?.userId;
          return <>{`${id} (${record?.customer[0]?.accountName})`}</>;
        },
      },
      {
        title: "SCRIPT",
        dataIndex: "scriptName",
        key: "scriptName",
        width: "8rem",
        align: "left",
        render: (text, record) => {
          const date = record?.expiryDate ? record?.expiryDate : "";
          // const date = valanBillDate(record?.expiryDate, false);
          const opt = record?.marketName?.endsWith("OPT");
          const name = opt
            ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
                record?.strikePrice ? record?.strikePrice : ""
              }`
            : record?.symbolName;
          return (
            <StyledDiv>
              {name} {date}
            </StyledDiv>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "ordertype",
        key: "ordertype",
        width: "4rem",
        align: "left",
        filters: filterOption,
        filterMultiple: false,
        className: "table-filter",
        render: (text, record) => {
          return <div>{record?.title}</div>;
        },
      },
      {
        title: "B/S",
        dataIndex: "tradePattern",
        key: "buy/sell",
        width: "3rem",
        align: "left",
      },
      {
        title: "QTY (LOT)",
        dataIndex: "lot",
        key: "lot",
        width: "7rem",
        align: "left",
        render: (text, record) => {
          const lot = formatNumber(record?.lot);
          const quantity = formatNumber(record?.quantity);
          const data =
            record?.marketName === "NSE EQU"
              ? quantity
              : `${quantity} (${lot})`;
          return <StyledDiv>{data}</StyledDiv>;
        },
      },
      {
        title: "RATE",
        dataIndex: "tradePrice",
        key: "rate",
        width: "5rem",
        align: "center",
        render: (text, record) => {
          const tradePrice = findMarket(record?.marketName)
            ? limitNumberLength(record?.tradePrice)
            : record?.tradePrice?.toFixed(2);
          return <div>{tradePrice}</div>;
        },
      },
      loginAccountType !== "customer" &&
      (addition?.editTrade || addition?.tradeDelete)
        ? {
            title: "ACTION",
            dataIndex: "edit",
            key: "edit",
            width: "5rem",
            align: "center",
            render: (text, record, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "0.6rem",
                  }}
                >
                  {addition?.editTrade &&
                    (record?.deviceType !== "system" || isOwner ? (
                      <EditOutlined
                        onClick={() => handleEdit(record, "allTrade")}
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: theme.colorPrimary1,
                        }}
                      />
                    ) : (
                      "-"
                    ))}

                  {addition?.tradeDelete &&
                    (record?.deviceType !== "system" || isOwner ? (
                      <Popconfirm
                        title="delete the trade"
                        description={`Are you sure to delete ${record?.tradePattern} ${record?.symbolName} ${record?.quantity} (${record?.lot}) @ ${record?.tradePrice}   ?`}
                        onConfirm={() => deleteTrade(record, index, "allTrade")}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined
                          style={{
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            color: "#FF474D",
                          }}
                        />
                      </Popconfirm>
                    ) : (
                      "-"
                    ))}
                </div>
              );
            },
          }
        : {
            width: "0px",
          },
    ],
    [deleteTrade, handleEdit, newPageData]
  );

  const handleChange = (pagination, filters, sorter) => {
    if (filters?.ordertype?.[0]) {
      setFilter(filters?.ordertype?.[0]);
    } else {
      setFilter(null);
    }
  };

  return (
    <Col xxl={12} xl={12} lg={24}>
      <DashboardCommonTable
        columns={latestTradeColumns}
        tableTitle="LATEST TRADES"
        viewMore={true}
        allowBtn={false}
        dataSource={newPageData?.[0] ? newPageData : []}
        isLoading={tradeQuery?.isLoading}
        // isLoading={objserver?.currentResult?.isFetchingNextPage}
        queryKey="allTrade"
        tradeQuery={tradeQuery}
        fetchNextPage={tradeQuery?.fetchNextPage}
        nextPage={tradeQuery?.hasNextPage}
        onChange={handleChange}
      />

      {showModal && (
        <EditTrade handleCancel={handleCancel} record={tradeRef.current} />
      )}
    </Col>
  );
};

const PendingTradeTable = () => {
  const queryClient = useQueryClient();

  //state
  const [showModal, setShowModal] = useState(false);
  const [symbol, setSymbol] = useState([]);
  const socketContext = useSocket();
  const { doSend } = socketContext;
  const tradeRef = useRef(null);

  //redux
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );

  //life cycle
  useEffect(() => {
    queryClient.refetchQueries(["pendingTrade"]);

    return () => {
      if (symbol?.length > 0) {
        doSend({
          event: "unsubscribeToServerMarket",
          payload: symbol,
        });
      }
    };
  }, []);

  // api call
  const pendingQuery = async ({ pageParam = 1 }) => {
    let obj = { userId: loginUserId, tradeStatus: "pending" };
    let response = await APICall(
      "post",
      `${EndPoints.getTrade}?page=${pageParam}&limit=10&isLatest=true`,
      obj
    );
    return response?.data?.data;
  };

  const pendingTrade = useInfiniteQuery({
    queryKey: ["pendingTrade"],
    queryFn: pendingQuery,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  const pendingTradeData = pendingTrade?.data?.pages?.flatMap(
    (page) => page?.trades
  );

  useEffect(() => {
    if (pendingTrade.data?.pages && !pendingTrade?.isLoading) {
      const scriptList = pendingTradeData?.map((el) => el?.symbol) || 0;
      const finalList = [...new Set(scriptList)];
      setSymbol(finalList);
      if (finalList?.length > 0) {
        doSend({
          event: "subscribeToServerMarket",
          payload: finalList,
        });
      }
    }
  }, [pendingTrade?.isLoading]);

  const deleteTrade = async (record, index, key) => {
    const id = record?._id;
    // const customerId = record?.customer?.[0]?._id;
    let obj = {
      deviceType: "web",
      tradeIpAddress: record?.tradeIpAddress,
    };

    try {
      let response = await APICall(
        "delete",
        `${EndPoints.deleteTrade}${id}`,
        obj
      );
      // console.log("🚀 ~ deleteTrade ~ response:", response);
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        queryClient.resetQueries([key]);
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleExecute = async (record) => {
    const id = record?._id;
    const customerId = record?.customer[0]?.userId;
    const valueType = currentPrice(record);
    const price =
      store.getState()?.webSocketSlice?.webSocket?.data?.[record?.symbol]?.[
        valueType
      ];

    const obj = { tradeStatus: "executed", tradePrice: price };

    try {
      let response = await APICall(
        "patch",
        `${EndPoints.editTrade}${id}&customerId=${customerId}`,
        obj
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        queryClient.resetQueries();
      } else {
        notification.error({ message: response?.data?.message });
      }
      // console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  // function
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleEdit = (record, key) => {
    setShowModal(true);
    tradeRef.current = { ...record, key };
  };

  // column
  const pendingTradeColumns = useMemo(
    () => [
      {
        title: "TIME",
        dataIndex: "time",
        key: "time",
        width: "5rem",
        align: "left",
        render: (text, record) => {
          const time = onlyTime(record?.tradeTime);
          return <span>{time}</span>;
        },
      },
      {
        title: "CLIENT",
        dataIndex: "client",
        width: "8rem",
        key: "client",
        ellipsis: true,
        align: "left",
        render: (text, record) => {
          let id = record?.customer[0]?.userId;
          return (
            <StyledOverFlowDiv>
              {`${id} (${record?.customer[0]?.accountName})`}
            </StyledOverFlowDiv>
          );
        },
      },
      {
        title: "SCRIPT",
        dataIndex: "symbol",
        key: "scriptName",
        width: "8rem",
        align: "left",
        render: (text, record) => {
          const date = record?.expiryDate ? record?.expiryDate : "";
          // const date = valanBillDate(record?.expiryDate, false);
          const opt = record?.marketName?.endsWith("OPT");
          const name = opt
            ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
                record?.strikePrice ? record?.strikePrice : ""
              }`
            : record?.symbolName;
          return (
            <StyledDiv>
              {name} {date}
            </StyledDiv>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "tradePattern",
        key: "tradePattern",
        width: "4rem",
        align: "left",
        render: (text, record) => {
          return <span>{record?.title}</span>;
        },
      },
      {
        title: "B/S",
        dataIndex: "tradePattern",
        key: "buy/sell",
        width: "3rem",
        align: "left",
      },
      {
        title: "QTY (LOT)",
        dataIndex: "lot",
        key: "lot",
        width: "7rem",
        align: "left",
        render: (text, record) => {
          const lot = formatNumber(record?.lot);
          // const intValue = lot?.toFixed(2).replace(/\.?0+$/, "");
          const quantity = formatNumber(record?.quantity);
          // const lot = parseFloat(record?.lot);
          // const intValue = isNaN(lot)
          //   ? ""
          //   : lot?.toFixed(2).replace(/\.?0+$/, "");
          // const quantity = parseFloat(record?.quantity);
          // const intValuequantity = isNaN(quantity)
          //   ? ""
          //   : parseInt(quantity, 10);
          const data =
            record?.marketName === "NSE EQU"
              ? quantity
              : `${quantity} (${lot})`;
          return <StyledDiv>{data}</StyledDiv>;
        },
      },
      {
        title: "RATE",
        dataIndex: "tradePrice",
        key: "tradePrice",
        width: "5rem",
        align: "center",
        render: (text, record) => {
          const tradePrice = findMarket(record?.marketName)
            ? limitNumberLength(record?.tradePrice)
            : record?.tradePrice?.toFixed(2);
          return <div>{tradePrice}</div>;
        },
      },
      {
        title: "Live",
        dataIndex: "live",
        key: "live",
        align: "center",
        width: "8rem",
        render: (text, record) => {
          return (
            <>
              <MemoizedCardCurrentPrice
                item={record}
                priceType={currentPrice(record)}
              />
            </>
          );
        },
      },
      {
        title: "ACTION",
        dataIndex: "edit",
        key: "edit",
        // width: "12rem",
        width: "100px",
        align: "center",
        render: (text, record, index) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <EditOutlined
                onClick={() => handleEdit(record, "pendingTrade")}
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                  color: theme.colorPrimary1,
                }}
              /> */}
              <Popconfirm
                title="delete the trade"
                description={`Are you sure to delete ${record?.tradePattern} ${record?.symbolName} ${record?.quantity} (${record?.lot}) @ ${record?.tradePrice}   ?`}
                onConfirm={() => deleteTrade(record, index, "pendingTrade")}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                    color: "#FF474D",
                  }}
                />
              </Popconfirm>
              {/* )} */}
              <Popconfirm
                title="Execute the trade"
                description="Are you sure to execute this trade?"
                onConfirm={() => handleExecute(record)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <RetweetOutlined
                  style={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                    color: "green",
                  }}
                />
              </Popconfirm>
            </div>
          );
        },
      },
    ],
    [handleExecute, deleteTrade, pendingTradeData]
  );

  return (
    <Col xxl={12} xl={12} lg={24}>
      <DashboardCommonTable
        columns={pendingTradeColumns}
        tableTitle="PENDING TRADES"
        dataSource={pendingTradeData?.[0] ? pendingTradeData : []}
        viewMore={true}
        allowBtn={false}
        queryKey="pendingTrade"
        isLoading={pendingTrade?.isLoading}
        tradeQuery={pendingTrade}
        nextPage={pendingTrade?.hasNextPage}
      />
      {showModal && (
        <EditTrade handleCancel={handleCancel} record={tradeRef.current} />
      )}
    </Col>
  );
};

const RejectionLogsTable = () => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState({
    flag: false,
    index: null,
  });
  const dispatch = useDispatch();

  //redux
  const loginAccountType = useSelector(
    (state) => state?.authSlice?.userDetail?.accountType
  );
  const canCall = useSelector(
    (state) => state?.globleSlice?.callAPI?.rejectionLog
  );
  const addition = useSelector((state) => state?.authSlice?.additionalSharing);

  // life cycle
  useEffect(() => {
    queryClient.refetchQueries(["rejectionLogs"]);
  }, []);

  // api call
  const getRejectionLog = async ({ pageParam = 1 }) => {
    let obj = {
      query: {
        childRejectionLogs: {
          page: pageParam,
          limit: 10,
        },
      },
    };

    let response = await APICall(
      "post",
      `${EndPoints.getTradeLog}?isLatest=true`,
      obj
    );
    // console.log("rejectionLogs", response?.data?.data);
    return response?.data?.data;
  };

  const tradeQuery = useInfiniteQuery({
    queryKey: ["rejectionLogs"],
    queryFn: getRejectionLog,
    getNextPageParam: (lastPage) =>
      lastPage?.childRejectionLogsPagination?.nextPage,
  });

  const totalData = tradeQuery?.data?.pages?.flatMap(
    (el) => el?.childRejectionLogs
  );

  //function
  const handleExecute = async (record, index) => {
    setLoading({ flag: true, index: index });
    try {
      let response = await APICall(
        "get",
        `${EndPoints.exucuteRejectedTrade}${record?._id}`
      );
      if (response?.status === 200) {
        notification.success({ message: response.data?.message });
        queryClient.refetchQueries(["rejectionLogs"]);
        // dispatch(setApiCall({ key: "rejectionLog", value: true }));
        // queryClient.resetQueries(["rejectionLog"]);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading({ flag: false, index: null });
    }
  };

  // column
  const rejectionColumn = [
    {
      title: "TIME",
      dataIndex: "client",
      key: "time",
      align: "left",
      width: "4rem",
      render: (text, record) => {
        const time = onlyTime(record?.tradeTime);
        return <span>{time}</span>;
      },
    },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "8rem",
      ellipsis: true,
      render: (text, record) => {
        return (
          <StyledOverFlowDiv
            title={`${record?.client?.userId} (${record?.client?.accountName})`}
          >{`${record?.client?.userId} (${record?.client?.accountName})`}</StyledOverFlowDiv>
        );
      },
    },
    {
      title: "SCRIPT",
      dataIndex: "symbolName",
      key: "Script",
      width: "14rem",
      align: "left",
      render: (text, record) => {
        const date = valanBillDate(record?.expiryDate, false);
        // const date = "";
        // formatDate(record?.expiryDate);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          <StyledDiv>
            {name} {date}
          </StyledDiv>
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "ordertype",
      key: "ordertype",
      width: "8rem",
      align: "left",
      render: (text, record) => {
        return <div>{record?.title ? record?.title : "-"}</div>;
      },
    },
    {
      title: "B/S",
      dataIndex: "tradePattern",
      key: "buy/sell",
      width: "4rem",
      align: "left",
    },
    {
      title: "QTY (LOT)",
      dataIndex: "lot",
      key: "lot",
      width: "10rem",
      align: "left",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        // const intValue = lot?.toFixed(2).replace(/\.?0+$/, "");
        const quantity = formatNumber(record?.quantity);
        // const lot = parseFloat(record?.lot);
        // const intValue = isNaN(lot)
        //   ? ""
        //   : lot?.toFixed(2).replace(/\.?0+$/, "");
        // const quantity = parseFloat(record?.quantity);
        // const intValuequantity = isNaN(quantity) ? "" : parseInt(quantity, 10);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return <div>{data}</div>;
      },
    },
    {
      title: "ORDER PRICE",
      dataIndex: "tradePrice",
      key: "orderPrice",
      width: "8rem",
      align: "left",
      render: (text, record) => {
        const tradePrice = findMarket(record?.marketName)
          ? limitNumberLength(record?.tradePrice)
          : record?.tradePrice?.toFixed(2);
        return <span>{tradePrice}</span>;
      },
    },
    {
      title: "CODE : REJECTION REASON",
      dataIndex: "message",
      key: "message",
      align: "left",
      width: "25rem",
      render: (text, record) => {
        const code = `${record?.message?.code} : ${record?.message?.text}`;
        return <span>{code}</span>;
      },
    },
    {
      title: "USER IP",
      dataIndex: "tradeIpAddress",
      key: "tradeIpAddress",
      align: "left",
      width: "15rem",
      render: (text, record) => {
        return <StyledDiv>{record?.tradeIpAddress}</StyledDiv>;
      },
    },
    loginAccountType !== "customer" && addition?.acceptRejectedTrade
      ? {
          title: "ACTION",
          dataIndex: "action",
          key: "action",
          width: "100px",
          align: "center",
          render: (text, record, index) => {
            return (
              <Popconfirm
                title="Allow the trade"
                description="Are you sure to allow this trade?"
                onConfirm={() => handleExecute(record, index)}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <styleTheme.AllowButton
                // loading={loading.flag && loading.index === index}
                // onClick={() => handleExecute(record, index)}
                >
                  Allow
                </styleTheme.AllowButton>
              </Popconfirm>
            );
          },
        }
      : { width: "0rem" },
  ];

  return (
    <Col span={24}>
      <DashboardCommonTable
        columns={rejectionColumn}
        dataSource={totalData?.[0] ? totalData : []}
        isLoading={tradeQuery.isLoading}
        tableTitle="REJECTION LOGS"
        queryKey="rejectionLogs"
        viewMore={true}
        tradeQuery={tradeQuery}
        // allowBtn={true}
      />
    </Col>
  );
};

const M2MAlertTable = () => {
  const queryCilent = useQueryClient();

  // life cycle
  useEffect(() => {
    queryCilent.refetchQueries(["m2mAlerts"]);
  }, []);

  // api call
  const getM2mAlert = async ({ pageParam = 1 }) => {
    if (!pageParam) {
      return;
    }

    let response = await APICall(
      "post",
      `${EndPoints.getM2MAlert}&page=${pageParam}&limit=10&isLatest=true`
    );
    // console.log("M2m response", response?.data);
    return response?.data?.data;
  };

  const alertQuery = useInfiniteQuery({
    queryKey: ["m2mAlerts"],
    queryFn: getM2mAlert,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    // enabled: canCall,
  });

  const allData = alertQuery?.data?.pages?.flatMap((el) => el?.alerts) || [];

  // column
  const m2mAlertColumns = [
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: "5rem",
      align: "left",
      render: (text, record) => {
        const time = onlyTime(record?.alertTime);
        return <span>{time}</span>;
      },
    },
    {
      title: "CLIENT",
      dataIndex: "d",
      key: "d",
      align: "left",
      width: "5rem",
      ellipsis: true,
      render: (text, record) => {
        return (
          <StyledOverFlowDiv
            title={`${record?.data?.userName}(${record?.clientId})`}
          >
            {`${record?.data?.userName}(${record?.clientId})`}
          </StyledOverFlowDiv>
        );
      },
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      align: "left",
      width: "25rem",
      render: (text, record) => {
        return <StyledDiv>{record?.data?.message}</StyledDiv>;
      },
    },
    {
      title: "M2M",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "5rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.amount)}</span>;
      },
    },
    {
      title: "LEDGER + M2M",
      dataIndex: "lot",
      key: "lot",
      align: "center",
      width: "5rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.balance)}</span>;
      },
    },
  ];
  // console.log("alldata", allData);
  return (
    <Col xl={12} lg={24}>
      <DashboardCommonTable
        columns={m2mAlertColumns}
        dataSource={allData?.[0] ? allData : []}
        tableTitle="M2M ALERTS"
        queryKey="m2mAlerts"
        isLoading={alertQuery?.isLoading}
        tradeQuery={alertQuery}
        viewMore={true}
        allowBtn={false}
      />
    </Col>
  );
};

const AutoSqureTable = () => {
  //state
  const [showModal, setShowModal] = useState({ flag: false, data: null });
  const queryCilent = useQueryClient();

  // life cycle
  useEffect(() => {
    queryCilent.refetchQueries(["autoSqure"]);
  }, []);

  // api call
  const getAutoSqureData = async ({ pageParam = 1 }) => {
    let response = await APICall(
      "post",
      `${EndPoints.getM2MAlert}&type=squareup&page=${pageParam}&limit=10&isLatest=true`
    );
    // console.log("response auto", response);
    return response?.data?.data;
  };

  const autoSqureQuery = useInfiniteQuery({
    queryKey: ["autoSqure"],
    queryFn: getAutoSqureData,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  // console.log("autoQuery", autoSqureQuery);
  const allData = autoSqureQuery?.data?.pages?.flatMap((el) => el?.alerts);

  // function
  const handleAction = (record) => {
    setShowModal({ flag: true, data: record });
  };

  // column
  const autoSquareColumns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        const time = onlyTime(record?.alertTime);
        return <div>{time}</div>;
      },
    },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "20rem",
      ellipsis: true,
      render: (text, record) => {
        return (
          <StyledOverFlowDiv
            title={`${record?.data?.userName}(${record?.clientId})`}
          >
            {`${record?.data?.userName}(${record?.clientId})`}
          </StyledOverFlowDiv>
        );
      },
    },
    {
      title: "M2M",
      dataIndex: "m2m",
      key: "m2m",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.amount)}</span>;
      },
    },
    {
      title: "LEDGER + M2M",
      dataIndex: "lot",
      key: "lot",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.balance)}</span>;
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => handleAction(record)}>
            Restore
          </Button>
        );
      },
    },
  ];

  return (
    <Col xl={12} lg={24}>
      <DashboardCommonTable
        columns={autoSquareColumns}
        tableTitle="AUTO SQUARE OFF LIST"
        dataSource={allData?.[0] ? allData : []}
        tradeQuery={autoSqureQuery}
        queryKey="autoSqure"
        isLoading={autoSqureQuery?.isLoading}
        viewMore={true}
        allowBtn={true}
      />
      {showModal?.flag && (
        <RestoreModal data={showModal?.data} setShowModal={setShowModal} />
      )}
    </Col>
  );
};

const AlertTable = () => {
  const m2mSummaryColumns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      width: "5rem",
    },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "center",
      width: "10rem",
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      align: "center",
      width: "20rem",
    },
  ];

  return (
    <Col span={24}>
      <DashboardCommonTable
        columns={m2mSummaryColumns}
        tableTitle="ALERT"
        viewMore={true}
        allowBtn={true}
      />
    </Col>
  );
};

export const EditTrade = ({ handleCancel, record }) => {
  const queryCliant = useQueryClient();

  // state
  const [data, setData] = useState({
    lot: record?.lot,
    qty: record?.quantity,
    price: record?.tradePrice,
  });
  const [loading, setLoading] = useState(false);

  // api call
  const handleSubmit = async () => {
    let id = record?._id;

    // let customerId = record?.customerId?._id;

    let obj = {
      // id: record?.customerId?._id,
      lot: data.lot,
      quantity: data.qty,
      tradePrice: Number(data.price),
    };
    // console.log("editedTrade", obj);
    setLoading(true);
    try {
      let response = await APICall("patch", `${EndPoints.editTrade}${id}`, obj);
      // console.log("response", response);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        // queryCliant.resetQueries([record?.key, "getWatchList"]);
        queryCliant.resetQueries({ queryKey: [record?.key, "getWatchList"] });
        queryCliant.refetchQueries(["tradingTrade"]);
      } else {
        notification.error({ message: response?.data?.message });
      }
      setLoading(false);
      handleCancel();
    } catch (error) {
      setLoading(false);
      notification.error({ message: error?.message });
      console.log("error", error);
    }
  };

  // function
  const onChangeValue = ({ target: { name, value } }) => {
    if (name === "price") {
      const regex = /^\d*\.?\d{0,2}$/;
      const valid = istrue.includes(record?.marketName);
      // console.log("market", record?.marketName);
      if ((regex.test(value) || value === "") && !valid) {
        const decimal = value.split(".")[1]?.toString();
        if (decimal?.length == 2) {
          const secondDecimalDigit = decimal[1];
          if (
            secondDecimalDigit === "0" ||
            secondDecimalDigit === "5" ||
            record?.marketName === "NSE EQU"
          ) {
            setData({ ...data, [name]: value });
          }
        } else if (!decimal || decimal?.length === 1) {
          setData({ ...data, [name]: value });
        }
      }
      if (valid) {
        setData({ ...data, [name]: value });
      }
    } else if (name === "qty") {
      let lotValue = (value || 1) / record?.lotSize;
      setData({ ...data, [name]: value, lot: lotValue });
    } else if (name === "lot") {
      let qtyValue = record?.lotSize * (value || 1);
      setData({ ...data, [name]: value, qty: qtyValue });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  return (
    <Modal
      open={true}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <Row justify={"left"} style={{ marginTop: "1rem" }}>
        <Col span={12}>
          <ModalCellView
            title={`${record?.symbolName} ${record?.strikePrice || ""} ${
              record?.option_type || ""
            }  ${
              record?.expiryDate
                ? dayjs(record?.expiryDate)?.format("DD MMM YYYY") || ""
                : ""
            }`}
            hideValue
          />
        </Col>

        <Col span={10}>
          <ModalCellView
            title={`${record?.customer[0]?.accountName} (${record?.customer[0]?.userId})`}
            hideValue
          />
        </Col>
      </Row>
      <ThemeCard bordered={false} style={{ margin: "1rem 0rem" }}>
        <Row justify={"space-around"} gutter={[30, 0]}>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Price"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "Price",
                name: "price",
                value: data?.price,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
          {record?.marketName !== "NSE EQU" && (
            <Col span={8}>
              <ThemeInputNumber
                marginBottom={"0rem"}
                width={"100%"}
                title="LOT"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  placeholder: "LOT",
                  name: "lot",
                  value: data?.lot,
                  onChange: onChangeValue,
                  // onBlur: onChangeValue,
                  disabled: record?.marketName === "NSE EQU" ? true : false,
                }}
              />
            </Col>
          )}
          <Col span={8}>
            <ThemeInputNumber
              marginBottom={"0rem"}
              width={"100%"}
              title="QTY"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "QTY",
                name: "qty",
                value: data?.qty,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: "2rem" }}>
          <ActionButton
            title={"Submit"}
            onClick={handleSubmit}
            loading={loading}
          />

          <ActionButton
            title={"Cancel"}
            buttonType="cancel"
            onClick={handleCancel}
          />
        </Row>
      </ThemeCard>
    </Modal>
  );
};

const RestoreModal = ({ data, setShowModal }) => {
  // state
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState("");
  const queryClient = useQueryClient();

  // life cycle
  useEffect(() => {
    if (!isOpen) {
      setIsOpen(true);
    }
  }, []);

  // api
  const revertAutoSquare = async () => {
    try {
      let response = await APICall(
        "delete",
        `${EndPoints.restoreAutoSquareOff}${data?._id}`
      );
      if (response.status === 200) {
        console.log("response", response?.data);
        notification.success({ message: response?.data?.message });
        setShowModal({ flag: false });
        queryClient.resetQueries(["autoSqure"]);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let obj = { password: pass };
    try {
      let response = await APICall("post", EndPoints.passwordVerification, obj);
      if (response?.status === 200) {
        // console.log("passward response", response?.data);
        revertAutoSquare();
        // notification.success({ message: response?.data?.message });
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  // function
  const onChange = (e) => {
    setPass(e.target.value);
  };
  const handleCancel = () => {
    setIsOpen(false);
    setShowModal({ flag: false });
  };
  return (
    <>
      <Modal
        open={isOpen}
        footer={false}
        onCancel={handleCancel}
        title={<div>Restore Auto SquareOff</div>}
        width={400}
      >
        <Row>
          <ModalText>• Update M2M limit of customer</ModalText>
          <ModalText>• Disable only position squerUp</ModalText>
          <Typography.Text style={{ marginTop: "0.5rem" }}>
            To update account {`${data?.data?.userName}(${data?.clientId})`}
            <Link
              style={{ marginLeft: "0.5rem" }}
              target="_blank"
              to={`/users/edit-account/${data?.clientId}`}
            >
              Click here
            </Link>
          </Typography.Text>
        </Row>
        <Row align={"left"} style={{ marginTop: "1rem" }}>
          <Col span={24}>
            <ThemeInput
              title="Passward"
              inputProps={{
                name: "passward",
                placeholder: "Enter Login Password",
                $value: pass,
                onChange: onChange,
              }}
            />
          </Col>
        </Row>
        <Row align={"center"} gutter={[30]}>
          <Col>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
          <Col>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty or undefined input
  const parts = dateString.split("-");

  if (parts.length !== 3) return dateString; // Handle unexpected input format

  const day = parts[0];
  const monthIndex = parseInt(parts[1], 10); // Parse as integer
  const year = parts[2];

  if (isNaN(monthIndex) || monthIndex < 1 || monthIndex > 12) return dateString; // Handle invalid month

  const monthAbbreviations = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const abbreviatedMonth = monthAbbreviations[monthIndex - 1]; // Adjust for zero-based index

  return `${day}${abbreviatedMonth}${year}`;
};

const onlyTime = (data) => {
  if (!data) {
    return;
  }
  const date = new Date(data);
  const formattedTime = date.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
    hour12: false,
  });
  return `${formattedTime}`;
};
