import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  border-radius: ${(props) => props.theme.token?.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme.token?.colorBgActionButton};
  box-shadow: ${(props) => props.theme.token?.boxShadowNone};
  margin-right: 2rem;
  height: 3rem;
  width: 100%;
`;
export const StyledDiv = styled.div`
  text-align: left;
`;
