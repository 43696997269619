import React from "react";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Form, Row } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import styled from "styled-components";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import { useMutation } from "@tanstack/react-query";

const StyleButton = styled(Button)`
  border-radius: ${(props) => props.theme?.token?.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme?.token?.colorBgActionButton};
  box-shadow: ${(props) => props.theme?.token?.boxShadowNone};
  margin-right: 2rem;
  height: 3rem;
  width: 100%;
`;

function BlukTrading() {
  const {
    isLoading: bulkTradeLoading,
    mutate: bulkTradeMutate,
    data: bulkTradeData,
    error: bulkTradeError,
  } = useMutation({
    mutationFn: (value) => {
      return APICall(
        "get",
        `${EndPoints.getBulkTrade}?quantity=${value?.quantity}&noOfTrades=${value?.noOfTrades}`
      ).then((res) => res?.data?.data);
    },
  });

  // function
  const onFinish = (values) => {
    console.log("Success:", values);
    bulkTradeMutate(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // column
  const columns = [
    bulkTradeLoading
      ? { title: "CLIENT", key: "acceptedBy", dataIndex: "client" }
      : {
          title: "CLIENT",
          key: "acceptedBy",
          dataIndex: "client",
          align: "center",
          render: (text, record) => {
            return <span> {`${record?.userName} (${record?.userId}) `}</span>;
          },
        },
    {
      title: "SCRIPT",
      dataIndex: "script",
      key: "script",
      align: "center",
      render: (text, record) => {
        return !bulkTradeLoading && <span>{record?.scriptName}</span>;
      },
    },

    {
      title: "NO. OF TRADES",
      dataIndex: "totalTrades",
      key: "totalTrades",
      align: "center",
    },
    {
      title: "START TIME",
      dataIndex: "startTime",
      key: "startTime",
      align: "center",
    },
    {
      title: "END TIME",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
    },
  ];

  return (
    <>
      <ThemeCard>
        <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row style={{ alignItems: "center" }} gutter={[20, 20]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <ThemeInputNumber
                title="No. of Trades"
                placeholder="Type No"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  name: "noOfTrades",
                  // value: value?.noOfTrades,
                  // onChange: onChange,
                  step: 1,
                }}
                required
                width={"98%"}
                formProps={{
                  name: "noOfTrades",
                  rules: [
                    { required: true, message: "Please enter no. of trades" },
                  ],
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <ThemeInputNumber
                title="Quantity"
                placeholder="Quantity"
                extraProps={{
                  input_type: "number",
                }}
                required
                inputProps={{
                  name: "quantity",
                  // value: value?.quantity,
                  // onChange: onChange,
                  step: 1,
                }}
                width={"98%"}
                formProps={{
                  name: "quantity",
                  rules: [{ required: true }],
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={6} lg={4} xl={2}>
              <StyleButton
                type="primary"
                htmlType="submit"
                loading={bulkTradeLoading}
                // onClick={handleSubmit}
              >
                Submit
              </StyleButton>
            </Col>
          </Row>
        </Form>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={bulkTradeData}
        column={columns}
        isLoading={bulkTradeLoading}
        headingStyle={false}
        isFilterTrue={true}
      />
    </>
  );
}

export default BlukTrading;
