import React, { useEffect, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Form, Row, notification } from "antd";
import ThemeInput from "../../components/ThemeInput";
import styled from "styled-components";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeDropDown from "../../components/ThemeDropDown";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  RemoveButton,
  SubmitButton,
  ThemeForm,
} from "../AccountPointEntry/style";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import { useWatch } from "antd/es/form/Form";

const initalState = {
  debitFrom: "",
  creditTo: "",
  type: "JV",
  date: "",
  point: "",
  remark: "",
};

function AccountPointJVEntry() {
  // state
  const [value, setValue] = useState(initalState);
  const [userOption, setUserOption] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  // redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const debit = useWatch("debitFrom", form)?.[0];
  const credit = useWatch("creditTo", form)?.[0];

  // api call
  const onSubmit = async (value) => {
    const payload = {
      ...value,
      creditTo: value?.creditTo?.[0],
      debitFrom: value?.debitFrom?.[0],
      type: "JV",
      remark: value?.remark || null,
    };
    // for (let key in value) {
    //   if (value[key] === "" && key !== "remark") {
    //     return notification.error({ message: "Please fill all details" });
    //   }
    // }
    // if (value.creditTo === value.debitFrom) {
    //   return notification.error({
    //     message:
    //       "You can not add same name in credit account and debit account",
    //   });
    // }

    try {
      setSubmitLoading(true);
      let response = await APICall("post", EndPoints.createLedger, payload);

      if (response.status === 200) {
        notification.success({ message: response.data?.message });
        form.resetFields();
        // setValue(initalState);
      } else {
        notification.error({ message: response.data?.message });
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      console.log("error", error);
    }
  };

  const getChildUser = async () => {
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getChildUsers}${userDetail?.userId}&type=onlychild`
      );
      return response?.data?.data?.childUsers;
    } catch (error) {
      console.log("error", error);
    }
  };

  const {
    isLoading,
    error,
    data: childData,
  } = useQuery({ queryKey: ["getChildUser"], queryFn: getChildUser });

  // life cycle
  useEffect(() => {
    if (childData) {
      setUserOption(() => {
        const list = childData.map((el) => {
          return {
            label: `${el?.accountName} (${el?.userId})`,
            value: el?.userId,
          };
        });
        return list;
      });
    }
  }, [childData]);

  // function
  const onChangeDate = (value) => {
    setValue((pre) => ({ ...pre, date: value }));
  };
  const onChangeInput = ({ target: { name, value } }) => {
    setValue((pre) => ({ ...pre, [name]: value }));
  };
  const onChangeDropDown = (name, value, option) => {
    setValue((pre) => ({ ...pre, [name]: value[0] }));
  };
  const handleCancel = () => {
    // setValue(initalState);
    form.resetFields();
  };

  const handleFinish = (value) => {
    // console.log("finish", value);
    onSubmit(value);
  };

  const handleFailed = (error) => {
    console.log("failed", error);
    notification.error({ message: error?.errorFields?.[0]?.errors?.[0] });
  };

  return (
    <ThemeCard title={"Account Point JV Entry"}>
      <ThemeForm
        form={form}
        onFinish={handleFinish}
        onFinishFailed={handleFailed}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 6 }}
        style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
      >
        {/* <Row justify={"center"} gutter={10}>
          <Col span={24} md={7}> */}
        <ThemeDropDown
          name="debitFrom"
          placeholder="Select"
          // $value={value.debitFrom}
          // $onChange={onChangeDropDown}
          options={userOption?.filter((el) => el?.value !== credit)}
          showSearch={true}
          style={{ width: "100%" }}
          // suffixIcon={<Spin size="small" spinning={isLoading} />}
          disabled={isLoading}
          form_props={{
            name: "debitFrom",
            label: "Debit Account",
            rules: [
              { required: true },
              // {
              //   validator: (_) => {
              //     if (value.debitFrom) {
              //       console.log("value", value.debitFrom);
              //       return Promise.resolve();
              //     } else {
              //       return Promise.reject(
              //         new Error(`Please add debit account name`)
              //       );
              //     }
              //   },
              // },
            ],
          }}
        />
        {/* </Col>
        </Row> */}

        {/* <Row
          justify={"center"}
          style={{
            marginTop: "2rem",
          }}
          gutter={10}
        >
          <Col span={24} md={7}> */}
        <ThemeDropDown
          name={"creditTo"}
          placeholder="Select"
          // $value={value.creditTo}
          // $onChange={onChangeDropDown}
          options={userOption?.filter((el) => el?.value !== debit)}
          showSearch={true}
          style={{ width: "100%" }}
          // suffixIcon={<Spin size="small" spinning={isLoading} />}
          disabled={isLoading}
          form_props={{
            name: "creditTo",
            label: "Credit Account",
            rules: [
              { required: true },
              // {
              //   validator: () => {
              //     if (value.creditTo) {
              //       return Promise.resolve();
              //     } else {
              //       return Promise.reject(
              //         new Error(`Please add credit account name`)
              //       );
              //     }
              //   },
              // },
            ],
          }}
        />
        {/* </Col>
        </Row> */}

        {/* <Row justify={"center"} style={{ marginTop: "2rem" }} gutter={10}>
          <Col span={24} md={7}> */}
        <ThemeDatePicker
          // onChange={onChangeDate}
          // value={value.date}
          width={"100%"}
          formProps={{
            name: "date",
            label: "Date",
            rules: [
              { required: true },
              // {
              //   validator: () => {
              //     if (value.date) {
              //       return Promise.resolve();
              //     } else {
              //       return Promise.reject(new Error(`Please add date`));
              //     }
              //   },
              // },
            ],
          }}
        />
        {/* </Col>
        </Row> */}

        {/* <Row justify={"center"} style={{ marginTop: "2rem" }} gutter={10}>
          <Col span={24} md={7}> */}
        <ThemeInputNumber
          marginBottom={"0rem"}
          style={{ width: "100%" }}
          inputProps={{
            name: "point",
            // value: value.point,
            // onChange: onChangeInput,
          }}
          errorheight="0rem"
          formProps={{
            name: "point",
            label: "Point",
            rules: [
              { required: true },
              // {
              //   validator: () => {
              //     if (value.point) {
              //       return Promise.resolve();
              //     } else {
              //       return Promise.reject(new Error(`Please add Points`));
              //     }
              //   },
              // },
            ],
          }}
        />
        {/* </Col>
        </Row> */}

        {/* <Row justify={"center"} gutter={10} style={{ marginTop: "1.5rem" }}>
          <Col span={24} md={7}> */}
        <ThemeInput
          marginBottom={"0rem"}
          style={{ width: "100%" }}
          inputProps={{
            name: "remark",
            // value: value.remark,
            // onChange: onChangeInput,
          }}
          formProps={{
            name: "remark",
            label: "Remark",
          }}
        />
        {/* </Col>
        </Row> */}

        <Row justify={"center"} style={{ marginTop: "2rem" }} gutter={[20, 20]}>
          <Col xs={24} sm={12} md={4} lg={4} xl={3}>
            <SubmitButton
              // onClick={onSubmit}
              loading={submitLoading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </SubmitButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={4} xl={3}>
            <RemoveButton type="primary" onClick={handleCancel}>
              Cancel
            </RemoveButton>
          </Col>
        </Row>
      </ThemeForm>
    </ThemeCard>
  );
}

export default AccountPointJVEntry;
