import { SettingOutlined } from "@ant-design/icons";
import { Form, Space, TimePicker, Typography } from "antd";
// import dayjs from "dayjs";
import { styled } from "styled-components";
const StyledThemeInput = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    & .ant-form-item-explain-error {
      width: ${(props) => props?.width};
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
    }
    .ant-input-group-addon {
      background-color: ${(props) => {
        return props?.$active
          ? props?.$activeColor || props.theme.token.colorPrimary
          : props?.$showSuffixBg
          ? props.theme.token.colorBgLayout
          : props.theme.token.colorBgBase;
      }};
    }
    .ant-input-affix-wrapper {
      padding-block: 0rem;
    }
    .ant-input-prefix {
      font-size: 1.3rem;
    }

    .ant-form-item {
      margin-bottom: ${(props) =>
        props?.$marginBottom ? props?.$marginBottom : "1.5rem"};
    }
  `,
  Title: styled(Typography.Title)`
    &.ant-typography {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${(props) =>
        props?.$titleFontWeight ? props?.$titleFontWeight : 600};
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
      & .styled-drop-down-star {
        color: ${(props) => props.theme.token.red4};
        font-size: 1rem;
      }
    }
    &.sub-title {
      color: ${(props) => props.theme.token.colorTextLabel};
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0.5rem;
      line-height: 1.125rem;
    }
    &.sub-title-2 {
      font-weight: 600;
    }
  `,
  TimePicker: styled(TimePicker)`
    border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
    & .ant-picker-input input {
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 400;
      height: 2.4rem;
    }
    &.ant-picker {
      width: 100%;
    }
  `,
};

const ThemeTimePicker = (props) => {
  const date = props?.inputProps?.value;
  // ? dayjs(props?.inputProps?.value)
  // : null;

  //functions
  const onChangeTime = (name, value, string) => {
    props.inputProps?.onChange &&
      props.inputProps?.onChange({
        target: {
          name,
          value: value?.$d,
        },
        string,
      });
  };

  return (
    <StyledThemeInput.Wrapper
      width={props?.width}
      $marginBottom={props?.marginBottom}
      $active={props?.active}
    >
      <Space
        style={{
          justifyContent: "space-between",
          ...props?.titleWrapperStyle,
        }}
      >
        {props?.title ? (
          <StyledThemeInput.Title $titleFontWeight={props?.titleFontWeight}>
            {props?.title}
            {props?.required ? (
              <span className="styled-drop-down-star">*</span>
            ) : null}
          </StyledThemeInput.Title>
        ) : null}
        {props?.subTitle ? (
          <StyledThemeInput.Title className="sub-title">
            {props?.subTitle}
          </StyledThemeInput.Title>
        ) : null}
      </Space>
      <div style={{ width: props?.width }}>
        <Form.Item {...props?.formProps}>
          <StyledThemeInput.TimePicker
            // defaultValue={dayjs("00:00", "mm:ss")}
            format={"HH:mm"}
            suffixIcon={<SettingOutlined />}
            {...props?.inputProps}
            // value={date}
            onChange={(value, string) =>
              onChangeTime(props?.inputProps?.name, value, string)
            }
          />
        </Form.Item>
      </div>
    </StyledThemeInput.Wrapper>
  );
};

export default ThemeTimePicker;
