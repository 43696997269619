import { Modal, notification, theme } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import {
  setSeletedMarket,
  setWatchListArray,
} from "../../../store/slice/marketWatchSlice";
import { StyledDropDownItem, marketWatchItem } from "../style";
import ThemeInput from "../../../components/ThemeInput";

const DropdownWatchList = ({ item, index }) => {
  const token = theme.useToken().token;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(item.name);

  // redux

  const seletedMarket = useSelector(
    (state) => state.marketWatchSlice?.seletctedMaketIndex
  );
  const watchList = useSelector(
    (state) => state.marketWatchSlice?.watchListArray
  );
  // function
  const handleCancel = () => {
    setModal(false);
  };

  const handleOk = async () => {
    if (name.length > 15) {
      return notification.error({
        message: "Please write name below 15 charactore",
      });
    }
    let obj = { name: name };
    setLoading(true);
    try {
      let response = await APICall(
        "post",
        `${EndPoints.editWatchList}${item?._id}`,
        obj
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        dispatch(
          setWatchListArray({
            type: "rename",
            index: index,
            name: name,
          })
        );
        setModal(false);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickMarketItem = async (value) => {
    if (value?.value === "rename") {
      setModal(true);
    } else if (value?.value === "delete") {
      if (watchList.length <= 1) {
        return notification.error({
          message: "At least one watch list require on market watch",
        });
      }
      setLoading(true);
      try {
        let response = await APICall(
          "get",
          `${EndPoints.deleteWatchList}${item?._id}`
        );
        if (response.status === 200) {
          notification.success({ message: response?.data?.message });
          dispatch(
            setWatchListArray({ type: "deleteWatchList", index: index })
          );
          if (seletedMarket === index) {
            dispatch(setSeletedMarket(index == 0 ? 0 : index - 1));
          }
        } else {
          notification.error({ message: response?.data?.message });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        paddingBlock: "0.5rem",
      }}
    >
      {marketWatchItem?.map((el, index) => {
        // const selected = selectedlist?.[item.value];
        return (
          <StyledDropDownItem
            // $selected={selected}
            onClick={() => onClickMarketItem(el)}
            key={el?.value}
          >
            {el?.label}
          </StyledDropDownItem>
        );
      })}
      <Modal
        open={modal}
        onCancel={handleCancel}
        onOk={handleOk}
        confirmLoading={loading}
      >
        <ThemeInput
          title="Rename"
          inputProps={{
            name: "rename",
            value: name,
            onChange: (e) => setName(e.target.value),
          }}
        />
      </Modal>
    </div>
  );
};

export default DropdownWatchList;
