import { useEffect, useState } from "react";
import ThemeInput from "../../../../components/ThemeInput";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Spin,
  notification,
} from "antd";
import { optionsWithDisabled } from "../style";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from "../../../../APICall";
import EndPoints from "../../../../APICall/EndPoints";
import { useSelector } from "react-redux";

const initalState = {
  status: null,
  message: "",
};

const StatusButton = ({ setShowStatus, item, refetch }) => {
  // status
  const [accountStatus, setAccountStatus] = useState(initalState);
  const [passward, setPassward] = useState("");
  const queryClient = useQueryClient();

  //redux
  const addition = useSelector((state) => state?.authSlice?.additionalSharing);

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["getStatus"],
      queryFn: () => getStatus(item?.userId),
    });
  }, []);
  //api
  const getStatus = async (userId) => {
    let response = await APICall("get", `${EndPoints.getUserStatus}${userId}`);
    return response?.data?.data;
  };

  const {
    isLoading: getStatusLoading,
    isFetching,
    error,
    data,
  } = useQuery({
    queryKey: ["getStatus"],
    queryFn: () => getStatus(item?.userId),
  });
  const updateStatus = async (payload) => {
    if (payload.status !== "Block") {
      delete payload.message;
    }
    let response = await APICall(
      "post",
      `${EndPoints?.updateUserStatus}${item?.userId}`,
      payload
    );
    return response;
  };

  const {
    isLoading: loadingUpdateStatus,
    mutate: updateStatusMutate,
    data: statusData,
  } = useMutation({
    mutationKey: "updateStatus",
    mutationFn: (payload) => updateStatus(payload),
    onSuccess: (res) => {
      if (res?.status === 200) {
        notification.success({ message: res?.data?.message });
        setShowStatus({ flage: false, item: null });
      } else {
        notification.error({ message: res?.message });
      }
    },
  });

  const {
    isLoading: loadingdeleteAccount,
    mutate: deleteAccountMutate,
    data: deleteData,
  } = useMutation({
    mutationKey: "deleteAccount",
    mutationFn: () => {
      return APICall("delete", `${EndPoints?.deleteUser}${item?.userId}`);
    },
    onSuccess: (res) => {
      if (res?.status === 200) {
        notification.success({ message: res?.data?.message });
        setShowStatus({ flage: false, item: null });
        refetch();
      } else {
        notification.error({ message: res?.data?.message });
      }
    },
  });

  const {
    isLoading: checkPasswardLoading,
    mutate: checkPasswardMutate,
    data: checkPasswardData,
  } = useMutation({
    mutationKey: "checkPassward",
    mutationFn: (payload) => {
      return APICall("post", `${EndPoints?.passwordVerification}`, payload);
    },
    onSuccess: (res) => {
      console.log(res, "response");
      if (res?.status === 200) {
        deleteAccountMutate();
      } else {
        notification.error({ message: res?.data?.message });
      }
    },
  });

  useEffect(() => {
    if (data) {
      setAccountStatus({
        ...accountStatus,
        status: data?.status,
        message: data?.blockedMessage,
      });
    }
  }, [data]);

  // function
  const onChangeRadio = async ({ target: { value } }) => {
    setAccountStatus({ ...accountStatus, status: value });
  };

  const handleMessage = (e) => {
    let value = e.target.value;
    setAccountStatus({ ...accountStatus, message: value });
  };

  const handleOkStatus = () => {
    updateStatusMutate(accountStatus);
  };

  const handleCancelStatus = () => {
    setShowStatus({ flage: false, item: null });
  };

  const handleDeleteAccount = async () => {
    // deleteAccountMutate();
    checkPasswardMutate({ password: passward });
  };

  return (
    <>
      <Modal
        open={true}
        title={`${item?.accountName}(${item?.userId})`}
        onOk={handleOkStatus}
        onCancel={handleCancelStatus}
        confirmLoading={loadingUpdateStatus}
      >
        {getStatusLoading || isFetching ? (
          <Row align={"center"}>
            <Spin active />
          </Row>
        ) : (
          <Radio.Group
            style={{
              marginTop: "1rem",
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
            options={optionsWithDisabled}
            onChange={onChangeRadio}
            value={accountStatus?.status}
            optionType="button"
            buttonStyle="solid"
          />
        )}
        {accountStatus.status === "Block" ? (
          <div style={{ width: "33rem", margin: "auto" }}>
            <ThemeInput
              title="Message"
              inputProps={{
                placeholder: "Enter Message Here",
                value: accountStatus?.message,
                onChange: handleMessage,
              }}
            />
          </div>
        ) : null}
        {addition?.clientDelete && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "1rem", width: "25rem" }}>
              <Input
                placeholder="Enter Your Password to Delete Account"
                value={passward}
                onChange={(e) => setPassward(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "2rem" }}>
              <Popconfirm
                title="Delete Account"
                description="Are you sure to delete this account"
                onConfirm={handleDeleteAccount}
              >
                <Button
                  style={{ marginBottom: "2rem", color: "red" }}
                  loading={loadingdeleteAccount || loadingdeleteAccount}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default StatusButton;
