import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const marketDataAdapter = createEntityAdapter({
  selectId: (marketData) => marketData.Symbol,
});

const initialState = {
  data: {},
  socketConnect: false,
};

export const positionSocketSlice = createSlice({
  name: "positionSocketSlice",
  initialState,
  reducers: {
    setPositionSocketData: (state, action) => {
      const { id, data } = action.payload;
      state.data[id] = data;
    },
    setPositionConnect: (state, action) => {
      state.socketConnect = action.payload;
    },
    resetPositiondata: (state, action) => {
      state.data = {};
    },
  },
});

export const { setPositionSocketData, setPositionConnect, resetPositiondata } =
  positionSocketSlice.actions;

export default positionSocketSlice.reducer;
