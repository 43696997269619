import React, { useEffect, useRef, useState } from "react";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Pagination, Row, notification } from "antd";
import styled from "styled-components";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { useDispatch, useSelector } from "react-redux";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import { valanBillDate } from "../SummaryReport/ReportCart";
import GetDate from "../../components/DatePicker/GetDate";
import useGetQueryParams from "../../hooks/useGetQueryParams";

const SubmitButton = styled(Button)`
  border-radius: ${(props) => props.theme?.token.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme?.token.colorBgActionButton};
  box-shadow: ${(props) => props.theme?.token.boxShadowNone};
  margin-right: 2rem;
  height: 3rem;
  width: 100%;
  @media (max-width: 1079px) {
    height: 2.5rem;
    font-size: 0.8rem;
  }
`;
const StyledDiv = styled.div`
  text-align: left;
`;

const M2mAlertLog = () => {
  const valueRef = useRef({});
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();
  const queryClient = useQueryClient();
  // state
  const [pages, setPage] = useState({ page: 1 });

  //redux
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  //life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginUserId,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  // api call
  const getM2mAlert = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    const obj = {
      userId: Number(params?.userId) || null,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
    };
    let response = await APICall(
      "post",
      `${EndPoints.getM2MAlert}&page=${params?.page || 1}&limit=${
        params?.limit || 10
      }`,
      obj
    );

    const data = response?.data?.data;
    setPage({
      page: data?.currentPage,
      nextPage: data?.nextPage,
      totalCount: data?.totalCount,
      totalPages: data?.totalPages,
    });
    return response?.data?.data;
  };

  const {
    isLoading,
    error,
    data: M2mAlert,
  } = useQuery({
    queryKey: ["m2mAlert", params],
    queryFn: getM2mAlert,
    enabled: Object.keys(params)?.length > 0,
  });

  // function
  const handleSearch = () => {
    let payloadValue = valueRef.current;
    let valueObj = {
      userId: payloadValue?.userId || loginUserId,
      startDate: payloadValue?.startDate?.toISOString() || null,
      endDate: payloadValue?.endDate?.toISOString() || null,
      page: 1,
    };

    for (let key in valueObj) {
      if (!valueObj[key]) {
        delete valueObj[key];
      }
    }
    setSearchParams(valueObj);
  };

  const handleClear = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};

    setSearchParams({ userId: loginUserId });
  };

  const handlePageChange = (value) => {
    setSearchParams({ ...params, page: value });
  };

  // column
  const m2mAlertColumns = [
    isLoading
      ? { title: "TIME", dataIndex: "time", key: "time" }
      : {
          title: "TIME",
          dataIndex: "time",
          key: "time",
          width: "6rem",
          align: "center",
          render: (text, record) => {
            const time = valanBillDate(record?.alertTime, true);
            return <span>{time}</span>;
          },
        },
    {
      title: "CLIENT",
      dataIndex: "d",
      key: "d",
      align: "left",
      width: "7rem",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>
              {record?.data?.userName}
              {`(${record?.clientId})`}
            </div>
          )
        );
      },
    },
    {
      title: "MESSAGE",
      dataIndex: "script",
      key: "script",
      align: "left",
      width: "25rem",
      render: (text, record) => {
        return !isLoading && <StyledDiv>{record?.data?.message}</StyledDiv>;
      },
    },
    {
      title: "M2M",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return !isLoading && <span>{formatToINR(record?.data?.amount)}</span>;
      },
    },
    {
      title: "LEDGER + M2M",
      dataIndex: "lot",
      key: "lot",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return !isLoading && <span>{formatToINR(record?.data?.balance)}</span>;
      },
    },
  ];

  return (
    <>
      <ThemeCard
      // title="M2mAlert Log"
      >
        <Row gutter={[10, 15]}>
          <GetDate valueRef={valueRef} />
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row gutter={[10, 15]} style={{ marginTop: "2rem" }}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <SubmitButton type="primary" onClick={handleSearch}>
              Search
            </SubmitButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <SubmitButton type="primary" onClick={handleClear}>
              Clear Search
            </SubmitButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={M2mAlert ? M2mAlert?.alerts : []}
        column={m2mAlertColumns}
        isLoading={isLoading}
        headingStyle={false}
        isFilterTrue={true}
        filterType="m2m"
      />
      <Row align={"center"}>
        <Pagination
          currentPage={params?.page || 1}
          total={pages?.totalCount}
          pageSize={params?.limit || 10}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </Row>
    </>
  );
};

export default M2mAlertLog;
