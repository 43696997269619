import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewAccountInfo } from "../../../../store/slice/userAccountSlice";
import ThemeCard from "../../../../components/ThemeCard";
import { Button, Col, InputNumber, Popover, Row, theme } from "antd";
import ThemeDropDown from "../../../../components/ThemeDropDown";
import styled from "styled-components";
import ThemeInputNumber, {
  converterFunction,
} from "../../../../components/ThemeInputNumber/ThemeInputNumber";
import { useWatch } from "antd/es/form/Form";

const AddButton = styled(Button)`
  padding: 2px 7px;
  border: 1px solid
    ${({ isAdd, token }) =>
      isAdd ? token.marketWatchUp : token.marketWatchDown};
  border-top-right-radius: ${({ isAdd }) => (isAdd ? "unset" : "6px")};
  border-bottom-right-radius: ${({ isAdd }) => (isAdd ? "unset" : "6px")};
  border-top-left-radius: ${({ isAdd }) => (isAdd ? "6px" : "unset")};
  border-bottom-left-radius: ${({ isAdd }) => (isAdd ? "6px" : "unset")};
  background: ${({ isAdd, token }) =>
    isAdd ? token.marketWatchUp : token.marketWatchDown};
  color: white;
  height: 100%;
`;
const marginValue = [
  {
    label: "Value",
    value: "value",
  },
];

const MarginView = ({ tab, view, is_edits, form }) => {
  const dispatch = useDispatch();
  const [newValue, setNewValue] = useState(0);
  const [showModal, setShowModal] = useState({
    flage: false,
    name: "",
    action: "",
  });
  const token = theme.useToken().token;

  //redux
  const userAccountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );
  const form_data = useSelector(
    (state) =>
      state.userAccountSlice[tab] && state.userAccountSlice[tab]?.[view]
  );
  const marginDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails?.marginDetails?.[tab]
  );
  const InitialValue = useSelector(
    (state) => state.globleSlice?.constantValue?.[tab]
  );

  const parentRent = useSelector(
    (state) =>
      state.authSlice.MarketAccessDetails?.accountDetails?.partnershipWith ===
      "rent"
  );
  const isMaster = userAccountType === "masterAdmin";
  const isCustomer = userAccountType === "customer";
  let totalMargin =
    Number(marginDetails?.delivery) > 0 ? Number(marginDetails?.delivery) : 0;
  const marginDelivary = useWatch(`delivery$${tab}`, form) || 0;
  const marginIntraday = useWatch(`intraday$${tab}`, form) || 0;
  const parentBasicInfo = useSelector(
    (state) => state.authSlice?.MarketAccessDetails?.basicInfo
  );

  const handleAdd = () => {
    let action = showModal?.action;
    let name = showModal?.name;

    form.setFields([
      {
        name: `total$${tab}`,
        value:
          Number(form.getFieldValue([`intraday$${tab}`]) || 0) +
          (action === "add" ? Number(newValue) : -Number(newValue)) +
          Number(form.getFieldValue([`delivery$${tab}`]) || 0),
        touched: true,
      },
      {
        name: `${showModal?.name}$${tab}`,
        value:
          Number(form.getFieldValue([`${name}$${tab}`]) || 0) +
          (action === "add" ? Number(newValue) : -Number(newValue)),
        touched: true,
      },
    ]);

    form.validateFields([`delivery$${tab}`, `intraday$${tab}`]);
    setShowModal({ flage: false, name: "", action: "" });
    setNewValue(0);
  };

  const handleOnChange = (e) => {
    setNewValue(e.target.value);
  };

  const handleCancel = () => {
    setShowModal({ flage: false, name: "", action: "" });
    setNewValue(0);
  };

  //function
  const content = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          textAlign: "center",
          width: "250px",
        }}
      >
        <ThemeInputNumber
          inputProps={{
            onChange: handleOnChange,
          }}
          extraProps={{
            showConversion: true,
            conversionValue: newValue,
          }}
          value={newValue}
          style={{ width: "100%" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Button onClick={handleAdd} style={{ width: "50%" }}>
            Ok
          </Button>
          <Button onClick={handleCancel} style={{ width: "50%" }}>
            Cancle
          </Button>
        </div>
      </div>
    );
  };

  const showPopover = (name, value) => {
    setShowModal({ flage: true, name: name, action: value });
  };

  const handleResetField = (name, objName) => {
    form.setFields([
      {
        name: name,
        value: InitialValue?.[objName],
        touched: true,
      },
    ]);
    form.validateFields([name]);
    setTimeout(() => {
      form.setFields([
        {
          name: `total$${tab}`,
          value:
            Number(form.getFieldValue([`intraday$${tab}`]) || 0) +
            Number(form.getFieldValue([`delivery$${tab}`]) || 0),
          touched: true,
        },
      ]);
    }, 0);
  };

  const onChangeDropDown = (name, value) => {
    dispatch(
      setNewAccountInfo({
        tab,
        view,
        key: name,
        [name]: value ? value[0] : null,
      })
    );
  };

  return (
    <ThemeCard title="Margin" headerColor style={{ marginBottom: "2rem" }}>
      <Row
        justify={userAccountType !== "customer" ? "center" : "space-between"}
        align={"center"}
        gutter={[7, 7]}
      >
        <Col
          xs={24}
          sm={6}
          md={6}
          lg={5}
          xl={3}
          align="center"
          justify="center"
        >
          <ThemeDropDown
            required
            placeholder="Value"
            options={marginValue}
            name={"type"}
            title={"Type"}
            width={"100%"}
            $value={form_data?.type}
            $onChange={onChangeDropDown}
            rules={[
              {
                name: "type",
                type: "array",
                required: true,
                message: "Please select cascader!",
              },
            ]}
          />
        </Col>
        <Col
          xs={24}
          sm={12}
          md={8}
          lg={6}
          xl={6}
          style={{ display: "flex", width: "100%" }}
        >
          {/* <div style={{ height: "100%" }}>
            {is_edits && (
              <Popover
                style={{ width: "5%" }}
                title={showModal?.name}
                open={
                  showModal?.flage &&
                  showModal?.name === "delivery" &&
                  showModal?.action === "add"
                }
                onClick={() => showPopover("delivery", "add")}
                content={content}
              >
                <AddButton>+</AddButton>
              </Popover>
            )}
          </div> */}
          <ThemeInputNumber
            required
            title={`Delivery`}
            isShowReset
            handleResetField={() =>
              handleResetField(`delivery$${tab}`, "delivery")
            }
            width="100%"
            extraProps={{
              showConversion: true,
            }}
            errorheight="2rem"
            inputProps={{
              name: `delivery$${tab}`,
              className: "addon-number-input",
              readOnly: is_edits ? true : false,
              onChange: (e) => {
                form.setFields([
                  {
                    name: `total$${tab}`,
                    value:
                      Number(form.getFieldValue([`intraday$${tab}`])) +
                      e.target.value,
                    touched: true,
                  },
                ]);
              },
              width: "98%",
              addonBefore: is_edits && (
                <Popover
                  style={{ width: "5%" }}
                  title="delivery"
                  open={
                    showModal?.flage &&
                    showModal?.name === "delivery" &&
                    showModal?.action === "add"
                  }
                  onClick={() => showPopover("delivery", "add")}
                  content={content}
                >
                  <AddButton isAdd token={token}>
                    +
                  </AddButton>
                </Popover>
              ),
              addonAfter: is_edits && (
                <Popover
                  style={{ width: "5%" }}
                  title="delivery"
                  open={
                    showModal?.flage &&
                    showModal?.name === "delivery" &&
                    showModal?.action === "remove"
                  }
                  onClick={() => showPopover("delivery", "remove")}
                  content={content}
                >
                  <AddButton token={token}>-</AddButton>
                </Popover>
              ),
              step: 1,
              suffix: `${
                is_edits
                  ? `/${
                      InitialValue?.historyDelivery
                        ? converterFunction(InitialValue?.historyDelivery)
                        : 0
                    }`
                  : ""
              } `,
              require: "true",
            }}
            formProps={{
              name: `delivery$${tab}`,
              // initialValue: 0,
              dependencies: [`intraday$${tab}`, `total$${tab}`],
              rules: [
                () => ({
                  validator(_, value) {
                    value = form.getFieldValue(`delivery$${tab}`);
                    if (is_edits) {
                      if (
                        ((isMaster || parentRent) &&
                          value >= 0 &&
                          value !== "" &&
                          value !== null) ||
                        (value <=
                          totalMargin +
                            InitialValue.delivery -
                            marginIntraday &&
                          value >= 0 &&
                          value !== "" &&
                          value !== null &&
                          !isCustomer) ||
                        (isCustomer &&
                          value >= 0 &&
                          value <=
                            InitialValue?.intraday +
                              InitialValue?.delivery +
                              totalMargin -
                              marginIntraday &&
                          value !== "" &&
                          value !== null)
                      ) {
                        return Promise.resolve();
                      } else {
                        if (isMaster || parentRent) {
                          return Promise.reject(
                            new Error(
                              `Please fill this field with positive number`
                            )
                          );
                        } else if (isCustomer && value > totalMargin) {
                          return Promise.reject(
                            new Error(
                              `You can not give total of delivery and intraday less then 0 and more then ${totalMargin}`
                            )
                          );
                        } else if (isCustomer) {
                          return Promise.reject(
                            new Error(
                              `You can not give delivery less then 0 and more then ${
                                InitialValue?.intraday +
                                  InitialValue?.delivery +
                                  totalMargin -
                                  marginIntraday >=
                                0
                                  ? InitialValue?.intraday +
                                    InitialValue?.delivery +
                                    totalMargin -
                                    marginIntraday
                                  : 0
                              }`
                            )
                          );
                        } else {
                          return Promise.reject(
                            new Error(
                              `You can not give delivery less then 0 and more then ${
                                totalMargin +
                                InitialValue.delivery -
                                marginIntraday
                              }  `
                            )
                          );
                        }
                      }
                    } else if (isCustomer) {
                      if (
                        (parentRent &&
                          value >= 0 &&
                          value !== "" &&
                          value !== null) ||
                        (value >= 0 &&
                          value <= totalMargin - marginIntraday &&
                          value !== "" &&
                          value !== null)
                      ) {
                        return Promise.resolve();
                      } else if (parentRent) {
                        return Promise.reject(`Please add positive number`);
                      } else if (
                        totalMargin === 0 &&
                        !is_edits &&
                        !parentRent
                      ) {
                        return Promise.reject(
                          `${parentBasicInfo.accountName}(${parentBasicInfo.userId}) don't have sufficient balance, Please enter 0 in both fields Delivery and Intraday`
                        );
                      } else {
                        if (isCustomer && value > totalMargin) {
                          return Promise.reject(
                            new Error(
                              `You can not give total of delivery and intraday less then 0 and more then ${totalMargin}  `
                            )
                          );
                        }

                        return Promise.reject(
                          new Error(
                            `You can not give  delivery less then 0 and more then ${
                              totalMargin - marginIntraday > 0
                                ? totalMargin - marginIntraday
                                : 0
                            }`
                          )
                        );
                      }
                    } else {
                      if (
                        ((isMaster || parentRent) &&
                          value !== "" &&
                          value !== null &&
                          value >= 0) ||
                        (value <= marginDetails?.delivery &&
                          value >= 0 &&
                          value !== null &&
                          value !== "")
                      ) {
                        return Promise.resolve();
                      } else if (
                        totalMargin === 0 &&
                        !is_edits &&
                        !parentRent &&
                        !isMaster
                      ) {
                        return Promise.reject(
                          `${parentBasicInfo.accountName}(${parentBasicInfo.userId}) don't have sufficient margin, Please enter 0`
                        );
                      }
                      if (isMaster || parentRent) {
                        return Promise.reject(
                          new Error(
                            `Please fill this field with positive number`
                          )
                        );
                      } else {
                        return Promise.reject(
                          new Error(
                            `You can not give delivery less then 0 and more then ${marginDetails?.delivery}`
                          )
                        );
                      }
                    }
                  },
                }),
              ],
            }}
          />
          {/* <div style={{ height: "100%" }}>
            {is_edits && (
              <Popover
                style={{ width: "5%" }}
                title="delivery"
                open={
                  showModal?.flage &&
                  showModal?.name === "delivery" &&
                  showModal?.action === "remove"
                }
                onClick={() => showPopover("delivery", "remove")}
                content={content}
              >
                <AddButton>-</AddButton>
              </Popover>
            )}
          </div> */}
        </Col>
        {isCustomer && (
          <>
            <Col
              xs={24}
              sm={1}
              md={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>+</div>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              style={{ display: "flex" }}
            >
              <div style={{ height: "100%" }}>
                {/* {is_edits && (
                  <Popover
                    title={showModal?.name}
                    open={
                      showModal?.flage &&
                      showModal?.name === "intraday" &&
                      showModal.action === "add"
                    }
                    onClick={() => showPopover("intraday", "add")}
                    content={content}
                  >
                    <AddButton>+</AddButton>
                  </Popover>
                )} */}
              </div>
              <ThemeInputNumber
                title="Intraday"
                errorheight="2rem"
                handleResetField={() =>
                  handleResetField(`intraday$${tab}`, "intraday")
                }
                isShowReset
                extraProps={{
                  showConversion: true,
                }}
                inputProps={{
                  name: "intraday",
                  className: "addon-number-input",
                  width: "98%",
                  readOnly: is_edits ? true : false,
                  addonBefore: is_edits && (
                    <Popover
                      title={showModal?.name}
                      open={
                        showModal?.flage &&
                        showModal?.name === "intraday" &&
                        showModal.action === "add"
                      }
                      onClick={() => showPopover("intraday", "add")}
                      content={content}
                    >
                      <AddButton isAdd token={token}>
                        +
                      </AddButton>
                    </Popover>
                  ),
                  addonAfter: is_edits && (
                    <Popover
                      title={showModal?.name}
                      open={
                        showModal?.flage &&
                        showModal?.name === "intraday" &&
                        showModal.action === "remove"
                      }
                      onClick={() => showPopover("intraday", "remove")}
                      content={content}
                    >
                      <AddButton token={token}>-</AddButton>
                    </Popover>
                  ),
                  // value: marginIntraday,
                  // onChange: onChange,
                  onChange: (e) => {
                    form.setFields([
                      {
                        name: `total$${tab}`,
                        value:
                          Number(form.getFieldValue([`delivery$${tab}`])) +
                          e.target.value,
                        touched: true,
                      },
                    ]);
                  },
                  suffix: `${
                    is_edits
                      ? `/${
                          converterFunction(InitialValue?.historyIntraday) ||
                          InitialValue?.historyIntraday == 0
                            ? InitialValue?.historyIntraday
                            : ""
                        }`
                      : ""
                  } `,
                }}
                formProps={{
                  initialValue: 0,
                  name: `intraday$${tab}`,
                  rules: [
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        value = getFieldValue(`intraday$${tab}`);

                        if (!is_edits) {
                          if (
                            (value >= 0 &&
                              value <= totalMargin - marginDelivary) ||
                            (parentRent && value >= 0)
                          ) {
                            return Promise.resolve();
                          } else if (totalMargin === 0 && !is_edits) {
                            return Promise.reject(
                              `${parentBasicInfo.accountName}(${parentBasicInfo.userId}) don't have sufficient margin, Please enter 0 in both fields Delivery and Intraday`
                            );
                          } else {
                            if (value > totalMargin) {
                              return Promise.reject(
                                new Error(
                                  `You cannot give total of delivery and intraday less than 0 and more than ${totalMargin}`
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You cannot give intraday less than 0 and more than ${
                                  totalMargin - marginDelivary > 0
                                    ? totalMargin - marginDelivary
                                    : 0
                                }`
                              )
                            );
                          }
                        } else {
                          let editValid =
                            InitialValue?.intraday +
                            InitialValue?.delivery +
                            totalMargin -
                            marginDelivary;
                          if (
                            (value >= 0 && value <= editValid) ||
                            (parentRent && value >= 0)
                          ) {
                            return Promise.resolve();
                          } else {
                            if (value > totalMargin) {
                              return Promise.reject(
                                new Error(
                                  `You cannot give total of delivery and intraday less than 0 and more than ${totalMargin}`
                                )
                              );
                            }
                            return Promise.reject(
                              new Error(
                                `You cannot give intraday less than 0 and more than ${
                                  editValid > 0 ? editValid : 0
                                }`
                              )
                            );
                          }
                        }
                      },
                    }),
                  ],
                  dependencies: [`delivery$${tab}`, `total$${tab}`],
                }}
              />
              {/* <div style={{ height: "100%" }}>
                {is_edits && (
                  <Popover
                    title={showModal?.name}
                    open={
                      showModal?.flage &&
                      showModal?.name === "intraday" &&
                      showModal.action === "remove"
                    }
                    onClick={() => showPopover("intraday", "remove")}
                    content={content}
                  >
                    <AddButton>-</AddButton>
                  </Popover>
                )}
              </div> */}
            </Col>
            <Col
              xs={24}
              sm={1}
              md={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>=</div>
            </Col>
            <Col xs={24} sm={10} md={8} lg={6} xl={5}>
              <ThemeInputNumber
                title="Total"
                errorheight="2rem"
                extraProps={{
                  input_type: "number",
                  showConversion: true,
                }}
                inputProps={{
                  name: `total$${tab}`,
                  // value: form_data?.total,
                  suffix: `${
                    is_edits
                      ? `/${
                          converterFunction(InitialValue?.historyTotal) ||
                          InitialValue?.historyTotal === 0
                            ? InitialValue?.historyTotal
                            : ""
                        }`
                      : ""
                  } `,

                  readOnly: true,
                }}
                formProps={{
                  name: `total$${tab}`,
                  initialValue: 0,

                  value:
                    Number(form.getFieldValue(`delivery$${tab}`)) +
                    Number(form.getFieldValue(`intraday$${tab}`)),
                }}
              />
            </Col>
          </>
        )}

        {isCustomer || tab !== "NSE EQU" ? null : (
          <Col xs={24} sm={4}>
            <ThemeInputNumber
              title="Interest Rate"
              required
              extraProps={{
                input_type: "number",
                showConversion: true,
              }}
              inputProps={{
                name: `interestRate$${tab}`,
              }}
              formProps={{
                name: `interestRate$${tab}`,
                InitialValue: 0,
                rules: [
                  () => ({
                    validator(_, value) {
                      if (
                        (value >= marginDetails?.interestRate &&
                          value !== "" &&
                          value !== null) ||
                        ((isMaster || parentRent) &&
                          value >= 0 &&
                          value !== "" &&
                          value <= 100 &&
                          value !== null)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `You can not give interstRate less then ${
                            isMaster || parentRent
                              ? 0
                              : marginDetails?.interestRate
                          } and more then 100 `
                        )
                      );
                    },
                  }),
                ],
              }}
            />
          </Col>
        )}
      </Row>
    </ThemeCard>
  );
};

export default MarginView;
