import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tooltip, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckOutlined,
  FullscreenOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import SimpleTable from "../../pages/MarketWatch/SimpleTable/SimpleTable";
import ThemeInput from "../../components/ThemeInput";
import {
  newSelectedMarket,
  setSeletedMarket,
  setWatchListArray,
} from "../../store/slice/marketWatchSlice";
import dayjs from "dayjs";
import EndPoints from "../../APICall/EndPoints";
import APICall from "../../APICall";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../../components/Socket";
import useGetWatchList from "../../hooks/useGetWatchList";
import {
  StyledTableWrapper,
  StyledDropDownItem,
  StyledMarketButtonAll,
  StyledMarketButton,
} from "./style";
import HeaderView from "./Components/HeaderView";
import DropdownWatchList from "./Components/DropdownWatchList";
import WatchListModal from "./Components/WatchListModal";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

function MarketWatch() {
  const fullscreenRef = useRef(null);
  const queryClient = useQueryClient();
  const { getWatchListByHook } = useGetWatchList();
  const dispatch = useDispatch();
  //state
  // const [selectedMarket, setSelectedMarket] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [watchListModal, setWatchListModal] = useState(false);
  const socketContext = useSocket();
  const { doSend } = socketContext;

  //redux
  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const visibleList = useSelector(
    (state) => state.marketWatchSlice.selectedMarket
  );
  const watchList = useSelector(
    (state) => state?.marketWatchSlice?.watchListArray
  );
  const selectedMarket =
    useSelector((state) => state.marketWatchSlice?.seletctedMaketIndex) || 0;

  useEffect(() => {
    if (
      (!selectedMarket && selectedMarket !== 0) ||
      selectedMarket >= watchList?.length
    ) {
      dispatch(setSeletedMarket(0));
    }
  }, [selectedMarket]);

  // const getWatchList = async () => {
  //   let response = await APICall("get", EndPoints.getWatchList);
  //   return response.data?.data?.watchList;
  // };
  const getWatchList = async () => {
    let response = await APICall("get", EndPoints.getWatchList);
    return response.data?.data;
  };

  const {
    isLoading: watcListLoading,
    data: watcListData,
    error: watcListError,
  } = useQuery({
    queryKey: ["getWatchList"],
    queryFn: getWatchList,
  });

  // life cycle
  useEffect(() => {
    queryClient.refetchQueries(["getWatchList"]);

    return () => {
      const unsubscribeSymbol = watchList?.[selectedMarket]?.scriptList?.map(
        (el) => el?.symbol
      );
      doSend({
        event: "unsubscribeToServerMarket",
        payload: unsubscribeSymbol,
      });
    };
  }, []);

  useEffect(() => {
    if (watcListData?.length > 0) {
      // const storedScripts = store?.getState()?.webSocketSlice?.webSocket?.data;
      // let storedKeys = Object?.keys(storedScripts);
      const symbolCall = watchList?.[selectedMarket]?.scriptList?.map(
        (el) => el?.symbol
      );

      if (symbolCall?.length > 0) {
        doSend({
          event: "subscribeToServerMarket",
          payload: symbolCall,
        });
      }
    }
  }, [selectedMarket, watcListData]);

  useEffect(() => {
    if (watcListData) {
      dispatch(setWatchListArray({ data: watcListData, type: "new" }));
      getWatchListByHook(watcListData);
    }
  }, [watcListData]);

  //functions
  const handleScriptSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  const handleMarketButton = (index) => {
    let removeSymbol = watchList?.[selectedMarket]?.scriptList?.map(
      (el) => el?.symbol
    );
    if (removeSymbol?.length > 0) {
      doSend({
        event: "unsubscribeToServerMarket",
        payload: removeSymbol,
      });
    }

    dispatch(setSeletedMarket(index));
  };

  const showAllTables = () => {
    // setSelectedMarket("All");
  };

  const onClickFullScreen = async () => {
    fullscreenRef.current.requestFullscreen();
  };

  return (
    <>
      <div style={{ marginTop: "1.5rem" }}>
        <HeaderView />
        <StyledTableWrapper
          ref={fullscreenRef}
          style={{
            backgroundColor: "rgb(240, 242, 245)",
            // overflow: "auto",
          }}
        >
          {/* <Row
            justify={window.innerWidth >= "1200px" ? "space-between" : "left"}
            gutter={[10, 10]}
            style={{
              width: "100%",
              marginTop: "2.5rem",
              height: "fit-content",
            }}
          >
            <Col xs={12} sm={8} md={6} lg={3} xl={2}>
              <StyledMarketButtonAll
                $isSelected={selectedMarket === "All"}
                dropdownRender={() => <DropdownRender />}
                onClick={showAllTables}
              >
                All
              </StyledMarketButtonAll>
            </Col>

            {marketList.length !== 0 &&
              marketList?.map((item, index) => {
                return (
                  <Col xs={12} sm={8} md={6} lg={3} xl={2} key={item.value}>
                    <StyledMarketButton
                      $isSelected={selectedMarket === item}
                      onClick={() => handleMarketButton(item)}
                    >
                      {item.label}
                    </StyledMarketButton>
                  </Col>
                );
              })}

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              style={{
                marginLeft: window.innerWidth >= 1200 ? "auto" : "",
              }}
            >
              <ThemeInput
                required
                inputProps={{
                  placeholder: "Search anything here",
                  prefix: <SearchOutlined />,
                  onChange: handleScriptSearch,
                }}
                formProps={{
                  name: "search_text",
                }}
              />
            </Col>

            {
              <Col
                xs={24}
                sm={12}
                md={6}
                lg={15}
                xl={1}
                style={{ textAlign: "end" }}
              >
                <Tooltip title="Full screen">
                  <FullscreenOutlined
                    onClick={onClickFullScreen}
                    style={{
                      height: "3.5rem",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            }
          </Row> */}

          <Row style={{ marginTop: "1rem", gap: "2rem" }}>
            {watchList &&
              watchList?.map((item, index) => {
                return (
                  <Col xs={11} sm={7} md={6} lg={3} xl={2} key={item?._id}>
                    <StyledMarketButtonAll
                      $isSelected={selectedMarket == index}
                      onClick={() => handleMarketButton(index)}
                      dropdownRender={() => (
                        <DropdownWatchList item={item} index={index} />
                      )}
                    >
                      {item?.name}
                    </StyledMarketButtonAll>
                    {/* <StyledDropDown
                    menu={{
                      items,
                    }}
                    placement="bottom"
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <StyledMarketButton>bottom</StyledMarketButton>
                  </StyledDropDown> */}
                  </Col>
                );
              })}
            <Col xs={11} sm={7} md={6} lg={3} xl={2}>
              <StyledMarketButton onClick={() => setWatchListModal(true)}>
                + Add Watch-List
              </StyledMarketButton>
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={6}
              xl={4}
              style={{
                marginLeft: window.innerWidth >= 1200 ? "auto" : "",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <ThemeInput
                required
                inputProps={{
                  placeholder: "Search anything here",
                  prefix: <SearchOutlined />,
                  onChange: handleScriptSearch,
                }}
                formProps={{
                  name: "search_text",
                }}
              />
              <Tooltip title="Full screen">
                <FullscreenOutlined
                  onClick={onClickFullScreen}
                  style={{
                    height: "3.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          {watchListModal && (
            <WatchListModal
              watchListModal={watchListModal}
              setWatchListModal={setWatchListModal}
            />
          )}
          {selectedMarket === "All" ? (
            // watchList.length !== 0 &&
            // watchList?.map((item) => {
            //   // if (!visibleList?.[item.value]) {
            //   //   return null;
            //   // }
            //   return (
            //     <SimpleTable
            //       key={item.name}
            //       title={item.name}
            //       searchValue={searchValue}
            //       isLoading={watcListLoading}
            //       watchListData={item?.scriptList}
            //     />
            //   );
            // })
            <SimpleTable
              key={selectedMarket}
              title={watchList?.[selectedMarket]?.name}
              searchValue={searchValue}
              isLoading={watcListLoading}
              selectedMarket={selectedMarket}
              watchListData={watchList?.[selectedMarket]?.scriptList}
            />
          ) : (
            <SimpleTable
              key={selectedMarket}
              title={watchList?.[selectedMarket]?.name}
              searchValue={searchValue}
              isLoading={watcListLoading}
              selectedMarket={selectedMarket}
              watchListData={watchList?.[selectedMarket]?.scriptList}
            />
          )}
        </StyledTableWrapper>
      </div>
    </>
  );
}

export default MarketWatch;

const DropdownRender = (menu) => {
  const token = theme.useToken().token;
  const dispatch = useDispatch();

  // redux
  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const selectedlist = useSelector(
    (state) => state.marketWatchSlice.selectedMarket
  );

  // function
  const onClickMarketItem = (item) => {
    let obj = {
      ...selectedlist,
      [item.value]: !selectedlist[item.value],
    };

    dispatch(newSelectedMarket(obj));
  };

  return (
    <div
      style={{
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        paddingBlock: "0.5rem",
      }}
    >
      {marketList?.map((item, index) => {
        const selected = selectedlist?.[item.value];
        return (
          <StyledDropDownItem
            $selected={selected}
            onClick={() => onClickMarketItem(item)}
            key={item?.value}
          >
            <CheckOutlined
              style={{
                marginRight: "0.5rem",
                visibility: selected ? "visible" : "hidden",
              }}
            />
            {item?.label}
          </StyledDropDownItem>
        );
      })}
    </div>
  );
};
