import ThemeCard from "../../../components/ThemeCard";
import { useSelector } from "react-redux";
import React from "react";
import CustomerView from "./components/CustomerView";
import RoleWiseView from "./components/RoleWiseView";

const AccessDetailsView = ({ is_edits, parentForm }) => {
  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );

  return (
    <ThemeCard
      title="Account details"
      bordered={false}
      style={{ marginBlock: "3rem", flexDirection: "column", width: "100%" }}
    >
      {accountType === "customer" ? (
        <CustomerView is_edits={is_edits} parentForm={parentForm} />
      ) : (
        <RoleWiseView is_edits={is_edits} parentForm={parentForm} />
      )}
    </ThemeCard>
  );
};

export default AccessDetailsView;
