import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThemeInputButtonBase from "../../../../components/ThemeInputButtonBase";
import {
  defaultCustomerOptions,
  defaultOptions,
} from "../../../../Helper/constant";
import APICall from "../../../../APICall";
import EndPoints from "../../../../APICall/EndPoints";
import SetUserDetails from "../../../../components/SetUserDetails/SetUserDetails";
import modifyInitialValuesObject from "../modifyInitialValuesObject";
import { handleMarketValidation } from "../../../../Helper/functions";
import { setNewAccountInfo } from "../../../../store/slice/userAccountSlice";
import { Col } from "antd";

const DefaultUserSetting = ({ validateFields, parentForm }) => {
  const dispatch = useDispatch();

  //state
  const [loading, setloading] = useState(false);

  // redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const basicInfo = useSelector((state) => state.userAccountSlice?.basicInfo);

  useEffect(() => {
    dispatch(
      setNewAccountInfo({
        parent_key: "basicInfo",
        key: "defaultUser",
        value: "",
      })
    );
  }, [accountType]);

  // api call
  const getDefaultUser = async (type) => {
    setloading(true);
    try {
      let URL = `${EndPoints.getDefaultUser}${accountType}`;
      if (accountType === "customer") {
        URL = `${EndPoints.getDefaultUser}${accountType}&type=${type.value}`;
      }
      let response = await APICall("get", URL);

      if (response.status === 200 && response.data) {
        // console.log("defaultUser", response.data?.data);
        const userInfo = response?.data?.data?.[0];
        const m2m = accountType === "customer" ? true : false;
        const finalObj = SetUserDetails(userInfo, m2m, true, parentForm);
        const brokerageObj = userInfo?.sharingBrokerageSchema?.reduce(
          (acc, schema) => {
            const { marketAccessId } = schema;
            acc[marketAccessId.scriptName] = schema;
            return acc;
          },
          {}
        );

        let modedVals = modifyInitialValuesObject(finalObj);
        parentForm.setFieldsValue({
          ...parentForm.getFieldsValue(),
          ...modedVals,
        });
        handleMarketValidation(parentForm, dispatch);
        setTimeout(() => {
          validateFields();
        }, 500);

        if (finalObj?.accountDetails?.newPositionSquareupTime) {
          finalObj.accountDetails.newPositionSquareupTime = null;
        }
        dispatch(setNewAccountInfo(finalObj));
        dispatch(
          setNewAccountInfo({
            parent_key: "basicInfo",
            key: "defaultUser",
            value: accountType !== "customer" ? "default" : type.value,
          })
        );
        dispatch(
          setNewAccountInfo({
            parent_key: "basicInfo",
            key: "importUserSettings",
            value: "",
          })
        );
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("🚀 ~ getDefaultUser ~ error:", error);
    }
  };

  return (
    <Col
      xs={24}
      sm={16}
      md={10}
      lg={7}
      xl={accountType !== "customer" ? 2 : 5}
      style={{
        marginTop: "1.5rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ThemeInputButtonBase
        type="primary"
        onClick={getDefaultUser}
        active={basicInfo.defaultUser}
        options={
          accountType === "customer" ? defaultCustomerOptions : defaultOptions
        }
        loading={loading}
      />
    </Col>
  );
};

export default DefaultUserSetting;
